import React from 'react'
import { Button, Table, Col, Row } from 'antd'

import { withRouter, RouteComponentProps } from 'react-router'
import api from '../../../utils/ApiAxios'
import notification from '../../../utils/notificationService'
import './ExamResults.css'
import Loading from '../../common/Loading/Loading'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'

interface IProps extends RouteComponentProps<RouteParams>, WithTranslation {

}

interface RouteParams {
  activityId: string;
  examId: string;
}

interface IState {
  loading: boolean;
  testName: string;
  testDate: string;
  results: {
    id: number
    name: string
    obtainedPoints: number
  }[];
  maxPoints: number;
}

class ExamResults extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      loading: true,
      testName: '',
      testDate: '',
      results: [],
      maxPoints: 0
    }
  }

  componentDidMount() {
    const examId = parseInt(this.props.match.params.examId)
    const activityId = parseInt(this.props.match.params.activityId)
    Promise.all([
      api.exams.getExam(examId),
      api.activities.getActivitySubscribers(activityId)
    ])
      .then(async response => {
        const test = response[0].data
        const subscribers = response[1].data
        const subscribersAnswersResponse = await Promise.all(
          subscribers.map(subscriber =>
            api.exams.getUserAnswers(examId, subscriber.id)
          )
        )
        const subscribersAnswers = subscribersAnswersResponse.map(
          response => response.data
        )
        const results = subscribersAnswers.reduce(
          (tempResults, subscriberAnswers, index: number) => {
            const subscriberObtainedPoints =
              subscriberAnswers.length === 0
                ? -1
                : subscriberAnswers.reduce(
                  (pointsSoFar: number, answer: any) =>
                    pointsSoFar + parseFloat(answer.obtained_points),
                  0
                )
            //obtained points is -1 if student didnt attend the test
            tempResults.push({
              id: subscribers[index].id,
              name: subscribers[index].name,
              obtainedPoints: subscriberObtainedPoints
            })
            return tempResults
          },
          []
        )
        this.setState({
          results: results,
          testName: test.name,
          testDate: test.startDate,
          loading: false,
          maxPoints: test.events.length
        })
      })
      .catch(error =>
        notification.error(this.props.t('exams.pageError'), error)
      )
      .catch(error =>
        notification.error(this.props.t('exams.pageError'), error)
      )
  }

  renderResults(obtainedPoints: number, maxPoints: number) {
    if (obtainedPoints === -1) {
      return <p className="result_red">{this.props.t('exams.examDidNotTake')}</p>
    }
    const percentage = (obtainedPoints * 100) / maxPoints
    const color =
      percentage >= 75
        ? 'result_green'
        : percentage >= 40
          ? 'result_orange'
          : 'result_red'
    return <p className={color}>{percentage.toFixed(2)} %</p>
  }

  render() {
    return (
      <main>
        {this.state.loading ? (
          <Loading />
        ) : (
          <div className="style">
            <h2>{this.props.t('exams.examResults')}</h2>
            <Row justify="space-between">
              <Col>
                <p>{this.props.t('exams.examTitle')}</p>
                <b>
                  <p
                    style={{ marginLeft: '10px' }}
                    dangerouslySetInnerHTML={{ __html: this.state.testName }}
                  />
                </b>
              </Col>
              <Col>
                <div>
                  <p>{this.props.t('exams.date')}</p>
                  <p
                    style={{ margin: 0 }}
                    dangerouslySetInnerHTML={{ __html: this.state.testDate }}
                  />
                </div>
              </Col>
            </Row>
            <br />
            <div>
              <Table
                rowKey={record => record.id}
                dataSource={this.state.results}
              >
                <Table.Column title={this.props.t('exams.studentName')} dataIndex="name" />
                <Table.Column
                  title={this.props.t('exams.examResult')}
                  dataIndex="obtainedPoints"
                  render={obtainedPoints => {
                    return this.renderResults(
                      obtainedPoints,
                      this.state.maxPoints
                    )
                  }}
                />
              </Table>
            </div>
            <div>
              <Button
                type="primary"
                onClick={() => {
                  this.props.history.goBack()
                }}
              >
                {this.props.t('exams.back')}
              </Button>
            </div>
          </div>
        )}
      </main>
    )
  }
}

export default withTranslation()(withRouter(ExamResults))
