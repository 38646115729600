import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'sk',
    debug: false,
    returnNull: false,
    resources: {
      sk: {
        translation: {
          titlePage: {
            secondaryHeader: {
              links: [
                {
                  label: 'Blog',
                  url: '/blog',
                  target: '_blank',
                  external: true
                },
                {
                  label: 'Kontakt',
                  url: '/contact',
                  target: '_self'
                },
                {
                  label: 'FAQ',
                  url: '/#FAQ',
                  target: '_self'
                }
              ],
              greeting: 'Ahoj, {{user}}!',
              dashboard: 'Dashboard',
              home: 'Domov'
            },
            titleSection: {
              title: 'Education Content Engineering Hub',
              description: 'Naším cieľom je kultivovať a komunikovať predmety s otvoreným obsahom.'
            },
            progressMonitoringSection: {
              title: 'Monitorovanie postupu študentov v reálnom čase pre učiteľov',
              description: 'Náš panel pre učiteľov ponúka monitorovanie postupu študentov v reálnom čase, poskytujúc učiteľom okamžité informácie na sledovanie a podporu výkonnosti študentov. Zostaňte v spojení a zabezpečte úspech študentov pomocou našich intuitívnych nástrojov, navrhnutých na zlepšenie angažovanosti v triede a personalizovanú výučbu.'
            },
            blogSection: {
              title: 'Preskúmajte náš blog: Poznámky, tipy a inšpirácie pre nadšencov vzdelávania',
              description: 'Objavte svet poznania a inšpirácie na našom blogu. Čakajú na vás zaujímavé články, cenné tipy a čerstvé pohľady. Pripojte sa k našej živej komunite učiacich sa a učiteľov. Čítajte teraz a nechajte sa inšpirovať!',
              ctaText: 'Blog'
            },
            projectOverviewSection: {
              title: 'Prehľad projektu: Education Content Engineering Hub',
              overviewItems: [
                {
                  title: 'Názov projektu:',
                  description: 'Education Content Engineering Hub.'
                },
                {
                  title: 'Označenie výzvy:',
                  description: 'BIN SGS02.'
                },
                {
                  title: 'Výška grantu:',
                  description: 'Boli nám udelené granty vo výške 200 000 eur.'
                },
                {
                  title: 'Zdroje financovania:',
                  description: 'Naše financovanie pochádza z nórskych grantov (170 000 eur) a štátneho rozpočtu (30 000 eur).'
                },
                {
                  title: 'Cieľ projektu:',
                  description: 'Cieľom je zlepšiť vzdelávací obsah v matematike a programovaní v jazyku Python prostredníctvom podpory spolupráce medzi zainteresovanými stranami a využitia otvoreného obsahu a vykonateľných modelov.'
                },
                {
                  title: 'Obdobie realizácie:',
                  description: 'Náš projekt sa uskutoční od septembra 2022 do apríla 2024.'
                }
              ]
            },
            grantsSection: {
              title: 'Nórske granty predstavujú príspevok Nórska pre zelenú, konkurencieschopnú a inkluzívnu Európu',
              subtitle: 'Všetky projekty sú spolufinancované zo štátneho rozpočtu Slovenskej republiky vo výške 15%.',
              description: {
                paragraph1: 'Nórsko prostredníctvom nórskych grantov prispieva k znižovaniu ekonomických a sociálnych rozdielov a k posilňovaniu vzájomných vzťahov so štátmi príjemcami v strednej a východnej Európe a v pobaltských krajinách. Nórsko úzko spolupracuje s EÚ prostredníctvom dohôd o Európskom hospodárskom priestore (EEA).',
                paragraph2: 'Spolu s ďalšími darcami poskytlo Nórsko od roku 1994 do roku 2014 3,3 miliardy eur prostredníctvom grantových programov. Nórske granty sú financované výhradne Nórskom a sú dostupné v krajinách, ktoré sa pridali k EÚ po roku 2003. V období 2014-2021 dosiahli nórske granty sumu 1,25 miliardy eur.'
              },
              priorities: {
                title: 'Aktuálne priority',
                items: [
                  'Inovácia • Výskum • Vzdelávanie • Konkurencieschopnosť',
                  'Sociálna inklúzia • Zamestnanosť mladých • Odstránenie chudoby',
                  'Životné prostredie • Energetika • Zmena klímy • Nízkouhlíkové hospodárstvo',
                  'Kultúra • Občianska spoločnosť • Dobrá správa vecí verejných • Základné práva',
                  'Spravodlivosť • Vnútorné veci'
                ]
              },
              grantsUrlInfo: 'Ak sa chcete dozvedieť viac o programoch a projektov, ktoré sú financované prostredníctvom Nórskych grantov na Slovensku, navštívte',
              linkUrl: 'https://www.norwaygrants.sk/'
            },
            faqSection: {
              title: 'Často kladené otázky',
              items: [
                {
                  title: 'Čo to je ECEH platforma?',
                  description: 'Centrum predmetov s otvoreným obsahom - Education Content Engineering Hub (ECEH) predstavuje responzívnu web aplikáciu na správu vzdelávacieho obsahu a e-vzdelávanie (e-learning), štandardný Learning and Content Management System (LCMS). Umožňuje definovať, spravovať a používať modely predmetov s otvoreným obsahom špeciálne nie však exkluzívne za účelom konsolidácie so štátnym vzdelávacím programom. Ponúka funkcionalitu aj na podporu e-vzdelávania.'
                },
                {
                  title: 'Čím sa líši ECEH platforma ako LCMS od iných systémov?',
                  description: 'Štandardne iné LCMS resp. len LMS systémy ponúkajú pre vybrané predmety vypracované materiály, vyučovacie hodiny, prezentácie, video-prezentácie, pracovné listy, odkazy na elektronické encyklopédie a pod. Fundamentálny rozdiel spočíva v samotnom prístupe. ECEH platforma na to ide z opačnej strany. Skôr než ti ponúkne haldy študijného materiálu (tony textu, hodiny videí a pod.) ti vymenuje čo všetko máš na konci „dňa“ poznať, vedieť urobiť a pod. Rieši to explicitným zoznamom otázok a úloh, ktoré máš počas definovaného časopriestoru vybaviť. Dôležité je, že tak poznáš otázky a úlohy v celom predmete dopredu. Až následne ti ponúka ku každej otázke či úlohe relevantné nápovede avšak len v tej najviac adresnej podobe (odkaz na konkrétnu minútu/sekundu videa kde sa ponúka požadované, konkrétnu stranu v učebnici/knihe kde sa ponúka riešenie a pod.). Celkovo je použitý prístup analogický k úspešným prístupom zo softvérového inžinierstva (prípadmi použitia riadený prístup (Use-Case Driven Approach, Agile, Lean a pod.).'
                },
                {
                  title: 'Ako môžem začať používať ECEH platformu?',
                  description: 'Je potrebné sa zaregistrovať na <a href="https://eceh.fiit.stuba.sk">eceh.fiit.stuba.sk</a>. Nevyhnutnou podmienkou je súhlas s podmienkami ochrany osobných údajov a pravidlami používania platformy. Systém je intuitívny a registrovaným používateľom ponúka používateľský manuál.'
                },
                {
                  title: 'Aké predmety/kurzy viem nájsť na platforme ECEH?',
                  description: 'Zoznam aktuálne ponúkaných predmetov je zrejmý z Menu -Zoznam predmetov.'
                },
                {
                  title: 'Ako je riešená otázka autorstva na platforme ECEH?',
                  description: 'ECEH platforma rešpektuje a ctí autorstvo v zmysle verejnej autorskej licencie CC.'
                },
                {
                  title: 'Komu je platforma ECEH určená?',
                  description: 'Platforma je určená tým, ktorí chcú vzdelávať a tým, ktorý sa nebránia byť vzdelávaní. Principiálne všetkým, ktorým záleží na tom aby bolo jasné čo vzdelávať.'
                },
                {
                  title: 'Čo znamená pojem „model“ predmetu?',
                  description: 'Pojem model predmetu v kontexte ECEH platformy predstavuje výsledok modelovania predmetu/kurzu ako aktivity. Model reprezentuje význame detailnú podobu predmetu/kurzu ako ideu, plán či návrh. Predstavuje platformu na diskusiu zainteresovaných. Model obyčajne predchádza samotnej častokrát nákladnej realizácii idei. Model predmetu definuje počet vyučovacích jednotiek napr. vyučovacích hodín, tém, pomenúva ich zhluky tzv. tematické celky, explicitne vymedzuje otázky a úlohy asociované konkrétnej vyučovacej hodine, ponúka niekoľko inštancií nápovede v rôznych formách (text, obraz, video, audio) viazaných na konkrétnu otázku či úlohu, v prípade uzavretých otázok a úloh obsahuje aj správnu odpoveď či riešenie.'
                },
                {
                  title: 'Čo znamená pojem „vykonateľný“ model predmetu?',
                  description: 'Pojem vykonateľný pochádza zo softvérového inžinierstva. Znamená to, že program je spustiteľný v prostredí do ktorého je vnesený. Podobne aj predpis otázok/úloh je v rámci vyučovacej hodiny realizovateľný. Môže a mal by obsahovať všetky nevyhnutné súčasti na zodpovedanie otázky či vyriešenie úlohy bez zbytočnej prokrestinácie používateľmi napr. študentmi/žiakmi/účastníkmi kurzu a pod.'
                },
                {
                  title: 'Som riaditeľom strednej školy. Prečo by som sa mal zaujímať o ECEH platformu?',
                  description: 'Zaujímavým dôvodom môže byť riešenie suplovania/zastupovania učiteľov. Vytvorený model predmetu je priamo „vykonateľný“. Obsahuje vzdelávací obsah v podobe už preddefinovaných otázok a úloh, odpovedí a riešení vrátane nápovedí pre každú otázku či úlohu. Zastupujúci učiteľ preto nemusí mať súvisiacu expertízu a napriek tomu budú žiaci/študenti konfrontovaní relevantným obsahom v zmysle štátneho/školského vzdelávacieho programu. Odpadá problém s nesplnením vzdelávacích cieľov z dôvodu výpadku zodpovedného/kompetentného učiteľa.'
                },
                {
                  title: 'Som začínajúcim učiteľom. Prečo by som mal používať ECEH platformu?',
                  description: 'ECEH platforma je nastavená ponúkať viacero inštancií modelu predmetu, ktorý budete vyučovať. Jedným kliknutím na tlačidlo si viete vytvoriť klon najviac relevantnej inštancie predmetu, ktorý budete vyučovať. Vhodnosť potenciálnej inštancie je determinovaná názvom predmetu, názvom a kódom študijného odboru, ročníkom a samozrejme vzdelávacím obsahom predmetu. Aj bez ďalšieho zásahu je model použiteľný (vykonateľný).'
                },
                {
                  title: 'Som senior učiteľom. Prečo by som mal používať ECEH platformu?',
                  description: 'Aj senior učiteľ potrebuje pravidelne resp. z času na čas „dobyť baterky“. Zafinancovať priebežné, pravidelné či aspoň sporadické školenie/kurz nemusí byť také jednoduché. ECEH platforma poskytuje možnosť absolvovať existujúce predmety/kurzy bezplatne. Ďalším dôvodom môže byť aktualizácia vami vyučovaného predmetu podľa návrhu realizácie rovnomenného predmetu pre ten istý ročník, študijný odbor kód a názov ak je taký v ECEH platforme vytvorený.'
                },
                {
                  title: 'Som senior učiteľom, mám od nového školského roka z dôvodu úpravy/doplnenia pracovného úväzku vyučovať predmet, ktorý som doteraz nevyučoval. Prečo by som mal používať ECEH platformu?',
                  description: 'ECEH platforma je nastavená ponúkať viacero inštancií modelu predmetu, ktorý máte od nového školského roka vyučovať. Jedným kliknutím na tlačidlo si viete vytvoriť klon najviac relevantnej inštancie predmetu (ak je taký v ECEH platforme vytvorený), ktorý budete vyučovať. Vhodnosť potenciálnej inštancie je determinovaná názvom predmetu, názvom a kódom študijného odboru, ročníkom a samozrejme vzdelávacím obsahom predmetu. Aj bez ďalšieho zásahu je model použiteľný (vykonateľný).'
                },
                {
                  title: 'Som žiakom strednej/študentom vysokej školy. Prečo by som mal používať ECEH platformu?',
                  description: 'ECEH platforma ponúka predmety s otvoreným obsahom. Už po prihlásení sa do systému si vo vybranom predmete môžeš pozrieť všetky otázky a úlohy, ktorými si budeš musieť prejsť v rámci školského/akademického roka. Budeš tak dopredu poznať otázky a úlohy z predmetu. ECEH platforma podporuje kultiváciu vzdelávacieho obsahu a je tak predpoklad, že sa budeš učiť na otázkach/úlohách/príkladoch/zadaniach a pod., ktoré môžu zvýšiť tvoju hodnotu na trhu práce.'
                },
                {
                  title: 'Som rodičom žiaka strednej školy. Prečo by som mal používať ECEH platformu?',
                  description: 'V prípade, že spolu so svojim dieťaťom riešite otázku ďalšieho štúdia máte k dispozícii náhľad do vzdelávacieho obsahu konkrétneho študijného odboru, konkrétneho predmetu v konkrétnom ročníku a konkrétnej škole (ak škola ECEH platformu používa). Tieto informácie môžu pomôcť pri vašom rozhodovaní. ECEH platforma ponúka možnosť mailovej notifikácie o aktivite vášho dieťaťa pre konkrétny predmet a konkrétnu vyučovaciu hodinu. Vie tak upozorniť v prípade, že je potrebná adresná pomoc a najmä včas.'
                },
                {
                  title: 'Som zamestnávateľom absolventov strednej/vysokej školy. Prečo by som sa mal zaujímať o ECEH platformu?',
                  description: 'Dáva to zmysel v prípade, že ste zamestnávateľom, ktorý musí vynakladať ďalšie investície do prípravy aktuálne prijatých absolventov do zamestnania. ECEH platforma ponúka možnosť vytvorenia nového predmetu/kurzu, ktorý by sprostredkoval vybudovanie požadovaných vedomostí, zručností a kompetencií. Alternatívou je ich zapracovanie do už existujúcich relevantných predmetov/kurzov prípadne odporúčanie na zapracovanie vybranej strednej/vysokej škole.'
                }
              ]
            },
            tryFreeSection: {
              titleRow1: 'Vyskúšajte Education Content Engineering Hub.',
              titleRow2: 'Je to zadarmo.',
              cta: 'Registrovať sa'
            },
            footer: {
              title: 'Education Content Engineering Hub',
              description: 'Spolupracujeme pre zelenú, konkurencieschopnú a inkluzívnu Európu.',
              section1: {
                title: 'ECEH',
                items: [
                  {
                    label: 'Blog',
                    href: '/blog',
                    external: true
                  },
                  {
                    label: 'Zásady ochrany osobných údajov',
                    href: '/privatepolicy'
                  }
                ]
              },
              section2: {
                title: 'Adresa',
                items: [
                  'Fakulta informatiky a informačných technológií',
                  'Ilkovičova 2, SK-842 16 Bratislava 4'
                ]
              }
            }
          },

          contactPage: {
            title: 'Kontakt',
            contactItems: [
              {
                title: 'Kontaktná osoba',
                children: [
                  {
                    label: 'Ing. Ján Lang, PhD.'
                  },
                  {
                    label: 'jan.lang@stuba.sk',
                    href: 'mailto:jan.lang@stuba.sk'
                  }
                ]
              },
              {
                title: 'Adresa',
                children: [
                  {
                    label: 'Fakulta informatiky a informačných technológií STU v Bratislave'
                  },
                  {
                    label: 'Education Content Engineering Hub'
                  },
                  {
                    label: 'Ilkovičova 2'
                  },
                  {
                    label: '842 16 Bratislava 4'
                  }
                ]
              }
            ]
          },

          basic: {
            yes: 'áno',
            no: 'nie',
            ok: 'OK',
            save: 'Uložiť',
            cancel: 'Zrušiť',
            delete: 'Odstrániť',
            remove: 'Odobrať',
            edit: 'Upraviť',
            name: 'Meno',
            email: 'Email',
            password: 'Heslo',
            class: 'Trieda',
            actions: 'Akcie',
            content: 'Obsah'
          },

          welcome: {
            eceh: 'Centrum predmetov s otvoreným obsahom',
            logIn: 'Prihlásiť sa teraz',
            registerNow: 'Ešte nemaš účet? Požiadaj o pozvanie teraz!',
            h01: 'Spoločným úsilím k zelenej, konkurencieschopnej a inkluzívnej Európe',
            c01: 'Centrum predmetov s otovreným obsahom, Education Content Engineering Hub (ECEH) získal grant z Nórska v sume 170 tis. €. Projekt bol spolufinancovaný v sume 30 tis. € zo štátneho rozpočtu Slovenskej republiky. Cieľom projektu je podpora komunikácie zainteresovaných orientovaná na kultiváciu vzdelávacieho obsahu predmetov (Matematika a Programovanie v jazyku Python) s otovreným obsahom prostredníctvom ich vykonateľných modelov.',
            c02: 'Ak sa chcete dozvedieť viac o programoch a projektoch financovaných z Nórskych grantov na Slovensku, navštívte stránku',

            p01: 'Nórske granty predstavujú príspevok Nórska k zelenej, konkurencieschopnej a inkluzívnej Európe. Nórsko prostredníctvom Nórskych grantov prispieva k znižovaniu ekonomických a sociálnych rozdielov a k posilňovaniu vzájomných vzťahov s prijímateľskými štátmi v strednej a východnej Európe a v Pobaltí. Nórsko úzko spolupracuje s EÚ prostredníctvom Dohody o Európskom hospodárskom priestore (EHP). Spoločne s inými donormi poskytlo Nórsko postupne v rokoch 1994 až 2014 prostredníctvom grantových schém 3,3 miliardy eur. Nórske granty sú financované výlučne Nórskom a sú dostupné v krajinách, ktoré sa pripojili k EÚ po roku 2003. Na obdobie 2014 – 2021 dosahujú Nórske granty 1,25 miliardy eur. Prioritami tohto obdobia sú:',
            li01: 'Inovácie, výskum, vzdelávanie a konkurencieschopnosť',
            li02: 'Sociálna inklúzia, zamestnanosť mladých a odstraňovanie chudoby',
            li03: 'Životné prostredie, energia, zmena klímy a nízko uhlíkové hospodárstvo',
            li04: 'Kultúra, občianska spoločnosť, dobré spravovanie a základné práva',
            li05: 'Spravodlivosť a vnútorné záležitosti',
            p02: 'Všetky projekty sú vo výške 15% spolufinancované zo štátneho rozpočtu Slovenskej republiky.',

            h31: 'Charakteristiky projektu',
            p25: 'Názov našeho projektu je ',
            p25s: 'Centrum predmetov s otvoreným obsahom',
            p26: 'Naša výzva má označenie ',
            p26s: 'BIN SGS02',
            p27: 'Výška našeho grantu je ',
            p27s: '200 tis. Eur',
            p28: 'Sme financovanní ',
            p28s: 'Nórskymi grantami sumou 170 tis. Eur a štátnym rozpočtom sumou 30 tis. Eur',
            p29: 'Cieľ našeho projektu je ',
            p29s: 'podporiť komunikáciu zainteresovaných, ktorá je orientovaná na kultiváciu vzdelávacieho obsahu predmetov (Matematika a Programovanie v jazyku Python) s otovreným obsahom prostredníctvom ich vykonateľných modelov',
            p30: 'Naša implementácia bude prebiehať v období ',
            p30s: '09.2022 – 04.2024',

            h11: 'Aktuality',

            h21: 'ECEH: Úvodná informačná konferencia',
            p21: 'Úvodná informačná konferencia Education Content Engineering Hub (ECEH) sa konala 26. septembra 2022 v Bratislave na Fakulte informatiky a informačných technológií Slovenskej technickej univerzity v Aule Minor číslo miestnosti -1,65 v hybridnom režime (prezenčne a online). Konferencie sa zúčastnili zástupcovia prijímateľa FIIT STU a jej partnerov zo Strednej školy elektrotechnickej, Hálova 16, Bratislava a občianskeho združenia Aj Ty v IT. Prítomní boli aj zástupcovia partnera donorskej krajiny z Nórskej univerzity vedy a techniky NTNU z Nórska.',
            p22: 'Pozvanie prijali aj zástupcovia výskumnej agentúry, zástupcovia veľvyslanectva Nórskeho kráľovstva, vysokoškolskí pedagógovia a študenti.',
            p23: 'Program bol nasledovný:',
            li21: 'Úvod a privítanie',
            li22: 'Predstavenie Nórskych fondov a Výskumnej agentúry',
            li23: 'Predstavenie projektových partnerov',
            li24: 'Predstavenie riešenia ECEH ako nástroja otvárajúceho nové možnosti vzdelávania',
            li25: 'Výzva na akciu, záver',
            p24: 'Online komunikácia bola realizovaná prostredníctvom systému webex.'
          },

          menu: {
            logoutSuccessful: 'Boli ste úspešne odhlásení',
            exitTest: 'Naozaj chcete ukončiť testovanie?',
            exitTask: 'Naozaj chcete ukončiť prácu na úlohách?',
            testOptions: 'Možnosti testu',
            testClose: 'Uzavrieť test',
            testStop: 'Ukončiť testovanie',
            testSubmit: 'Odovzdať test',
            quit: 'Ukončiť',
            quitTask: 'Ukončiť prácu',
            home: 'Domov',
            tasks: 'Úlohy',
            subjects: 'Predmety',
            listOfTasks: 'Zoznam úloh',
            addTask: 'Pridať úlohu',
            listOfSubjects: 'Zoznam predmetov',
            createSubject: 'Vytvoriť predmet',
            accountSettings: 'Nastavenia účtu',
            logOut: 'Odhlásiť sa',
            privacyPolicy: 'Pravidlá ochrany osobných údajov',
            flagPath: 'flag_svk.png',
            changeLanguage: 'Slovensky',
            addUser: 'Pozvať používaťeľa',
          },

          delete: {
            areYouSure: 'Ste si istý, že chcete danú položku zmazať?',
            itemDelete: 'Daná položka bude odstránená zo zoznamu!'
          },

          error: {
            e403: 'Prepáčte, nemáte prístup na túto stránku',
            e404: 'Prepáčte, požadovaná stránka neexistuje',
            e500: 'Prepáčte, na serveri nastala chyba',
            backHome: 'Návrat domov'
          },

          login: {
            login: 'Prihlásenie',
            loginMe: 'Prihlásiť sa',
            personalInformation: 'Osobné údaje',
            registration: 'Žiadosť o registráciu',
            textField: 'Dôvod žiadosti o prístup',
            textFieldPlaceholder: 'Sem napíšte dôvod',
            registerMe: 'Zaslať požiadavku',
            registerMe_mail: 'Zaregistrovať sa',
            loginSuccessful: 'Boli ste úspešne prihlásený',
            loginUnsuccessful: 'Prihlásenie sa nepodarilo',
            passwordResetInstruction: 'Na Váš email, bola odoslaná správa, pokračujte podľa inštrukcií v ňom',
            passwordResetUnsuccessful: 'Niekde nastal problém, akciu sa nepodarilo vykonať',
            passwordResetSuccessful: 'Heslo bolo úspešne zmenené',
            passwordsNonidentical: 'Zadané heslá sa nezhodujú',
            emailNonexisting: 'Zadaná emailová adresa neexistuje',
            tokenValid: 'Token je platný, prosím pokračujte v obnove hesla',
            tokenNonvalid: 'Token sa nepodarilo overiť, prosím znova odošlite požiadavku na zmenu hesla',
            emailWrongFormat: 'Prosím zadajte platný email',
            emailEnter: 'Prosím zadajte vašu emailovú adresu',
            emailExists: 'Zadaná emailová adresa už existuje',
            passwordEnter: 'Prosím zadajte heslo',
            password: 'Heslo',
            passwordForgot: 'Zabudli ste heslo?',
            passwordRecovery: 'Obnova hesla',
            passwordRecoveryInstruction: 'Pre zaslanie dalších inštrukcií pre obnovu hesla, prosím zadajte Vašu emailovú adresu použitú pri registrácií.',
            passwordEnterNew: 'Pre obnovu hesla zadajte nové heslo k vašemu účtu.',
            passwordRequirements: 'Heslo musí obsahovať minimálne 6 znakov',
            passwordNew: 'Nové heslo',
            passwordReEnter: 'Prosím znovu zadajte heslo pre overenie!',
            passwordRepeatNew: 'Zopakujte nové heslo',
            passwordRepeat: 'Zopakujte heslo',
            passwordChange: 'Zmeniť heslo',
            backToLogin: 'Späť na prihlásenie',
            registrationSuccessful: 'Boli ste úspešne zaregistrovaný',
            registration_header: 'Registrácia',
            registrationRequestSuccessful: 'Žiadosť bola úpešne zaslaná',
            registrationError: 'Účet sa nepodarilo zaregistrovať, registráciu prosím zopakujte',
            policyAgreement: 'Pre pokračovanie musíte súhlasiť s podmienkami Ochrany osobných údajov',
            policyAgreed: 'Prečítal som si a súhlasím s podmienkami Ochrany osobných údajov',
            name: 'Krstné meno',
            lastname: 'Priezvisko',
            enterName: 'Prosím zadajte vaše krstné meno',
            enterLastname: 'Prosím zadajte vaše priezvisko',
            organization: 'Organizácia',
            organizationSelect: 'Prosím vyberte zo zoznamu vašu organizáciu',
            class: 'Trieda',
            classEnter: 'Prosím zadajte triedu. Napr. 4.A',
            classRequirements: 'Maximum 5 znakov',
            moreInfo: 'Viac info tu'
          },

          dashboard: {
            listOfCreatedSubjects: 'Zoznam vytvorených predmetov',
            listOfRegisteredSubjects: 'Zoznam registrovaných predmetov',
            listOfCreatedTasks: 'Zoznam vytvorených úloh',
            enterSearchText: 'Zadajte vyhľadávaný text'
          },

          activities: {
            copySubject: 'Kopírovať predmet',
            deleteSubject: 'Odstrániť predmet',
            editSubject: 'Upraviť predmet',
            authorUnknown: 'Autor nezistený',
            public: 'Verejný',
            publicSubject: 'Verejný predmet',
            privateSubject: 'Súkromný predmet',
            title: 'Názov',
            author: 'Autor',
            program: 'Št. odbor',
            programCode: 'Kód št. odboru',
            notListed: 'Neuvedené',
            year: 'Ročník',
            description: 'Popis',
            addAll: 'Pridať všetkých',
            addStudents: 'Pridať študentov',
            enterTitle: 'Prosím zadajte názov vyučovacej hodiny',
            enterDescription: 'Prosím zadajte popis vyučovacej hodiny',
            errorPageLoad: 'Obsah stránky sa neporadilo načítať, skúste stránku načítať neskôr',
            errorSubjectLoad: 'Predmet sa nepodarilo načítať, skúste stránku načítať neskôr',
            subjectName: 'Názov predmetu',
            subjectField: 'Študijný odbor',
            subjectFieldCode: 'Kód',
            subjectYear: 'Ročník',
            subjectEnterContent: 'Prosím doplňte obsah predmetu',
            subjectCreate: 'Vytvoriť predmet',
            subjectCreateSuccess: 'Predmet bol uspešne vytvorený',
            subjectCreateUnsuccess: 'Predmet sa nepodarilo vytvoriť',
            subjectCopySuccess: 'Kópia predmetu bola úspešne vytvorená',
            subjectCopyUnsuccess: 'Kópiu predmetu sa nepodarilo vytvoriť',
            subjectCopyQuestion: 'Ste si istý, že chcete vytvoriť kópiu tohto predmetu?',
            subjectCopyWarning: 'Vytvorením kópie sa predmet zaradí medzi vaše vlastné',
            subjectCopy: 'Vytvoriť kópiu',
            subjectDeleteQuestion: 'Ste si istý, že chcete predmet odstrániť?',
            subjectDeleteWarning: 'Ak sa rozhodnete predmet odstrániť, bude trvalo odstránený.',
            subjectDeletedSuccessful: 'Predmet bol úspešne odstránený.',
            subjectDeleteUnsuccessful: 'Predmet sa nepodarilo odstrániť.',
            subjectRefreshError: 'Predmet sa nepodarilo aktualizovať, skúste to prosím neskôr.',
            subjectEdit: 'Úprava predmetu',
            subjectSave: 'Uložiť zmeny',
            subjectEnterName: 'Prosím zadajte názov predmetu',
            subjectDisplayToOthers: 'Povoliť iným používateľom zobraziť tento predmet',
            subjectRemoveStudent: 'Odobrať študenta z predmetu',
            subjectDisplayInfo: 'Povoliť iným používateľom zobraziť tento predmet',
            subjectUpdateSuccess: 'Predmet bol úspešne aktualizovaný',
            subjectUpdateUnsuccess: 'Predmet sa nepodarilo aktualizovať',
            subjectDetail: 'Detail predmetu',
            subjectAddStudents: 'Pridanie študentov do predmetu',
            subjectRemoveStudents: 'Odobrať všetkých študentov z predmetu',
            subjectsDisplayMy: 'Zobraziť len moje predmety',
            subjectsList: 'Zoznam predmetov',
            subjectsSearch: 'Zadajte vyhľadávaný text',
            examActivationError: 'Test sa nepodarilo aktivovať, skúste stránku načítať neskôr',
            active: 'Aktívny',
            notActive: 'Neaktívny',
            startInfoTest: 'Spustiť informatívne testovanie',
            startTaskWork: 'Začať pracovať na úlohách',
            teacherPanel: 'Učiteľský panel',
            tasksAddDelete: 'Pridať/vymazať úlohy',
            tasksAddSuccess: 'Úlohy boli úspešne pridané do vyučovacej hodiny',
            tasksLoadUnsuccess: 'Zoznam úloh zvoleného predmetu sa nepodarilo aktualizovať',
            tasksAddFromLesson: 'Pridanie úloh patriacich do vyučovacej hodiny',
            tasksDisplayMy: 'Zobraziť len moje úlohy:',
            taskEditWarning: 'Pri zmene úloh vo vyučovacej hodine sa resetuje aktuálny stav učiteľského panelu.',
            lessonEdit: 'Úpraviť vyučovaciu hodinu',
            lessonEditInfo: 'Upraviť informácie o vyučovacej hodine',
            lessonDelete: 'Odstrániť vyučovaciu hodinu',
            lessonDeleteSuccess: 'Vyučovacia hodina bola z predmetu úspešne odstránená',
            lessonDeleteUnsuccess: 'Vyučovacie hodiny sa nepodarilo z predmetu odstrániť',
            lessonDeleteQuestion: 'Ste si istý, že chcete vyučovaciu hodinu odstrániť?',
            lessonDeleteWarning: 'Ak sa rozhodnete vyučovaciu hodinu odstrániť, bude odstránená len z tohto predmetu.',
            lessonUpdateSuccess: 'Vyučovacia hodina bola úspešne aktualizovaná',
            lessonUpdateUnsuccess: 'Vyučovaciu hodinu sa nepodarilo aktualizovať',
            lessonAutorshipError: 'Vyučovaciu hodinu nemôžete upraviť, pretože nie ste jej autorom',
            lessonAddedToSubject: 'Vytvorená vyučovacia hodina bola úspešne pridaná do predmetu',
            lessonCreateUnsuccess: 'Novú vyučovaciu hodinu sa nepodarilo vytvoriť',
            lessonsLoadUnsuccess: 'Vyučovacie hodiny sa neporadilo načítať, skúste stránku načítať neskôr',
            lessonAdd: 'Pridať vyučovaciu hodinu',
            lessonAddNew: 'Pridať novú vyučovaciu hodinu',
            lessonAddToSubject: 'Pridanie vyučovacej hodiny do predmetu',
            lessonsSelect: 'Vyberte požadované vyučovacie hodiny',
            listOfLessons: 'Zoznam vyučovacích hodín',
            tests: 'Testy',
            testAdd: 'Pridať test',
            testStart: 'Spustiť test',
            testNotExist: 'Neexistujú žiadne testy',
            testShowInfo: 'Testy sa zobrazia iba študentom tohto predmetu',
            showResults: 'Zobraziť výsledky',
            showOverallResults: 'Zobraziť celkové výsledky',
            tasks: 'Úlohy',
            questions: 'Otázky',
            sortStudents: 'Zoradiť študentov',
            studentRemoveQuestion: 'Ste si istý, že chete študenta odobrať z predmetu?',
            studentRemoveWarning: 'Ak sa rozhodnete študenta odobrať, bude odobraný len z aktuálneho predmetu.',
            studentsRemoveQuestion: 'Ste si istý, že chcete odobrať všetkých študentov daného predmetu?',
            studentsRemoveWarning: 'Ak sa rozhodnete študentov odobrať, budú odobraní len z aktuálneho predmetu.',
            studentsListLoadUnsuccess: 'Zoznam študentov sa neporadilo načítať, skúste stránku načítať neskôr',
            studentsListUpdateSuccess: 'Zoznam študentov predmetu bol úspešne aktualizovaný',
            studentsListUpdateUnsuccess: 'Nepodarilo sa aktualizovať zoznam študentov predmetu',
            studentsLoadUnsuccess: 'Nepodarilo sa načítať študentov',
            studentsList: 'Zoznam študentov predmetu',
            authorsList: 'Zoznam autorov',
            authorsSelect: 'Vyberte požadovaných autorov',
            organizations: 'Organizácie',
            selectOrganizationType: 'Vyberte požadovaný typ organizácie',
            filter: 'Filter',
            students: 'Študenti:',
            selectStudents: 'Vyberte požadovaných študentov',
            validate: 'Validovať',
            assignmentList: 'Zoznam úloh:',
            copyTask: 'Kopírovať úlohu'
          },

          events: {
            correct: 'Správna odpoveď',
            hints: 'Nápovedy',
            title: 'Názov',
            author: 'Autor',
            type: 'Typ:',
            answers: 'Odpovede',
            edit: 'Upraviť',
            numberOfOptions: 'Počet možností musí byť najmenej 2',
            eventDescription: 'Prosím zadajte popis úlohy',
            eventCreateSuccess: 'Úloha bola vytvorená',
            eventCreateUnsuccess: 'Úlohu sa nepodarilo vytvoriť',
            eventEnterDuration: 'Je potrebné zadať trvanie úlohy',
            eventMaxDuration: 'Maximálny čas na úlohu je 5 hodín',
            eventMinDuration: 'Minimálny čas na úlohu je 10 sekúnd',
            eventDurationMinutesExceeded: 'Maximálny možný vstup pre minúty je 59',
            optionEnterDescription: 'Prosím vyplňte popis možnosti',
            hintEnterNameAndBody: 'Prosím doplňte názov aj text nápovedy',
            assignmentPreview: 'Náhľad',
            assignmentAdd: 'Pridanie úlohy',
            assignmentAddNow: 'Pridať úlohu',
            assignmentTitle: 'Názov',
            assignmentTitleEnter: 'Prosím doplňte názov úlohy',
            assignmentContentEnter: 'Prosím doplňte obsah úlohy',
            assignmentType: 'Typ úlohy',
            assignmentTypeEnter: 'Prosím vyberte typ úlohy',
            assignmentMultichoice: 'Otázka s výberom odpovede',
            assignmentOpentext: 'Otvorená úloha',
            assignmentNoanswer: 'Bez odpovede',
            assignmenstRecommendedTime: 'Odporúčaný čas na vyriešenie úlohy',
            assignmentOptions: 'Možnosti',
            assignmentCorrectAnswer: 'Správna odpoveď',
            assignmentCorrectAnswerEnter: 'Vložte správnu odpoveď',
            assignmentNewOption: 'Nová možnosť',
            assignmentNewOptionBody: 'Prosím doplňte obsah novej možnosť',
            assignmentDelete: 'Odstrániť úlohu',
            assignmentDeleteWarning: 'Ak sa rozhodnete úlohu odstrániť, bude trvalo odstránená.',
            assignmentDeleteQuestion: 'Ste si istý, že chcete úlohu odstrániť ?',
            assignmentDeleteSuccess: 'Úloha bola úspešne odstránená.',
            assignmentDeleteUnsuccess: 'Úlohu sa nepodarilo odstrániť.',
            assignmentSearch: 'Zadajte vyhľadávaný text',
            assignmentPageError: 'Úlohu sa nepodarilo načítať, skúste stránku načítať neskôr',
            assignmentRecommendedTime: 'Odporúčaný čas na vyriešenie úlohy:',
            assignmentList: 'Zoznam úloh',
            displayOnlyMy: 'Zobraziť len moje úlohy:',
            optionAdd: 'Pridať možnosť',
            optionAddLong: 'Pridať novú možnosť odpovede',
            hintTitle: 'Názov nápovedy',
            hintBodyEnter: 'Prosím vyplňte názov nápovedy',
            hintAdd: 'Pridať nápovedu',
            imageUploadSuccess: 'Obrázok bol nahraný',
            imageUploadUnsuccess: 'Obrázok sa nepodarilo nahrať',
            noActionAvailable: 'Nie je možné vykonať žiadnu akciu',
            authorUnknown: 'Autor nezistený',
            errorPageLoad: 'Obsah stránky sa neporadilo načítať, skúste stránku načítať neskôr',
            loading: 'Načítavam...',
            hintDescription: 'Obsah nápovedy',
            enterHintDescription: 'Prosím vyplňte obsah nápovedy'
          },

          exams: {
            results: 'Výsledky',
            date: 'Dátum:',
            back: 'Naspäť',
            studentName: 'Meno žiaka',
            shortPoint: 'b',
            of: 'z',
            submitError: 'Test sa nepodarilo odovzdať, prosím skúste to znovu',
            pageExamError: 'Obsah testu sa nepodarilo načítať, skúste ho prosím načítať neskôr',
            pageError: 'Obsah stránky sa neporadilo načítať, skúste stránku načítať neskôr',
            examFinished: 'Testovanie bolo ukončené',
            examPointsEarned: 'Získali ste',
            examPointsTotal: 'bodov z ',
            examClose: 'Uzavrieť test',
            examCloseWarning: 'Ak opustíte stránku, test bude odovzdaný. Ozaj chcete opustiť stránku?',
            examFinishQuestion: 'Naozaj chcete ukončiť testovanie?',
            examSubmit: 'Odovzdať test',
            prevQuestion: 'Predošlá otázka',
            nextQuestion: 'Ďalšia otázka',
            examDidNotTake: 'Nepísal!',
            examResults: 'Výsledky testu',
            examResult: 'Výsledok testu',
            examTitle: 'Názov testu:',
            resultPercentage: 'Celkový výsledok v percentách',
            totalCorrectAnswers: 'Celkový počet správnych odpovedí:',
            totalPointsEarned: 'Celkový počet získaných bodov:',
            resultOfQuestions: 'Výsledky k otázkam',
            numberOfQuestion: 'Číslo otázky',
            titleOfQuestion: 'Názov otázky',
            successOfQuestion: 'Úspešnosť otázky',
            alreadyCompletedError: 'Test už bol raz vyplnený'

          },

          profile: {
            class: 'Trieda',
            name: 'Meno',
            surname: 'Priezvisko',
            email: 'e-mail',
            organization: 'Organizácia',
            pageError: 'Obsah stránky sa neporadilo načítať, skúste stránku načítať neskôr',
            passwordDoNotMatch: 'Zadané heslá sa nezhodujú',
            changeSuccess: 'Udaje boli úspešne zmenené.',
            changeUnsuccess: 'Údaje sa nepodarilo uložiť. Skúste to znova.',
            passwordChangeSuccess: 'Heslo bolo úspešne zmenené.',
            passwordChangeUnsuccess: 'Heslo sa nepodarilo zmeniť. Skúste to znova.',
            userInfo: 'Informácie o používateľovi',
            inviteLink: 'Registračný link bol úspešne zaslaný',
            changePassword: 'Zmeniť heslo',
            changeOfPassword: 'Zmena hesla',
            changeUserInfo: 'Zmeniť údaje',
            changeOfUserInfo: 'Zmena údajov',
            enterOldPassword: 'Prosím zadajte pôvodné heslo!',
            enterNewPassword: 'Prosím zadajte nové heslo!',
            enterNewPasswordRepeart: 'Prosím znovu zadajte nové heslo pre overenie!',
            passwordMinRequirements: 'Heslo musí obsahovať minimálne 6 znakov',
            oldPassword: 'Staré heslo',
            newPassword: 'Nové heslo',
            repeatNewPassword: 'Zopakujte nové heslo'
          },

          tasks: {
            pageError: 'Obsah stránky sa neporadilo načítať, skúste stránku načítať neskôr',
            taskDone: 'Úloha dokončená',
            taskIsDone: 'Úloha je dokončená',
            taskDoneAlready: 'Úloha je už dokončená',
            generalError: 'Niečo sa pokazilo! Skúste to znova.',
            prevTask: 'Predošlá úloha',
            nextTask: 'Ďalšia úloha',
            quit: 'Ukončiť',
            done: 'Hotovo',
            hints: 'Nápovedy',
            congrat: 'Gratulujeme k vypracovaniu všetkých úloh',
            taskElaboration: 'Vypracovávanie úloh',
            taskTitleError: 'Názov vyučovacej hodiny sa nepodarilo načítať'
          },

          panel: {
            inLesson: 'Vo vyučovacej hodine',
            outLesson: 'Mimo vyučovacej hodiny',
            Offline: 'Offline',
            studentName: 'Meno študenta'
          },

          test: {
            testCreateSuccess: 'Test bol úspešne vytvorený.',
            testCreateUnsuccess: 'Test sa nepodarilo vytvoriť',
            testPickDateTitle: 'Zvoľte korektný dátum a korektný názov testu.',
            pageError: 'Obsah stránky sa neporadilo načítať, skúste stránku načítať neskôr',
            loadError: 'Došlo k chybe pri načítávaní stránky, skúste znova načítať stránku',
            initAnswerError: 'Nesprávne inicializované možnosti otázky!',
            title: 'Názov',
            created: 'Vytvorené',
            recommendedTime: 'Odporúčaný čas na vyriešenie úlohy',
            actions: 'Akcie',
            deleteTasks: 'Vymazať úlohy',
            listOfLessons: 'Zoznam vyučovacích hodín',
            listOfTasks: 'Zoznam úloh pre vybranú vyučovaciu hodinu',
            addLesson: 'Pridať vyučovaciu hodinu do testu',
            listOftasksForTest: 'Zoznam úloh pre vytváraný test',
            createTest: 'Vytvoriť test',
            displayResults: 'Zobraziť výsledky',
            nextQuestion: 'Ďalšia otázka',
            evaluate: 'Ohodnotiť',
            hints: 'Nápovedy',
            questionAlreadeAnswered: 'Otázka už bola správne zodpovedaná',
            results: 'Výsledky',
            closeTest: 'Uzavrieť test',
            numberCorrect: 'Počet správne označených odpovedí: ',
            numberUncorrect: 'Počet nesprávne označených odpovedí: ',
            correctAnswers: 'Správne zodpovedané otázky: ',
            successRate: 'Celková úspečnosť: ',
            informativeTesting: 'Informatívne testovanie',
            lessonTitleError: 'Názov vyučovacej hodiny sa nepodarilo načítať'
          }
        }
      },
      en: {
        translation: {
          titlePage: {
            secondaryHeader: {
              links: [
                {
                  label: 'Blog',
                  url: '/blog',
                  target: '_blank',
                  external: true
                },
                {
                  label: 'Contact',
                  url: '/contact',
                  target: '_self'
                },
                {
                  label: 'FAQ',
                  url: '/#FAQ',
                  target: '_self'
                }
              ],
              greeting: 'Hello, {{user}}!',
              dashboard: 'Dashboard',
              home: 'Home'
            },
            titleSection: {
              title: 'Education Content Engineering Hub',
              description: 'Our goal is tu cultivate and communicate open content subjects.'
            },
            progressMonitoringSection: {
              title: 'Real-time Student Progress Monitoring for Teachers',
              description: 'Our Teacher Panel offers real-time student progress monitoring, empowering teachers with immediate insights to track and support student performance. Stay connected and drive student success with our intuitive tools designed to enhance classroom engagement and personalized instruction.'
            },
            blogSection: {
              title: 'Explore Our Blog: Insights, Tips, and Inspiration for Educational Enthusiasts',
              description: 'Discover a world of knowledge and inspiration on our blog. Engaging articles, valuable tips, and fresh perspectives await. Join our vibrant community of learners and educators. Read now and be inspired!',
              ctaText: 'Blog'
            },
            projectOverviewSection: {
              title: 'Project Overview: Education Content Engineering Hub',
              overviewItems: [
                {
                  title: 'Project Name:',
                  description: 'Education Content Engineering Hub.'
                },
                {
                  title: 'Call for Proposals Label:',
                  description: 'BIN SGS02.'
                },
                {
                  title: 'Grant Amount:',
                  description: 'We have been awarded a grant of 200,000 Euros.'
                },
                {
                  title: 'Funding Sources:',
                  description: 'Our funding comes from Norwegian grants (170,000 Euros) and the state budget (30,000 Euros).'
                },
                {
                  title: 'Project Objective:',
                  description: 'We aim to improve educational content in Mathematics and Programming in Python by facilitating collaboration among stakeholders and utilizing open content and executable models.'
                },
                {
                  title: 'Implementation Period:',
                  description: 'Our project will be carried out from September 2022 to April 2024.'
                }
              ]
            },
            grantsSection: {
              title: 'Norwegian grants represent Norway\'s contribution to green, competitive and inclusive Europe',
              subtitle: 'All projects are in the amount of 15% co-financed from the state budget Slovak Republic.',
              description: {
                paragraph1: 'Norway through Norwegian Grants contributes to the reduction of economic and social differences and to empowerment mutual relations with recipient states v Central and Eastern Europe and the Baltics. Norway works closely with the EU through Agreements on the European Economic Area (EEA).',
                paragraph2: 'Together with other donors, it provided Norway gradually between 1994 and 2014 3.3 billion through grant schemes euro Norwegian grants are financed exclusively by Norway and are available in countries that have joined the EU after 2003. For the period 2014-2021 they reach Norwegian grants of 1.25 billion euros.'
              },
              priorities: {
                title: 'Current Priorities',
                items: [
                  'Innovation • Research • Education • Competitiveness',
                  'Social inclusion • Youth employment • Poverty eradication',
                  'Environment • Energy • Climate change • Low carbon economy',
                  'Culture • Civil society • Good governance • Fundamental rights',
                  'Justice • Internal affairs'
                ]
              },
              grantsUrlInfo: 'If you want to know more about programmes and projects financed by the Norway Grants in Slovakia, visit',
              linkUrl: 'https://www.norwaygrants.sk/en/'
            },
            faqSection: {
              title: 'Frequently asked questions',
              items: [
                {
                  title: 'What is the ECEH platform?',
                  description: 'The Education Content Engineering Hub (ECEH) is a responsive web application for managing educational content and e-learning, a standard Learning and Content Management System (LCMS). It allows you to define, manage and use open content subject models specifically but not exclusively for the purpose of consolidation with the state curriculum. It also offers functionality to support e-learning.'
                },
                {
                  title: 'How does the ECEH platform as an LCMS differ from other systems?',
                  description: 'Standard other LCMS or only LMS systems offer prepared materials for selected subjects, lessons, presentations, video-presentations, worksheets, links to electronic encyclopedias, etc. The fundamental difference lies in the approach itself. The ECEH platform approaches this from the opposite side. Before it offers you piles of study material (tons of text, hours of videos, etc.), it lists what you should know, be able to do, etc., at the end of the "day". It solves it with an explicit list of questions and tasks that you have to complete during the defined time space. It is important that you know the questions and tasks for the entire subject in advance. Only then will it offer you relevant hints for each question or task. However, only in the most relevant form (link to a specific minute/second of the video where the desired is offered, a specific page in the textbook/book where the solution is offered, etc.). Overall, the approach used is analogous to successful approaches from software engineering (Use-Case Driven Approach, Agile, Lean, etc.).'
                },
                {
                  title: 'How can I start using the ECEH platform?',
                  description: 'It is necessary to register at <a href="https://eceh.fiit.stuba.sk">eceh.fiit.stuba.sk</a>. An indispensable condition is the agreement with the terms of personal data protection and the rules of use of the platform. The system is intuitive and offers a user manual to registered users.'
                },
                {
                  title: 'What subjects/courses can I find on the ECEH platform?',
                  description: 'The list of currently offered subjects can be seen from Menu - Subjects.'
                },
                {
                  title: 'How is the issue of authorship resolved on the ECEH platform?',
                  description: 'The ECEH platform respects and honors authorship in the sense of the CC public copyright license.'
                },
                {
                  title: 'Who is the ECEH platform intended for?',
                  description: 'The platform is intended for those who want to educate and those who do not resist being educated. In principle, everyone who cares about what to educate is clear.'
                },
                {
                  title: 'What does the term "model" of a subject mean?',
                  description: 'The term subject model in the context of the ECEH platform represents the result of subject/course modeling as an activity. The model represents a meaningfully detailed form of the subject/course as an idea, plan or proposal. It represents a platform for interested parties to discuss. The model usually precedes the often costly realization of the idea itself. The subject model defines the number of teaching units, e.g. lessons, topics, names their clusters so-called thematic units, explicitly defines the questions and tasks associated with a specific lesson, offers several instances of help in various forms (text, image, video, audio) linked to a specific question or task, in the case of closed questions and tasks, it also contains the correct answer or solution.'
                },
                {
                  title: 'What does the term "executable" subject model mean?',
                  description: 'The term executable comes from software engineering. It means that the program is executable in the environment in which it is launched. Similarly, the prescription of questions/tasks can be implemented within the lesson. It can and should contain all the necessary components to answer a question or solve a task without unnecessary procrastination by users, e.g. students/pupils/course participants etc.'
                },
                {
                  title: 'I am a high school director. Why should I be interested in the ECEH platform?',
                  description: 'An interesting reason can be the solution of substituting teachers. The created subject model is directly "executable". It contains educational content in the form of predefined questions and tasks, answers and solutions, including hints/help for each question or task. Therefore, the substitute teacher does not have to have related expertise, and nevertheless the pupils/students will be confronted with relevant content in terms of the state/school educational program. The problem of failure to meet educational goals due to the absence of a responsible/competent teacher is eliminated.'
                },
                {
                  title: 'I am a beginning teacher. Why should I use the ECEH platform?',
                  description: 'The ECEH platform is set up to offer multiple instances of the subject model you will be teaching as well. With one click of a button, you can create a clone of the most relevant instance of the subject you are going to teach. The suitability of a potential instance is determined by the name of the subject, the name and code of the field of study, the grade and, of course, the educational content of the subject. Even without further intervention, the model is usable (executable).'
                },
                {
                  title: 'I am a senior teacher, from the new school year I have to teach a subject that I have not taught so far due to the adjustment/addition of working hours. Why should I use the ECEH platform?',
                  description: 'The ECEH platform is set to offer multiple instances of the subject model that you have to teach from the new school year. With one click of a button, you can create a clone of the most relevant instance of the subject (if one is created in the ECEH platform) that you will teach. The suitability of a potential instance is determined by the name of the subject, the name and code of the field of study, the grade and, of course, the educational content of the subject. Even without further intervention, the model is usable (executable).'
                },
                {
                  title: 'I am a high school/college student. Why should I use the ECEH platform?',
                  description: 'The ECEH platform offers subjects with open content. Already after logging into the system, you can see all the questions and tasks you will have to go through during the school/academic year in the selected subject. That way, you will know the questions and tasks from the subject in advance. The ECEH platform supports the cultivation of educational content, so it is assumed that you will learn from questions/tasks/examples/assignments, etc., which can increase your value on the labor market.'
                },
                {
                  title: 'I am a parent of a high school student. Why should I use the ECEH platform?',
                  description: 'If you and your child are solving the issue of further studies, you have access to the educational content of a specific field of study, a specific subject in a specific year and a specific school (if the school uses the ECEH platform). This information can help you make a decision. The ECEH platform offers the option of email notification about your child\'s activity for a specific subject and a specific lesson. This way, teh system can notify you if direct assistance is needed, and especially in time.'
                },
                {
                  title: 'I am an employer of high school/college graduates. Why should I be interested in the ECEH platform?',
                  description: 'It makes sense if you are an employer who has to make additional investments in preparing currently hired graduates for employment. The ECEH platform offers the possibility of creating a new subject/course that would mediate creation of the required knowledge, skills and competences. An alternative is to incorporate them into already existing relevant subjects/courses, or to recommend them to a selected high school/university.'
                }
              ]
            },
            tryFreeSection: {
              titleRow1: 'Try Education Content Engineering Hub.',
              titleRow2: 'It’s free.',
              cta: 'Sign up'
            },
            footer: {
              title: 'Education Content Engineering Hub',
              description: 'Working together for a green, competetive and inclusive Europe.',
              section1: {
                title: 'ECEH',
                items: [
                  {
                    label: 'Blog',
                    href: '/blog',
                    external: true
                  },
                  {
                    label: 'Privacy Policy',
                    href: '/privatepolicy'
                  }
                ]
              },
              section2: {
                title: 'Address',
                items: [
                  'Faculty of Informatics and Information Technologies',
                  'Ilkovicova 2, SK-842 16 Bratislava 4'
                ]
              }
            }
          },

          contactPage: {
            title: 'Contact',
            contactItems: [
              {
                title: 'Contact person',
                children: [
                  {
                    label: 'Ing. Ján Lang, PhD.'
                  },
                  {
                    label: 'jan.lang@stuba.sk',
                    href: 'mailto:jan.lang@stuba.sk'
                  }
                ]
              },
              {
                title: 'Address',
                children: [
                  {
                    label: 'Faculty of Informatics and Information Technologies'
                  },
                  {
                    label: 'Education Content Engineering Hub'
                  },
                  {
                    label: 'Ilkovicova 2'
                  },
                  {
                    label: '842 16 Bratislava 4'
                  }
                ]
              }
            ]
          },

          basic: {
            yes: 'Yes',
            no: 'No',
            ok: 'OK',
            save: 'Save',
            cancel: 'Cancel',
            delete: 'Delete',
            remove: 'Remove',
            edit: 'Edit',
            name: 'Name',
            email: 'Email',
            password: 'Password',
            class: 'Class',
            actions: 'Actions',
            content: 'Content'
          },

          welcome: {
            eceh: 'Education Content Engineering Hub',
            logIn: 'Log in now',
            registerNow: 'Don\'t have an account yet? Ask for registration now!',

            h01: 'Working together for a green, competitive and inclusive Europe',
            c01: 'Education Content Engineering Hub (ECEH) received a grant from Norway in the amount of 170,000. €. The project was co-financed in the amount of 30 thousand. € from the state budget of the Slovak Republic. The goal of the project is to support the communication between all the stakeholders in order to cultivate the educational content of the open content subjects (Mathematics and Programming in the Python language) by creating their executable models.',
            c02: 'If you want to know more about programmes and projects financed by the Norway Grants in Slovakia, visit',

            p01: 'Norwegian grants represent Norway\'s contribution to green, competitive and inclusive Europe. Norway through Norwegian Grants contributes to the reduction of economic and social differences and to empowerment mutual relations with recipient states v Central and Eastern Europe and the Baltics. Norway works closely with the EU through Agreements on the European Economic Area (EEA). Together with other donors, it provided Norway gradually between 1994 and 2014 3.3 billion through grant schemes euro Norwegian grants are financed exclusively by Norway and are available in countries that have joined the EU after 2003. For the period 2014-2021 they reach Norwegian grants of 1.25 billion euros. The priorities of this periods are:',
            li01: 'Innovation, research, education and competitiveness',
            li02: 'Social inclusion, youth employment and poverty eradication',
            li03: 'Environment, energy, climate change and low carbon economy',
            li04: 'Culture, civil society, good governance and fundamental rights',
            li05: 'Justice and internal affairs',
            p02: 'All projects are in the amount of 15% co-financed from the state budget Slovak Republic.',

            h31: 'Characteristics of the project',
            p25: 'The name of our project is ',
            p25s: 'Education Content Engineering Hub',
            p26: 'Out call for proposals has a label ',
            p26s: 'BIN SGS02',
            p27: 'The amount of our grant is ',
            p27s: '200 000 Eur',
            p28: 'We are funded by the ',
            p28s: 'Norwegian grants in the amount of 170 000 Euros and the state budget in the amount of 30 000 Eur',
            p29: 'The goal of our project is to ',
            p29s: 'support the communication of Stakeholders aimed at cultivating the educational content of subjects (Mathematics and Programming in Python) with open content through their executable models',
            p30: 'Our implementation will take place in the period of ',
            p30s: '09.2022 – 04.2024',

            h11: 'News',

            h21: 'ECEH: Introductory Information Conference',
            p21: 'The Education Content Engineering Hub (ECEH) Introductory Information Conference took place on September 26, 2022 in Bratislava at the Faculty of Informatics and Information Technologies of the Slovak Technical University in Aula Minor room number -1.65 in hybrid mode (face-to-face and online). The conference was attended by representatives of the recipient FIIT STU and its partners from the Secondary Industrial School of Electrical Engineering, Hálova 16, Bratislava, and the civic association Aj Ty v IT. Representatives of the donor country\'s partner from the Norwegian University of Science and Technology NTNU from Norway were also present.',
            p22: 'The invitation was also accepted by representatives of the research agency, representatives of the embassy of the Kingdom of Norway, university teachers and students.',
            p23: 'The program was as follows:',
            li21: 'Welcome speech',
            li22: 'Introducing Norway funds and Research Agency',
            li23: 'Project partners introduction',
            li24: 'Introducing the ECEH solution as a tool opening-up new learning opportunities',
            li25: 'Call for the Action, conclusion',
            p24: 'Online communication was implemented by the webex system.'
          },

          menu: {
            logoutSuccessful: 'Logout successful',
            exitTest: 'Are you sure you want to exit the exam?',
            exitTask: 'Are you sure you want to finish working on the assignments?',
            testOptions: 'Exam options',
            testClose: 'Close exam',
            testStop: 'End exam',
            testSubmit: 'Submit exam',
            quit: 'Quit',
            quitTask: 'Quit assignment',
            home: 'Home',
            tasks: 'Assignments',
            subjects: 'Subjects',
            listOfTasks: 'Assignments',
            addTask: 'Add assignment',
            listOfSubjects: 'Subjects',
            createSubject: 'Create subject',
            accountSettings: 'Account settings',
            logOut: 'Log out',
            privacyPolicy: 'Privacy policy',
            flagPath: 'flag_eng.png',
            changeLanguage: 'English'
          },

          delete: {
            areYouSure: 'Are you sure you want to delete the item?',
            itemDelete: 'This item will be removed from the list!'
          },

          error: {
            e403: 'Sorry, you cannot access this page',
            e404: 'Sorry, the requested page does not exist',
            e500: 'Sorry, there was an error on the server',
            backHome: 'Back home'
          },

          login: {
            login: 'Log in',
            loginMe: 'Log in now',
            personalInformation: 'Personal information',
            registration: 'Request access',
            registerMe: 'Send the request',
            registerMe_mail: 'Sign in',
            textField: 'Reason for access request',
            textFieldPlaceholder: 'Write your reason here',
            loginSuccessful: 'You have been successfully logged in',
            loginUnsuccessful: 'Login failed',
            passwordResetInstruction: 'A message has been sent to your email, follow the instructions in it',
            passwordResetUnsuccessful: 'There was a problem somewhere, the action could not be performed',
            passwordResetSuccessful: 'Password has been changed successfully',
            passwordsNonidentical: 'Passwords do not match',
            emailNonexisting: 'The entered email address does not exist',
            tokenValid: 'The token is valid, please continue to reset your password',
            tokenNonvalid: 'The token could not be verified, please resend the password change request',
            emailWrongFormat: 'The email address is not in the correct format',
            emailEnter: 'Please enter your email address',
            emailExists: 'The entered email address already exists',
            passwordEnter: 'Please enter a password',
            password: 'Password',
            passwordForgot: 'Forgot your password?',
            passwordRecovery: 'Password recovery',
            passwordRecoveryInstruction: 'To send further instructions for password recovery, please enter your email address used during registration.',
            passwordEnterNew: 'To reset your password, enter a new password for your account.',
            passwordRequirements: 'The password must contain at least 6 characters',
            passwordNew: 'New password',
            passwordReEnter: 'Please re-enter your password for verification!',
            passwordRepeatNew: 'Repeat the new password',
            passwordRepeat: 'Repeat the password',
            passwordChange: 'Change password',
            backToLogin: 'Back to login',
            registration_header: 'Registration',
            registrationSuccessful: 'You have been successfully registered',
            registrationRequestSuccessful: 'Request has been successfully sent',
            registrationError: 'The account could not be registered, please try again',
            policyAgreement: 'To continue, you must agree to the terms of the Privacy Policy',
            policyAgreed: 'I have read and agree to the terms of the Personal Data Protection',
            name: 'First name',
            lastname: 'Last name',
            enterName: 'Please enter your first name',
            enterLastname: 'Please enter your last name',
            organization: 'Organization',
            organizationSelect: 'Please select your organization from the list',
            class: 'Class',
            classEnter: 'Please enter your class',
            classRequirements: 'Max 5 characters',
            moreInfo: 'More info here'
          },

          dashboard: {
            listOfCreatedSubjects: 'List of created subjects',
            listOfRegisteredSubjects: 'List of registered subjects',
            listOfCreatedTasks: 'List of created assignments',
            enterSearchText: 'Enter the search text'
          },

          activities: {
            copySubject: 'Copy subject',
            deleteSubject: 'Delete subject',
            editSubject: 'Edit subject',
            authorUnknown: 'Unknown author',
            public: 'Public',
            publicSubject: 'Public subject',
            privateSubject: 'Private Subject',
            title: 'Title',
            author: 'Author',
            program: 'Field of study',
            programCode: 'Field of study - code',
            notListed: 'Not listed',
            year: 'Year',
            description: 'Description',
            addAll: 'Add all',
            addStudents: 'Add students',
            enterTitle: 'Please enter the name of the lesson',
            enterDescription: 'Please enter a description of the lesson',
            errorPageLoad: 'The content of the page could not be loaded, please try to load the page later',
            errorSubjectLoad: 'The subject could not be loaded, please try to load the page later',
            subjectName: 'Subject name',
            subjectField: 'Field of study',
            subjectFieldCode: 'Code',
            subjectYear: 'Year',
            subjectEnterContent: 'Please complete the content of the subject',
            subjectCreate: 'Create subject',
            subjectCreateSuccess: 'Subject was created successfully',
            subjectCreateUnsuccess: 'Subject could not be created',
            subjectCopySuccess: 'The copy of subject successfully created',
            subjectCopyUnsuccess: 'The copy of subject could not be created',
            subjectCopyQuestion: 'Are you sure you want to make a copy of this subject?',
            subjectCopyWarning: 'Making a copy will make the subject one of your own',
            subjectCopy: 'Create copy',
            subjectDeleteQuestion: 'Are you sure you want to delete the subject?',
            subjectDeleteWarning: 'If you choose to delete this subject, it will be permanently deleted.',
            subjectDeletedSuccessful: 'Subject was successfully removed.',
            subjectDeleteUnsuccessful: 'Subject could not be romoved removed.',
            subjectRefreshError: 'Failed to update subject, please try again later.',
            subjectEdit: 'Edit subject',
            subjectSave: 'Save changes',
            subjectEnterName: 'Please enter the name of the subject',
            subjectDisplayToOthers: 'Allow other users to view this subject',
            subjectRemoveStudent: 'Remove student from the subject',
            subjectDisplayInfo: 'Allow other users to view this subject',
            subjectUpdateSuccess: 'The subject has been updated successfully',
            subjectUpdateUnsuccess: 'The subject could not be updated',
            subjectDetail: 'Subject detail',
            subjectAddStudents: 'Adding students to a subject',
            subjectRemoveStudents: 'Remove all students from the subject',
            subjectsDisplayMy: 'Display only my subjects',
            subjectsList: 'List of subjects',
            subjectsSearch: 'Enter the search text',
            examActivationError: 'The test could not be activated, please try to load the page later',
            active: 'Active',
            notActive: 'Inactive',
            startInfoTest: 'Start informative testing',
            startTaskWork: 'Start working on assignments',
            teacherPanel: 'Teacher panel',
            tasksAddDelete: 'Add/delete assignments',
            tasksAddSuccess: 'Assignments have been successfully added to the lesson',
            tasksLoadUnsuccess: 'The assignment list of the selected subject could not be updated',
            tasksAddFromLesson: 'Adding assignments belonging to the lesson',
            tasksDisplayMy: 'Display only my assignments',
            taskEditWarning: 'When changing assignments in a lesson, the current status of the teacher\'s panel is reset.',
            lessonEdit: 'Edit lesson',
            lessonEditInfo: 'Edit lesson informations',
            lessonDelete: 'Delete lesson',
            lessonDeleteSuccess: 'The lesson was successfully removed from the subject',
            lessonDeleteUnsuccess: 'Lessons could not be removed from the subject',
            lessonDeleteQuestion: 'Are you sure you want to delete the lesson?',
            lessonDeleteWarning: 'If you decide to delete a lesson, it will only be deleted from this subject.',
            lessonUpdateSuccess: 'The lesson has been successfully updated',
            lessonUpdateUnsuccess: 'The lesson could not be updated',
            lessonAutorshipError: 'You cannot edit a lesson because you are not its author',
            lessonAddedToSubject: 'The created lesson was successfully added to the subject',
            lessonCreateUnsuccess: 'A new lesson could not be created',
            lessonsLoadUnsuccess: 'Lessons could not be loaded, please try to load the page later',
            lessonAdd: 'Add a lesson',
            lessonAddNew: 'Add a new lesson',
            lessonAddToSubject: 'Adding a lesson to a subject',
            lessonsSelect: 'Select the desired lessons',
            listOfLessons: 'List of lessons',
            tests: 'Tests',
            testAdd: 'Add test',
            testStart: 'Start test',
            testNotExist: 'There are no tests',
            testShowInfo: 'Tests will only be shown to students of this subject',
            showResults: 'Show results',
            showOverallResults: 'Show overall results',
            tasks: 'Assignemnts',
            questions: 'Questions',
            sortStudents: 'Sort the students',
            studentRemoveQuestion: 'Are you sure you want to remove the student from the subject?',
            studentRemoveWarning: 'If you decide to remove a student, he will only be removed from the current subject.',
            studentsRemoveQuestion: 'Are you sure you want to remove all students of the subject?',
            studentsRemoveWarning: 'If you decide to remove students, they will only be removed from the current subject.',
            studentsListLoadUnsuccess: 'The list of students could not be loaded, please try to load the page later',
            studentsListUpdateSuccess: 'The list of subject students has been successfully updated',
            studentsListUpdateUnsuccess: 'Failed to update the list of subject students',
            studentsLoadUnsuccess: 'Failed to load students',
            studentsList: 'List of subject students',
            authorsList: 'List of authors',
            authorsSelect: 'Select the desired authors',
            organizations: 'Organizations',
            selectOrganizationType: 'Select the desired type of organization',
            filter: 'Filter',
            students: 'Študents:',
            selectStudents: 'Select the desired students',
            validate: 'Validate',
            assignmentList: 'List of assignments:',
            copyTask: 'Copy task'
          },

          events: {
            correct: 'Correct answer',
            hints: 'Hints',
            title: 'Title',
            author: 'Author',
            type: 'Type:',
            answers: 'Answers',
            numberOfOptions: 'The number of options must be at least 2',
            eventDescription: 'Please enter an assignment description',
            eventCreateSuccess: 'Assignment was created',
            eventCreateUnsuccess: 'Failed to create assignment',
            eventEnterDuration: 'You must enter the duration of the assignment',
            eventMaxDuration: 'The maximum time per assignment is 5 hours',
            eventMinDuration: 'The minumum time per assignment is 10 seconds',
            eventDurationMinutesExceeded: 'The maximum possible input for minutes is 59',
            optionEnterDescription: 'Please fill in the option description',
            hintEnterNameAndBody: 'Please fill in the text of the hint',
            assignmentAdd: 'Adding an assignment',
            assignmentAddNow: 'Add an assignment',
            assignmentTitle: 'Title',
            assignmentTitleEnter: 'Please fill in the title of assignment',
            assignmentContentEnter: 'Please complete the content of the assignment',
            assignmentType: 'Assignment Type',
            assignmentTypeEnter: 'Please select assignment type',
            assignmentMultichoice: 'Multiple choice question',
            assignmentOpentext: 'Open text assignment',
            assignmentNoanswer: 'No answer',
            assignmenstRecommendedTime: 'Recommended time to solve assignment',
            assignmentOptions: 'Options',
            assignmentCorrectAnswer: 'Correct answer',
            assignmentCorrectAnswerEnter: 'Enter the correct answer',
            assignmentNewOption: 'New option',
            assignmentNewOptionBody: 'Please enter the content of the new option',
            assignmentDelete: 'Delete assignment',
            assignmentDeleteWarning: 'If you decide to delete the assignment, it will be permanently removed.',
            assignmentDeleteQuestion: 'Are you sure you want to delete the assignment?',
            assignmentDeleteSuccess: 'Assignment was successfully deleted.',
            assignmentDeleteUnsuccess: 'Failed to delete the assignment.',
            assignmentSearch: 'Enter the search text',
            assignmentPageError: 'Failed to load the assignment. Please try to reload the page later',
            assignmentRecommendedTime: 'Recommended time to solve the assignment:',
            assignmentList: 'List of assignments',
            displayOnlyMy: 'Display only my assignments:',
            optionAdd: 'Add option',
            optionAddLong: 'Add new answer option',
            hintTitle: 'Hint title',
            hintBodyEnter: 'Please fill in the hint title',
            hintAdd: 'Add hint',
            imageUploadSuccess: 'Image was successfully uploaded',
            imageUploadUnsuccess: 'Failed to upload the image',
            noActionAvailable: 'No action available',
            authorUnknown: 'Author unknown',
            errorPageLoad: 'Failed to load page content. Please try to reload the page later',
            loading: 'Loading...',
            hintDescription: 'Hint description',
            enterHintDescription: 'Please enter hint description'

          },

          exams: {
            results: 'Results',
            date: 'Date:',
            back: 'Back',
            studentName: 'Name of the student',
            shortPoint: 'p',
            of: 'of',
            submitError: 'Failed to submit the exam. Please try again.',
            pageExamError: 'Failed to load the exam content. Please try to reload the exam later',
            pageError: 'Failed to load page content. Please try to reload the page later',
            examFinished: 'Exam has ended',
            examPointsEarned: 'You earned',
            examPointsTotal: 'points of ',
            examClose: 'Close exam',
            examCloseWarning: 'If you leave the page, the exam will be submitted. Are you sure you want to leave the page?',
            examFinishQuestion: 'Are you sure you want to finish the exam?',
            examSubmit: 'Submit exam',
            prevQuestion: 'Previous question',
            nextQuestion: 'Next question',
            examDidNotTake: 'Did not take the exam!',
            examResults: 'Exam results',
            examResult: 'Exam result',
            examTitle: 'Exam title:',
            resultPercentage: 'Overall result in percentage',
            totalCorrectAnswers: 'Total number of correct answers:',
            totalPointsEarned: 'Total number of points earned:',
            resultOfQuestions: 'Results of questions',
            numberOfQuestion: 'Question number',
            titleOfQuestion: 'Question title',
            successOfQuestion: 'Question success',
            alreadyCompletedError: 'The test has alredy been completed'
          },

          profile: {
            class: 'Class',
            name: 'Name',
            surname: 'Surname',
            email: 'e-mail',
            organization: 'Organization',
            pageError: 'Failed to load page content, please try again later.',
            passwordDoNotMatch: 'Passwords do not match.',
            changeSuccess: 'Changes have been successfully saved.',
            changeUnsuccess: 'Changes could not be saved. Please try again.',
            passwordChangeSuccess: 'Password has been successfully changed.',
            passwordChangeUnsuccess: 'Password could not be changed. Please try again.',
            userInfo: 'User information',
            inviteLink: 'Registration link has been successfully sent',
            changePassword: 'Change password',
            changeOfPassword: 'Change of password',
            changeUserInfo: 'Change user information',
            changeOfUserInfo: 'Change of user information',
            enterOldPassword: 'Please enter your old password!',
            enterNewPassword: 'Please enter a new password!',
            enterNewPasswordRepeart: 'Please enter the new password again for verification!',
            passwordMinRequirements: 'Password must contain at least 6 characters.',
            oldPassword: 'Old Password',
            newPassword: 'New Password',
            repeatNewPassword: 'Repeat New Password'
          },

          tasks: {
            pageError: 'Page content failed to load, please try again later.',
            taskDone: 'Task done',
            taskIsDone: 'Task is done',
            taskDoneAlready: 'Task is already done',
            generalError: 'Something went wrong! Please try again.',
            prevTask: 'Previous task',
            nextTask: 'Next task',
            quit: 'Quit',
            done: 'Done',
            hints: 'Hints',
            congrat: 'Congratulations on completing all tasks',
            taskElaboration: 'Task elaboration',
            taskTitleError: 'Failed to load lesson title'
          },

          panel: {
            inLesson: 'Inside lesson',
            outLesson: 'Out of lesson',
            Offline: 'Offline',
            studentName: 'Student\'s name'

          },

          test: {
            testCreateSuccess: 'Exam successfully created.',
            testCreateUnsuccess: 'Failed to create exam.',
            testPickDateTitle: 'Choose a valid date and a valid exam name.',
            pageError: 'Failed to load page content, please try again later.',
            loadError: 'Error loading page, please try to reload the page.',
            initAnswerError: 'Incorrectly initialized question options!',
            title: 'Title',
            created: 'Created',
            recommendedTime: 'Recommended time to solve the task',
            actions: 'Actions',
            deleteTasks: 'Delete tasks',
            listOfLessons: 'List of lessons',
            listOfTasks: 'List of tasks for the selected lesson',
            addLesson: 'Add a lesson to the exam',
            listOftasksForTest: 'List of tasks for the created exam',
            createTest: 'Create exam',
            displayResults: 'Display results',
            nextQuestion: 'Next question',
            evaluate: 'Evaluate',
            hints: 'Hints',
            questionAlreadeAnswered: 'Question has already been answered correctly',
            results: 'Results',
            closeTest: 'Close exam',
            numberCorrect: 'Number of correctly marked answers: ',
            numberUncorrect: 'Number of incorrectly marked answers: ',
            correctAnswers: 'Correctly answered questions: ',
            successRate: 'Overall success rate: ',
            informativeTesting: 'Informative testing',
            lessonTitleError: 'Failed to load lesson title.'
          }
        }
      }

    }
  })