import { withRouter, RouteComponentProps } from 'react-router-dom'
import React from 'react'

/* Css */
import './Welcome.scss'

/* i18n */
import { WithTranslation, withTranslation } from 'react-i18next'
import Layout from './partials/Layout/Layout'
import Header from './partials/Header/Header'
import Footer from './partials/Footer/Footer'
import SectionRealTimeProgress from './partials/SectionRealTimeProgress/SectionRealTimeProgress'
import SectionProjectOverview from './partials/SectionProjectOverview/SectionProjectOverview'
import SectionGrants from './partials/SectionGrants/SectionGrants'
import SectionTitle from './partials/SectionTitle/SectionTitle'
import SectionBlog from './partials/SectionBlog/SectionBlog'
import SectionTryFree from './partials/SectionTryFree/SectionTryFree'
import SecondaryHeader from './partials/SecondaryHeader/SecondaryHeader'
import SectionFaq from './partials/SectionFaq/SectionFaq'


interface IProps extends RouteComponentProps, WithTranslation {
}

interface IState {
  selectedLanguage: string;
}

class Welcome extends React.Component<IProps, IState> {
  render() {
    return (
      <Layout>
        <Header />
        <SecondaryHeader />

        {/* Title */}
        <SectionTitle />

        {/* All sections */}
        <SectionProjectOverview />
        <SectionBlog />
        <SectionGrants />
        <SectionRealTimeProgress />
        <SectionFaq />
        <SectionTryFree />

        <Footer />
      </Layout>
    )
  }
}

export default withTranslation()(withRouter(Welcome))