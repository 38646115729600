import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

// component to scroll content to top
export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
  }, [pathname]);

  return null;
}
