import { withRouter, RouteComponentProps } from 'react-router-dom'
import React, { Component } from 'react'

/* Css */
import './SectionBlog.scss'

/* i18n */
import { WithTranslation, withTranslation } from 'react-i18next'
import MainCard from '../MainCard/MainCard'
import EcehButton from '../../../../common/EcehButton/EcehButton'

interface IProps extends RouteComponentProps, WithTranslation {
}

interface IState {
  selectedLanguage: string;
}

class SectionBlog extends Component<IProps, IState> {
  render() {
    const { t } = this.props
    return (
      <MainCard dark className="eceh-blog-section">
        {/* Title and description */}
        <div className="eceh-blog-section__title-container">
          <h2>{t('titlePage.blogSection.title')}</h2>
          <p>{t('titlePage.blogSection.description')}</p>
        </div>

        {/* Button */}
        <div className="eceh-blog-section__cta-container">
          <EcehButton href="/blog" target="_blank" label={t('titlePage.blogSection.ctaText')} />
        </div>
      </MainCard>
    )
  }
}

export default withTranslation()(withRouter(SectionBlog))