import React from 'react'
import { LoginOutlined, UserAddOutlined } from '@ant-design/icons'
import { Modal, Tabs, Card } from 'antd'

import LoginTab from './LoginTab/LoginTab'
import RegistrationTab from './RegistrationTab/RegistrationTab'

import './SignInModal.scss'

import { withRouter, RouteComponentProps } from 'react-router-dom'
import { WithTranslation, withTranslation } from 'react-i18next'

const { TabPane } = Tabs

interface IProps extends RouteComponentProps, WithTranslation {
  visibleOnPageLoad?: boolean;
  defaultRegistration?: boolean;
  buttonStyle?: any;
  childRef?: any;

  /* Initial values */
  initialEmail?: string;
  initialPassword?: string;
}

interface IState {
  visible: boolean;
}

class SignInModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.handleOk = this.handleOk.bind(this)
    this.state = { visible: !!this.props.visibleOnPageLoad }
  }

  /*
  * Hacky way for refs, because somehow it doesn't work :)
  * @see https://github.com/reactjs/react.dev/issues/2120
  * */
  componentDidMount() {
    this.props.childRef(this)
  }

  componentWillUnmount() {
    this.props.childRef(undefined)
  }

  openModal = () => this.setState({ visible: true })

  handleOk = () => {
    this.setState({ visible: false })
    this.props.history.push('/dashboard')
  }

  handleCancel = () => {
    this.setState({ visible: false })
    if (this.props?.match?.path === '/password/find/:token') this.props.history.push({ pathname: '/' })
  }

  render() {
    return (
      <Modal
        centered
        destroyOnClose
        closable={true}
        closeIcon={(
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.17004 14.8299L14.83 9.16992" stroke="#73808D" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round" />
            <path d="M14.83 14.8299L9.17004 9.16992" stroke="#73808D" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round" />
            <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#73808D"
                  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        )}
        footer={null}
        visible={this.state.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        className="eceh-sign-in-modal"
      >
        <Card className="eceh-sign-in-modal__card">
          <Tabs defaultActiveKey={this.props.defaultRegistration ? '1' : '2'}>
            {/* Registration tab */}
            <TabPane tab={<><UserAddOutlined />{this.props.t('login.registration')}</>} key="1">
              <RegistrationTab
                handleOk={this.handleOk}
                initialEmail={this.props.initialEmail}
                initialPassword={this.props.initialPassword}
              />
            </TabPane>

            {/* Login tab */}
            <TabPane tab={<><LoginOutlined />{this.props.t('login.login')}</>} key="2">
              <LoginTab
                handleOk={this.handleOk}
                initialEmail={this.props.initialEmail}
                initialPassword={this.props.initialPassword}
              />
            </TabPane>
          </Tabs>
        </Card>
      </Modal>)
  }
}


export default withTranslation()(withRouter(SignInModal))
