import React, { ReactText } from 'react'
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Table, Tooltip } from 'antd'
import 'antd/dist/antd.css'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import './ActivitiesTable.css'
import api from '../../../../utils/ApiAxios'

//other
import { AuthContext } from '../../../../context/Auth'
import { Activity, User } from '../../../../models'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import axios, { CancelTokenSource } from 'axios'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'

interface IProps extends RouteComponentProps, WithTranslation {
  activities: Activity[];
  onDelete?: (id?: number) => void;
  onClone?: (id?: number) => void;
  showActions?: boolean;
}

interface IState {
  loading: boolean;
  searchedColumn: keyof Activity;
  searchText: ReactText;
  users: User[];
}

let getAllStudentsCancelToken: CancelTokenSource | undefined


class ActivitiesTable extends React.Component<IProps, IState> {
  static contextType = AuthContext
  searchInput = null;

  constructor(props: IProps) {
    super(props)
    this.renderButtons = this.renderButtons.bind(this)
    this.searchInput = null
    this.state = {
      loading: true,
      searchText: '',
      searchedColumn: 'title',
      users: []
    }
  }

  renderButtons = (activity: Activity) => {
    const { onDelete, onClone } = this.props
    const cloneButton = onClone && (
      <Tooltip title={this.props.t('activities.copySubject')}>
        <Button
          onClick={e => {
            e.stopPropagation()
            onClone && onClone(activity.id)
          }}
          icon={<CopyOutlined />}
          type="primary"
        />
      </Tooltip>
    )

    /* User is author of activity */
    if (onDelete && this.context.user.id === activity.author_id) {
      return (
        <>
          {cloneButton}
          <Tooltip title={this.props.t('activities.deleteSubject')}>
            <Button
              onClick={e => {
                e.stopPropagation()
                onDelete && onDelete(activity.id)
              }}
              icon={<DeleteOutlined />}
              danger
            />
          </Tooltip>
        </>
      )

      /* If activity is public - show copy btn */
    } else if (this.context.user.id === activity.author_id || activity.public) {
      return cloneButton
    }
  }

  renderAuthorName = (author_id: number) => {
    const result = this.state.users.find((user: User) => user.id === author_id)

    return <span>{result ? result.name : this.props.t('activities.authorUnknown')}</span>
  }

  componentDidMount() {
    /* Cancel previous request if exists */
    if (getAllStudentsCancelToken) getAllStudentsCancelToken.cancel('Request canceled due to new request')

    /* Create new cancel token */
    getAllStudentsCancelToken = axios.CancelToken.source()

    api.users.getAllStudents(getAllStudentsCancelToken).then(response => {
      this.setState({
        users: response.data,
        loading: false
      })
    })
  }

  componentWillUnmount() {
    if (getAllStudentsCancelToken) {
      getAllStudentsCancelToken.cancel('Request canceled due component unmounting')
    }
  }

  getColumnSearchProps = (dataIndex: keyof Activity) => ({
    onFilter: (value: string | number | boolean, record: Activity) => {
      if (record && record[dataIndex]) {
        return record[dataIndex]!.toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase())
      } else {
        return false
      }
    }
  })

  handleReset = (clearFilters: FilterDropdownProps['clearFilters']) => {
    clearFilters && clearFilters()
    this.setState({ searchText: '' })
  }

  render() {
    return (
      <Table<Activity>
        rowKey={(record: Activity) => String(record.id)}
        loading={this.state.loading}
        dataSource={this.props.activities}
        className={'activities-table__table'}
        rowClassName={(activity) => {
          /* Do not disable row is user is author, or activity is public or user is student of activity */
          if (this.context.user.id === activity.author_id || activity.public || activity.isStudent) {
            return ''
          } else {
            return 'disabled' 
          }
        }}
        onRow={(activity: Activity) => {
          /* Do not disable row if user is author, or activity is public or user is student of activity */
          if (this.context.user.id === activity.author_id || activity.public || activity.isStudent) {
            return { onClick: () => this.props.history.push(`/activity/detail/${activity.id}`) }
          } else {
            return {}
          }
        }}
        scroll={{ x: true }}
      >
        <Table.Column
          title={this.props.t('activities.public')}
          dataIndex="public"
                key="public"
          render={(publicActivity: boolean) =>
            <Tooltip title={publicActivity ? this.props.t('activities.publicSubject') : this.props.t('activities.privateSubject')}>
              {
                publicActivity
                  ? <img className="public-img" src="/unlocked.svg" alt={this.props.t('activities.publicSubject')} />
                  : <img className="public-img" src="/locked.svg" alt={this.props.t('activities.privateSubject')} />
              }
            </Tooltip>
          }
        />
        <Table.Column
          title={this.props.t('activities.title')}
          dataIndex="title"
          key="title"
          {...this.getColumnSearchProps('title')}
        />
        <Table.Column
          title={this.props.t('activities.author')}
          dataIndex="author_id"
          key="author_id"
          render={(author_id: number) => this.renderAuthorName(author_id)}
        />
        <Table.Column
          title={this.props.t('activities.program')}
          dataIndex="program"
          key="program"
          render={(program) => <span>{program ? program : this.props.t('activities.notListed')}</span>}
        />
        <Table.Column
          title={this.props.t('activities.programCode')}
          dataIndex="code"
          key="code"
          render={(code) =>  <span>{code ? code : this.props.t('activities.notListed')}</span>}
        />
        <Table.Column
          title={this.props.t('activities.year')}
          dataIndex="year"
          key="year"
          render={(year) =>  <span>{year ? year : this.props.t('activities.notListed')}</span>}
        />
        {this.props.showActions && (
          <Table.Column
            title={this.props.t('basic.actions')}
            key="actions"
            render={(text: ReactText, activity: Activity) =>
              this.renderButtons(activity)
            }
          />
        )}
      </Table>
    )
  }
}

export default withTranslation()(withRouter(ActivitiesTable))
