import React from 'react'
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Switch, Modal, Input } from 'antd'
import { withRouter, Redirect, RouteComponentProps } from 'react-router-dom'
import ActivitiesTable from './ActivitiesTable/ActivitiesTable'
import './Activities.css'
import api from '../../../utils/ApiAxios'
import notification from '../../../utils/notificationService'
import { AuthContext } from '../../../context/Auth'
import Loading from '../../common/Loading/Loading'
import Activity from '../../../models/activity'
import axios, { CancelTokenSource } from 'axios'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'

const { confirm } = Modal

interface IProps extends RouteComponentProps, WithTranslation {

}

interface IState {
  showMyActivities: boolean;
  activities: Activity[];
  loading: boolean;
  userId: BigInteger | string;
}

let getFilteredActivitiesCancelToken: CancelTokenSource | undefined


class Activities extends React.Component<IProps, IState> {
  // eslint-disable-next-line
  private searched_text = new String

  constructor(props: IProps) {
    super(props)
    this.state = {
      showMyActivities: false,
      activities: [],
      loading: true,
      userId: ''
    }
    this.handleActivityClone = this.handleActivityClone.bind(this)
  }

  static contextType = AuthContext

  componentDidMount() {
    this.getFilteredActivities()
  }

  componentWillUnmount() {
    if (getFilteredActivitiesCancelToken) {
      getFilteredActivitiesCancelToken.cancel('Request canceled due component unmounting')
    }
  }

  getFilteredActivities = () => {
    /* Cancel previous request if exists */
    if (getFilteredActivitiesCancelToken) getFilteredActivitiesCancelToken.cancel('Request canceled due to new request')

    /* Create new cancel token */
    getFilteredActivitiesCancelToken = axios.CancelToken.source()


    api.activities
      .getFilteredActivities({ text: this.searched_text, user: this.state.userId }, getFilteredActivitiesCancelToken)
      .then(response => {
        this.setState({
          activities: response.data,
          loading: false
        })
      })
      .catch(error => notification.error(this.props.t('activities.errorPageLoad'), error))
  }

  handleMyActivitiesSwitch = (checked: boolean) => {
    let userId = ''
    if (checked) {
      userId = this.context.user.id
    }

    this.setState({
      showMyActivities: checked,
      userId: userId
    }, () => {
      this.getFilteredActivities()
    })
  }

  cloneActivity = (id: number) => {
    this.setState({
      loading: true
    })

    api.activities
      .cloneActivity(id)
      .then(response => {
        const { activities } = this.state
        activities.push(response.data.activity)
        this.setState({
          loading: false,
          activities
        })
        notification.success(this.props.t('activities.subjectCopySuccess'))
      })
      .catch(error => {
        this.setState({
          loading: false
        })
        notification.error(this.props.t('activities.subjectCopyUnsuccess'), error)
      })
  }

  handleActivityClone = (activityId?: number) => {
    confirm({
      title: this.props.t('activities.subjectCopyQuestion'),
      content: this.props.t('activities.subjectCopyWarning'),
      okText: this.props.t('activities.subjectCopy'),
      cancelText: this.props.t('basic.cancel'),
      onOk: () => {
        if (activityId) {
          this.cloneActivity(activityId)
        } else {
          notification.error('', '[ERROR] CLONE ACTIVITY - ActivityId is missing', false)
        }
      }
    })
  }

  handleDeleteActivity = (activityId?: number) => {
    confirm({
      title: this.props.t('activities.subjectDeleteQuestion'),
      content: this.props.t('activities.subjectDeleteWarning'),
      cancelText: this.props.t('basic.cancel'),
      okText: this.props.t('basic.delete'),
      onOk: () => {
        if (activityId) {
          api.activities.deleteActivity(activityId).then(resp => {
            if (resp) {
              notification.success(this.props.t('activities.subjectDeletedSuccessful'))
              this.setState({
                ...this.state,
                activities: [
                  ...this.state.activities.filter(
                    activity => activity.id !== activityId
                  )
                ]
              })
            } else {
              notification.error(this.props.t('activities.subjectDeletedUnsuccessful'))
            }
          })
        } else {
          notification.error('', '[ERROR] DELETE ACTIVITY - ActivityId is missing', false)
        }
      }
    })
  }

  render() {
    const activityContent = this.context.isLoggedIn ? (
      <ActivitiesTable
        showActions
        activities={this.state.activities}
        onClone={this.handleActivityClone}
        onDelete={this.handleDeleteActivity} />
    ) : (
      <Redirect to="/403" />
    )

    return (
      <React.Fragment>
        {this.state.loading ? (
          <Loading />
        ) : (
          <div className="style">
            <h2>{this.props.t('activities.subjectsList')}</h2>
            <div style={{ marginTop: 20, marginBottom: 40 }}>
              {this.props.t('activities.subjectsDisplayMy')}
              <Switch
                checked={this.state.showMyActivities}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={this.handleMyActivitiesSwitch}
                style={{ marginLeft: 20 }}
              />
            </div>
            <div>
              <Input.Search
                name="search_bar"
                placeholder={this.props.t('activities.subjectsSearch')}
                onChange={(e) => {
                  this.searched_text = e.target.value
                }}
                onSearch={(e) => {
                  this.searched_text = e
                  this.getFilteredActivities()
                }}

              />
            </div>
            <div>{activityContent}</div>
            <div>
              <Button type="default" icon={<PlusOutlined />} onClick={() => this.props.history.push('/activity/new')}>
                {this.props.t('activities.subjectCreate')}
              </Button>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default withTranslation()(withRouter(Activities))
