import React from 'react'
import { LoginOutlined, UserAddOutlined } from '@ant-design/icons'
import { Modal, Tabs, Card, Button } from 'antd'

//common
import LoginForm from './LoginAndRegistrationModalLoginForm/LoginForm'
import RegistrationForm from './LoginAndRegistrationModalRgistrationForm/RegistrationForm'

//other
import {withRouter, RouteComponentProps} from 'react-router-dom'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'

const {TabPane} = Tabs

interface IProps extends RouteComponentProps, WithTranslation {
  visibleOnPageLoad?: boolean;
  registration?: boolean;
  buttonStyle?: any;
}

interface IState {
  visible: boolean;
  icontype: string;
}

// modal with login and registration panel, component is rendered as button
class Login extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.handleOk = this.handleOk.bind(this)
    this.state = {
      visible: this.props.visibleOnPageLoad ? this.props.visibleOnPageLoad : false,
      icontype: this.props.registration ? 'user-add' : 'login',
    }
  }

  // function to show modal
  showModal = () => {
    this.setState({
      visible: true,
    })
  };

  // function to handle success registration or login
  handleOk = () => {
    this.setState({
      visible: false,
    })
    this.props.history.push('/dashboard')
  };

  // function to hide modal
  handleCancel = () => {
    this.setState({
      visible: false,
    })
    if (this.props.match && this.props.match.path && this.props.match.path === '/password/find/:token') {
      this.props.history.push({
        pathname: '/',
      })
    }
  };

  render() {
    const registrationActive = this.props.registration

    return <>
      <Button
        type={this.props.buttonStyle ? this.props.buttonStyle.type : 'link'}
        onClick={this.showModal}
        shape={this.props.buttonStyle ? this.props.buttonStyle.shape : 'circle'}
        style={{
          backgroundColor: this.props.buttonStyle ? this.props.buttonStyle.color : '',
          borderColor: this.props.buttonStyle ? this.props.buttonStyle.color : '',
        }}
        size={this.props.buttonStyle ? this.props.buttonStyle.size : 'default'}
      >
        {this.props.registration ? <UserAddOutlined/> : <LoginOutlined/>}
        {this.props.buttonStyle ? this.props.buttonStyle.textToShow : this.props.registration ? this.props.t('login.registration') : this.props.t('login.login')}
      </Button>
      <Modal
        bodyStyle={{padding: '0'}}
        width={400}
        centered
        destroyOnClose
        closable={false}
        footer={null}
        visible={this.state.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <Card style={{background: '#f0f2f5', border: '0'}}>
          <Tabs style={{paddingTop: 30, paddingBottom: 30}} defaultActiveKey={registrationActive ? '1' : '2'}>
            <TabPane
              tab={
                <span>
                  <UserAddOutlined />
                  {this.props.t('login.registration')}
                </span>
              }
              key="1"
            >
              <RegistrationForm handleOk={this.handleOk}/>
            </TabPane>
            <TabPane
              tab={
                <span>
                  <LoginOutlined />
                  {this.props.t('login.login')}
                </span>
              }
              key="2"
            >
              <LoginForm handleOk={this.handleOk}/>
            </TabPane>
          </Tabs>
        </Card>
      </Modal>
    </>
  }
}


export default withTranslation()(withRouter(Login))
