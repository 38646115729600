import React from 'react'
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteOutlined
} from '@ant-design/icons'
import { Button, Row, List, Col, Collapse, Modal, Typography } from 'antd'
import Loading from '../../common/Loading/Loading'
import api from '../../../utils/ApiAxios'
import { withRouter, RouteComponentProps } from 'react-router'
import notification from '../../../utils/notificationService'
import './EventsDetail.css'
import { AuthContext } from '../../../context/Auth'
import { Event, Option, Help } from '../../../models'
//@ts-ignore
import { InlineMath, BlockMath } from 'react-katex'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'

const { Title } = Typography
const { Panel } = Collapse
const { confirm } = Modal

interface IProps extends RouteComponentProps<RouteParams>, WithTranslation {

}

interface RouteParams {
  id: string
}

interface IState {
  owner: boolean
  event: Event | null
  answers: Option[]
  hints: Help[]
  loading: boolean
  type: { name: string }
}

class EventsDetail extends React.Component<IProps, IState> {
  static contextType = AuthContext

  constructor(props: IProps) {
    super(props)
    this.state = {
      owner: false,
      event: null,
      answers: [],
      hints: [],
      loading: true,
      type: { name: this.props.t('events.loading') }
    }
    this.showConfirm = this.showConfirm.bind(this)
  }

  showConfirm(id?: number, header?: string) {
    if (id) {
      confirm({
        title: this.props.t('events.assignmentDeleteQuestion'),
        content: this.props.t('events.assignmentDeleteWarning'),
        okText: this.props.t('basic.delete'),
        cancelText: this.props.t('events.cancel'),
        onOk: () => {
          api.options.deleteAllOptions(id).then(() =>
            api.events
              .deleteEvent(id)
              .then(() => {
                notification.success(this.props.t('events.assignmentDeleteSuccess'))
                this.props.history.push('/events')
              })
              .catch(error => {
                notification.error(this.props.t('events.assignmentDeleteUnsuccess'), error)
              })
          )
        }
      })
    } else {
      notification.error(
        this.props.t('events.assignmentPageError'),
        `[ERROR] SHOW CONFIRM - EventId ${this.props.match.params.id} not inicialized`
      )
    }
  }

  componentDidMount() {
    const user = this.context.user
    const id = parseInt(this.props.match.params.id)

    Promise.all([
      api.events.getEvent(id),
      api.events.getEventOptions(id),
      api.events.getEventHelp(id),
      api.events.getEventTypes(id)
    ])
      .then(response => {
        let owner = false
        if (response[0].data.author_id === user.id) {
          owner = true
        }
        this.setState({
          event: response[0].data,
          owner,
          answers: response[1].data,
          loading: false,
          hints: response[2].data,
          type: response[3].data,
        })
      })
      .catch(error =>
        notification.error(
          this.props.t('events.errorPageLoad'),
          error
        )
      )
  }

  renderTextWithMath(message: string) {

    message = message.replaceAll('<p>', '').replaceAll('</p>', '<br>')



    let indexInline, indexBlock
    let iflag = false

    const elements = []

    while(true) {
      indexInline = message.search('<script type="math/tex">')
      indexBlock = message.search('<script type="math/tex; mode=display">')

      if (indexInline !== -1 && indexBlock !== -1) {
        iflag = (indexInline < indexBlock)
      }
      else if (indexInline !== -1) {
        iflag = true
      }
      else if (indexBlock !== -1) {
        iflag = false
      }
      else {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message}} />)
        break
      }

      const endIndex = message.search('</script>')

      // eslint-disable-next-line
      if(iflag == true) {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message.substring(0, indexInline)}} />)
        elements.push(<InlineMath>{message.substring(indexInline+24, endIndex)}</InlineMath>)
      }
      else {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message.substring(0, indexBlock)}} />)
        elements.push(<BlockMath>{message.substring(indexBlock+38, endIndex)}</BlockMath>)
      }
      message = message.substring(endIndex+9)
    }

    return elements
  }

  renderListOption(item: Option) {
    return (
      <Row style={{ width: '100%' }}>
        <Col span={22}>
          <b>
            <p>
              {this.renderTextWithMath(item.answer_data)}
            </p>
          </b>
        </Col>
        <Col span={2} style={{ textAlign: 'right' }}>
          {item.correct_answer ? (
            <CheckCircleFilled style={{ color: 'green' }} />
          ) : (
            <CloseCircleFilled style={{ color: 'red' }} />
          )}
        </Col>
      </Row>
    )
  }

  renderListHelp(item: Help) {
    return (
      <Row style={{ width: '100%' }}>
        <Col span={22}>
          <b>
            <p>
              {this.renderTextWithMath(item.url)}
            </p>
          </b>
        </Col>
      </Row>
    )
  }

  render() {
    const { event } = this.state
    return (
      <>
        {this.state.loading || event === null ? (
          <Loading />
        ) : (
          <div className="page-margin">
            <Row>
              <Col xs={21} md={15}>
                <Title level={2}>{event.header}</Title>
              </Col>
              <Col xs={3} md={9} className="right">
                {this.state.owner && (
                  <Button
                    className="display-small-screen"
                    onClick={() => {
                      this.showConfirm(event.id)
                    }}
                    shape="circle"
                    icon={<DeleteOutlined />}
                    danger
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={15}>
                <p>
                  {this.renderTextWithMath(event.message)}
                </p>
              </Col>
              <Col xs={24} md={9}>
                <p className="times-list-item">
                  <b>{this.props.t('events.assignmentRecommendedTime')}</b>{' '}
                  {Math.floor(event.time_to_handle/3600).toString().padStart(2,'0')}:{Math.floor((event.time_to_handle/60)%60).toString().padStart(2,'0')}:{Math.floor(event.time_to_handle%60).toString().padStart(2,'0')}
                </p>
                <p className="times-list-item">
                  <b>{this.props.t('events.type')}</b> {this.state.type.name}
                </p>
              </Col>
            </Row>
            <Collapse
              className="border-bottom-collapse-header"
              ghost
              defaultActiveKey="answers"
            >
              {this.state.answers.length && (
                <Panel
                  header={<Title level={4}>{this.props.t('events.answers')}</Title>}
                  key="answers"
                  disabled
                >
                  <List
                    bordered={false}
                    dataSource={this.state.answers}
                    style={{ marginTop: '10px' }}
                    renderItem={item => (
                      <List.Item>{this.renderListOption(item)}</List.Item>
                    )}
                  />
                </Panel>
              )}

              <Panel header={<Title level={4}>{this.props.t('events.hints')}</Title>} key="hints">
                <List
                  dataSource={this.state.hints}
                  style={{ marginTop: '10px' }}
                  renderItem={item => (
                    <List.Item>
                      {this.renderListHelp(item)}
                    </List.Item>
                  )}
                />
              </Panel>
            </Collapse>
          </div>
        )}
      </>
    )
  }
}

export default withTranslation()(withRouter(EventsDetail))
