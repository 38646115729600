import React from 'react'
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom'

/* Boundaries */
import ErrorBoundary from './ErrorBoundary'
import LoggedInBoundary from './LoggedInBoundary'

/* Views */
import Welcome from './components/views/Welcome/Welcome'
import Registration from './components/views/Registration/Registration'
import Profile from './components/views/Profile/Profile'
import Dashboard from './components/views/Dashboard/Dashboard'
import PrivatePolicy from './components/views/PrivatePolicy/PrivatePolicy'
import MathTex from './components/views/MathTex/MathTex'
import ExamContainer from './components/views/ExamContainer/ExamContainer'
import TestContainer from './components/views/TestContainer/TestContainer'
import TasksContainer from './components/views/TasksContainer/TasksContainer'
import EventCreation from './components/views/EventCreation/EventCreation'
import ActivityCreation from './components/views/ActivityCreation/ActivityCreation'
import Events from './components/views/Events/Events'
import Activities from './components/views/Activities/Activities'
import EventDetail from './components/views/EventsDetail/EventsDetail'
import ActivityDetail from './components/views/ActivitiesDetail/ActivityDetail'
import ErrorPage from './components/common/ErrorPage/ErrorPage'
import TestCreation from './components/views/TestCreation/TestCreation'
import ExamResults from './components/views/ExamResults/ExamResults'
import ExamResultsStudent from './components/views/ExamResults/ExamResultsStudent'
import TeacherPanel from './components/views/TeacherPanel/TeacherPanel'
import Invite from './components/views/Invite/Invite'

import HeaderAndMenu from './components/common/HeaderAndMenu/HeaderAndMenu'
import ScrollToTop from './components/common/ScrollToTop/ScrollToTop'

import api from './utils/ApiAxios'
import { AuthContext } from './context/Auth'
import Contact from './components/views/Contact/Contact'

let timer


class App extends React.Component {
  constructor(props) {
    const state = localStorage.appState
    super(props)

    this.reportStateToServer = this.reportStateToServer.bind(this)

    this.toggleMenu = () => {
      this.setState(state => ({ menuClosedState: !state.menuClosedState }))
    }

    this.handleMenuClick = e => {
      this.setState(() => ({ currentPage: e.key }))
    }

    this.setCurrentUserState = ({ unitId, activityId }) => {
      this.setState(() => ({
        unitId,
        activityId,
      }))
    }

    const defaultState = {
      toggleMenu: this.toggleMenu,
      handleMenuClick: this.handleMenuClick,
      setCurrentUserState: this.setCurrentUserState,
      currentPage: props.location.pathname || '/',
      menuClosedState: false,
      unitId: 0,
      activityId: 0,
    }

    if (state) {
      const AppState = JSON.parse(state)
      if (AppState.isLoggedIn) {
        api.global.setHeader('Authorization', `Bearer ${AppState.user.token}`)
      } else {
        api.global.removeHeader('Authorization')
      }
      this.state = {
        ...defaultState,
        menuClosedState: false,
        isLoggedIn: AppState.isLoggedIn,
        user: AppState.user,
      }
    } else {
      this.state = { ...defaultState, isLoggedIn: false, user: {} }
    }
  }

  reloadAuthStateFromLocalStorageAndSetApiAuthorizationToken = () => {
    const state = localStorage.appState
    if (state) {
      const AppState = JSON.parse(state)

      /* Set Api Authorization Token. !Note: Keep before setting state. */
      if (AppState.isLoggedIn) {
        api.global.setHeader('Authorization', `Bearer ${AppState.user.token}`)
      } else {
        api.global.removeHeader('Authorization')
      }

      /* Update state according to local storage */
      this.setState({ isLoggedIn: AppState.isLoggedIn, user: AppState.user })
    }
  }

  reportStateToServer() {
    if (this.state.isLoggedIn) {
      api.auth.update({
        unit_id: this.state.unitId,
        activity_id: this.state.activityId,
      })
    }
  }

  componentDidMount() {
    /* Inform about user state every 5 seconds */
    timer = setInterval(() => this.reportStateToServer(), 5_000)
  }

  componentWillUnmount() {
    clearTimeout(timer)
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          isLoggedIn: this.state.isLoggedIn,
          user: this.state.user,
          menuClosedState: this.state.menuClosedState,
          toggleMenu: this.state.toggleMenu,
          reloadAuthStateFromLocalStorageAndSetApiAuthorizationToken: this.reloadAuthStateFromLocalStorageAndSetApiAuthorizationToken,
          handleMenuClick: this.state.handleMenuClick,
          currentPage: this.state.currentPage,
          setCurrentUserState: this.state.setCurrentUserState,
        }}
      >
        <ErrorBoundary loggedIn={this.state.isLoggedIn}>
          <Router>
            <ScrollToTop />
            <Switch>
              {/* Landing Page */}
              <Route exact path="/">
                <Welcome />
              </Route>

              {/* Landing Page (password reset) */}
              <Route exact path="/password/find/:token">
                <Welcome />
              </Route>

              {/* Contact */}
              <Route exact path="/contact">
                <Contact />
              </Route>

              <Route exact path="/registration">
                <Registration />
              </Route>

              {/* Default error pages */}
              <Route exact path="/403">
                <ErrorPage errorCode={403} />
              </Route>
              <Route exact path="/404">
                <ErrorPage errorCode={404} />
              </Route>
              <Route exact path="/500">
                <ErrorPage errorCode={500} />
              </Route>

              {/* Privacy policy */}
              <Route exact path="/privatepolicy">
                <PrivatePolicy />
              </Route>

              {/* Math.Tex info */}
              <Route exact path="/mathtex">
                <MathTex />
              </Route>

              {/* Team 2021/2022 redirect */}
              <Route
                path="/aboutus"
                component={() => {
                  window.location.href = 'https://labss2.fiit.stuba.sk/TeamProject/2021/team04/'
                  return null
                }}
              />

              {/* Auth routes */}
              <Route exact path="/profile">
                <LoggedInBoundary isLoggedIn={this.state.isLoggedIn}>
                  <HeaderAndMenu content={<Profile />} />
                </LoggedInBoundary>
              </Route>
              <Route exact path="/dashboard">
                <LoggedInBoundary isLoggedIn={this.state.isLoggedIn}>
                  <HeaderAndMenu content={<Dashboard />} />
                </LoggedInBoundary>
              </Route>
              <Route exact path="/:activityId/:unitId/exam/:testId">
                <LoggedInBoundary isLoggedIn={this.state.isLoggedIn}>
                  <HeaderAndMenu
                    content={(
                      menuState,
                      props,
                      handleExitTestResults,
                      handleExitTestFromLastQuestion,
                      handleErrorExitTest,
                      handleMeasurableExit,
                    ) => (
                      <ExamContainer
                        {...props}
                        menuState={menuState}
                        handleExitTestResults={handleMeasurableExit}
                        handleExitTestFromLastQuestion={
                          handleExitTestFromLastQuestion
                        }
                        handleErrorExitTest={handleErrorExitTest}
                      />
                    )}
                    isTest
                    isMeasurable
                  />
                </LoggedInBoundary>
              </Route>
              <Route exact path="/:activityId/:unitId/test">
                <LoggedInBoundary isLoggedIn={this.state.isLoggedIn}>
                  <HeaderAndMenu
                    content={(
                      menuState,
                      props,
                      handleExitTestResults,
                      handleExitTestFromLastQuestion,
                    ) => (
                      <TestContainer
                        {...props}
                        menuState={menuState}
                        handleExitTestResults={handleExitTestResults}
                        handleExitTestFromLastQuestion={
                          handleExitTestFromLastQuestion
                        }
                      />
                    )}
                    isTest
                  />
                </LoggedInBoundary>
              </Route>
              <Route exact path="/:activityId/:unitId/tasks">
                <LoggedInBoundary isLoggedIn={this.state.isLoggedIn}>
                  <HeaderAndMenu
                    content={(
                      menuState,
                      props,
                      handleExitTestResults,
                      handleExitTestFromLastQuestion,
                    ) => (
                      <TasksContainer
                        {...props}
                        menuState={menuState}
                        handleExitTestResults={handleExitTestResults}
                        handleExitTestFromLastQuestion={
                          handleExitTestFromLastQuestion
                        }
                      />
                    )}
                    taskWork
                  />
                </LoggedInBoundary>
              </Route>
              <Route exact path="/event/new">
                <LoggedInBoundary isLoggedIn={this.state.isLoggedIn}>
                  <HeaderAndMenu content={<EventCreation />} />
                </LoggedInBoundary>
              </Route>
              <Route exact path="/event/edit/:id">
                <LoggedInBoundary isLoggedIn={this.state.isLoggedIn}>
                  <HeaderAndMenu content={<EventCreation />} />
                </LoggedInBoundary>
              </Route>
              <Route exact path="/activity/new">
                <LoggedInBoundary isLoggedIn={this.state.isLoggedIn}>
                  <HeaderAndMenu content={<ActivityCreation />} />
                </LoggedInBoundary>
              </Route>
              <Route exact path="/activity/edit/:id">
                <LoggedInBoundary isLoggedIn={this.state.isLoggedIn}>
                  <HeaderAndMenu content={<ActivityCreation />} />
                </LoggedInBoundary>
              </Route>
              <Route exact path="/test/new">
                <LoggedInBoundary isLoggedIn={this.state.isLoggedIn}>
                  <HeaderAndMenu content={<TestCreation />} />
                </LoggedInBoundary>
              </Route>
              <Route exact path="/exam/result/:activityId/:examId">
                <LoggedInBoundary isLoggedIn={this.state.isLoggedIn}>
                  <HeaderAndMenu content={<ExamResults />} />
                </LoggedInBoundary>
              </Route>
              <Route exact path="/exam/student/result/:examId">
                <LoggedInBoundary isLoggedIn={this.state.isLoggedIn}>
                  <HeaderAndMenu content={<ExamResultsStudent />} />
                </LoggedInBoundary>
              </Route>
              <Route exact path="/event/detail/:id">
                <LoggedInBoundary isLoggedIn={this.state.isLoggedIn}>
                  <HeaderAndMenu content={<EventDetail />} />
                </LoggedInBoundary>
              </Route>
              <Route exact path="/activity/detail/:id">
                <LoggedInBoundary isLoggedIn={this.state.isLoggedIn}>
                  <HeaderAndMenu content={<ActivityDetail />} />
                </LoggedInBoundary>
              </Route>
              <Route exact path="/events">
                <LoggedInBoundary isLoggedIn={this.state.isLoggedIn}>
                  <HeaderAndMenu content={<Events />} />
                </LoggedInBoundary>
              </Route>
              <Route exact path="/activities">
                <LoggedInBoundary isLoggedIn={this.state.isLoggedIn}>
                  <HeaderAndMenu content={<Activities />} />
                </LoggedInBoundary>
              </Route>
              <Route exact path="/:activityId/:unitId/panel">
                <LoggedInBoundary isLoggedIn={this.state.isLoggedIn}>
                  <HeaderAndMenu content={<TeacherPanel />} />
                </LoggedInBoundary>
              </Route>
              <Route exact path="/invite">
                <LoggedInBoundary isLoggedIn={this.state.isLoggedIn}>
                  <HeaderAndMenu content={<Invite />} />
                </LoggedInBoundary>
              </Route>


              <Route path="*">
                <ErrorPage errorCode={404} />
              </Route>
            </Switch>
          </Router>
        </ErrorBoundary>
      </AuthContext.Provider>
    )
  }
}

export default withRouter(App)
