import React from 'react'
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Modal, Switch, Input } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import EventsTable from './EventsTable/EventsTable'
import api from '../../../utils/ApiAxios'
import notification from '../../../utils/notificationService'
import './Events.css'
import { AuthContext } from '../../../context/Auth'
import Loading from '../../common/Loading/Loading'
import Event from '../../../models/event'
import axios, { CancelTokenSource } from 'axios'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'

const { confirm } = Modal

interface IProps extends RouteComponentProps, WithTranslation {

}

interface IState {
  events: Event[];
  loading: boolean;
  showMyEvents: boolean;
  userId: BigInteger | string;
}

let getFilteredEventsCancelToken: CancelTokenSource | undefined

class Events extends React.Component<IProps, IState> {
  // eslint-disable-next-line
  private searched_text = new String
  
  constructor(props: IProps) {
    super(props)
    this.state = {
      events: [],
      loading: true,
      showMyEvents: true,
      userId: ''
    }
  }

  static contextType = AuthContext

  componentDidMount() {
    this.setState({userId : this.context.user.id}, ()=>this.getFilteredEventsSearch())
  }

  componentWillUnmount() {
    if (getFilteredEventsCancelToken) {
      getFilteredEventsCancelToken.cancel('Request canceled due component unmounting')
    }
  }

  getFilteredEventsSearch = () => {
    /* Cancel previous request if exists */
    if (getFilteredEventsCancelToken) getFilteredEventsCancelToken.cancel('Request canceled due to new request')

    /* Create new cancel token */
    getFilteredEventsCancelToken = axios.CancelToken.source()

    api.events
      .getFilteredEventsSearch({ text: this.searched_text, user: this.state.userId }, getFilteredEventsCancelToken)
      .then(response => {
        this.setState({
          events: response.data,
          loading: false
        })
      })
      .catch(error => notification.error(this.props.t('events.errorPageLoad'), error))
  }

  handleEventDelete = (eventId?: number, eventHeader?: string) => {
    confirm({
      title: this.props.t('events.assignmentDeleteQuestion'),
      content: this.props.t('events.assignmentDeleteWarning'),
      okText: this.props.t('basic.delete'),
      cancelText: this.props.t('basic.cancel'),
      onOk: () => {
        if (eventId) {
          Promise.all([
            api.options.deleteAllOptions(eventId),
            api.events.deleteEvent(eventId)
          ]).then(resp => {
            if (resp) {
              notification.success(
                this.props.t('events.assignmentDeleteSuccess')
              )
              this.setState({
                ...this.state,
                events: [
                  ...this.state.events.filter(event => event.id !== eventId)
                ]
              })
            } else {
              notification.error(
                this.props.t('events.assignmentDeleteUnsuccess')
              )
            }
          })
        } else {
          notification.error('', '[ERROR] HANDLE EVENT DELETE - EventId not inicialized', false)
        }
      }
    })
  }

  handleMyEventsSwitch = (checked: boolean) => {
    let userId = ''
    if (checked) {
      userId = this.context.user.id
    }

    this.setState({
      showMyEvents: checked,
      userId: userId
    }, () => {
      this.getFilteredEventsSearch()
    })
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <Loading />
        ) : (
          <div className="style">
            <h2>{this.props.t('events.assignmentList')}</h2>

            <div style={{ paddingBottom: 20 }}>
              <div style={{ marginBottom: 10 }}>
                {this.props.t('events.displayOnlyMy')}
                <Switch
                  checked={this.state.showMyEvents}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={this.handleMyEventsSwitch}
                  style={{ marginLeft: 20 }}
                />
              </div>
            </div>
            <div>
              <Input.Search
                name="search_bar"
                placeholder={this.props.t('events.assignmentSearch')}
                onChange={(e) => {this.searched_text = e.target.value}}
                onSearch={(e) => {
                  this.searched_text = e
                  this.getFilteredEventsSearch()
                }}
              />
            </div>
            <div>
              <EventsTable
                events={this.state.events}
                onDelete={this.handleEventDelete}
                showActions
              />
            </div>
            <div>
              <Button
                type="default"
                icon={<PlusOutlined />}
                onClick={() => this.props.history.push('/event/new')}
              >
                {this.props.t('events.assignmentAddNow')}
              </Button>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default withTranslation()(withRouter(Events))
