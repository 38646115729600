import React, { RefObject, ReactNode } from 'react'
import { LockOutlined, MailOutlined, RollbackOutlined } from '@ant-design/icons'
import { Input, Button, Row, Col, Form } from 'antd'
import { withRouter } from 'react-router'


//types
import { RouteComponentProps } from 'react-router'
import { FormInstance, Rule } from 'antd/lib/form'
import { Store } from 'antd/lib/form/interface'


//other
import api from '../../../../utils/ApiAxios'
import { AuthContext } from '../../../../context/Auth'
import notification from '../../../../utils/notificationService'
import LocalStorageAppState from '../../../../models/appState'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'

interface IRouteParams {
  token: string;
}

interface IProps extends RouteComponentProps<IRouteParams>, WithTranslation {
  handleOk: () => void;
}

interface IState {
  forgottenPassword: boolean;
  token: string | null;
  email: string | null;
  confirmDirty: boolean;
}


class LoginForm extends React.Component<IProps, IState> {
  static contextType = AuthContext
  private formRef: RefObject<FormInstance> = React.createRef<FormInstance>()

  constructor(props: IProps) {
    super(props)
    this.state = {
      forgottenPassword: false,
      token: null,
      email: null,
      confirmDirty: false
    }
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params.token) {
      const { token } = this.props.match.params
      this.checkToken(token)
    }
  }

  // handle click on login button
  handleLoginSubmit = (values: Store) => {
    api.auth
      .login({ email: values.email, password: values.password })
      .then(response => {
        this.handleSuccessResponse(response)
        // this.context.reloadAuthStateFromLocalStorageAndSetApiAuthorizationToken()
        notification.success(this.props.t('login.loginSuccessful'))
        this.props.handleOk()
      })
      .catch(error => {
        notification.error(this.props.t('login.loginUnsuccessful'), error)
      })
  }

  // handle succes login, saving user's data to local storage
  handleSuccessResponse = (response: { data: any }) => {
    const user = {
      token: response.data.success.token,
      name: response.data.success.name,
      role_id: response.data.success.role_id,
      role: response.data.success.role,
      email: response.data.success.email,
      id: response.data.user_id
    }

    const appState: LocalStorageAppState = {
      isLoggedIn: true,
      user,
      loginTimestamp: new Date().toString()
    }

    // save app state with user date in local storage
    localStorage.appState = JSON.stringify(appState)
  }

  // setting modal to show forgotten password formular
  handleForgottenPassword = () => {
    this.setState({
      forgottenPassword: !this.state.forgottenPassword
    })
  }

  // handle submit email on forgotten password formular
  handleForgottenPasswordSubmit = (values: Store) => {
    api.auth
      .generateResetToken({ email: values.email })
      .then(() => {
        notification.success(this.props.t('login.passwordResetInstruction'))
      })
      .catch(error => {
        notification.error(this.props.t('login.passwordResetUnsuccessful'), error)
      })
  }

  // used to change password, after token from email is entered
  handleChangePasswordSubmit = (values: Store) => {
    if (this.state.email && this.state.token) {
      values.email = this.state.email
      values.token = this.state.token
    } else {
      notification.error(this.props.t('login.passwordResetUnsuccessful'))
      return
    }
    api.auth
      .resetPassword({
        email: values.email,
        password: values.password,
        c_password: values.c_password,
        token: values.token
      })
      .then(response => {
        this.handleSuccessResponse(response)
        this.context.reloadAuthStateFromLocalStorageAndSetApiAuthorizationToken()
        notification.success(this.props.t('login.passwordResetSuccessful'))
        notification.success(this.props.t('login.loginSuccessful'))
        this.props.handleOk()
      })
      .catch(error => {
        notification.error(
          this.props.t('login.passwordResetUnsuccessful'),
          error
        )
      })
  }

  // validate password
  compareToFirstPassword = (rule: Rule, value: string) => {
    if (
      value &&
      this.formRef.current &&
      value !== this.formRef.current.getFieldValue('password')
    ) {
      return Promise.reject(this.props.t('login.passwordsNonidentical'))
    } else {
      return Promise.resolve()
    }
  }

  // validate password
  validateToNextPassword = (rule: Rule, value: string) => {
    if (value && this.formRef.current && this.state.confirmDirty) {
      this.formRef.current.validateFields(['c_password'])
    }
    return Promise.resolve()
  }

  // validate if email exists
  checkIfEmailExists = async (rule: Rule, value: string) => {
    try {
      await api.auth.checkIfEmailExists({ email: value })
      return Promise.reject(this.props.t('login.emailNonexisting'))
    } catch (err) {
      return Promise.resolve()
    }
  }

  // check token validity
  checkToken = (token: string) => {
    api.auth
      .checkToken(token)
      .then(response => {
        this.setState({
          ...this.state,
          forgottenPassword: true,
          token: response.data.token,
          email: response.data.email
        })
        notification.success(
          this.props.t('login.tokenValid')
        )
      })
      .catch(error => {
        this.setState({
          forgottenPassword: true,
          token: null,
          email: null
        })
        notification.error(
          this.props.t('login.tokenNonvalid'),
          error
        )
      })
  }

  renderLoginForm(): ReactNode {
    return (
      <Form
        onFinish={this.handleLoginSubmit}
        onFinishFailed={(values: Store) => console.log(values)}
        className="login-form"
        style={{ paddingTop: 20 }}
      >
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: this.props.t('login.emailWrongFormat')
            },
            {
              required: true,
              message: this.props.t('login.emailEnter')
            }
          ]}
        >
          <Input
            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: this.props.t('login.passwordEnter') }]}
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, .25)' }} />}
            type="password"
            placeholder={this.props.t('login.password')}
          />
        </Form.Item>
        <Form.Item name="remember" valuePropName="checked">
          <Row justify="space-around">
            <Col>
              <Button
                className="login-form-forgot"
                type="link"
                onClick={this.handleForgottenPassword}
              >
                {this.props.t('login.passwordForgot')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '92%' }}>
            {this.props.t('login.loginMe')}
          </Button>
        </Form.Item>
      </Form>
    )
  }

  renderPasswordResetStageOne() {
    return <React.Fragment>
      <h3>{this.props.t('login.passwordRecovery')}</h3>
      <p>
        {this.props.t('login.passwordRecoveryInstruction')}
      </p>
      <Form
        onFinish={this.handleForgottenPasswordSubmit}
        className="login-form"
        style={{ paddingTop: 20 }}
      >
        <Form.Item name="email" validateFirst={true} validateTrigger="onBlur" rules={[
          {
            type: 'email',
            message: this.props.t('login.emailWrongFormat')
          },
          {
            required: true,
            message: this.props.t('login.emailEnter')
          },
          {
            validator: this.checkIfEmailExists
          }
        ]}>

          <Input
            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ width: '100%' }}
          >
            {this.props.t('login.passwordRecovery')}
          </Button>
        </Form.Item>
      </Form>
      <Row justify="center">
        <Col>
          <Button
            type="link"
            icon={<RollbackOutlined />}
            onClick={this.handleForgottenPassword}
          >
            {this.props.t('login.backToLogin')}
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  }

  renderPasswordResetStageTwo() {
    return <React.Fragment>
      <h3>{this.props.t('login.passwordRecovery')}</h3>
      <p>{this.props.t('login.passwordEnterNew')}</p>
      <Form
        onFinish={this.handleChangePasswordSubmit}
        className="login-form"
        style={{ paddingTop: 20 }}
      >
        <Form.Item name="password" rules={[
          {
            required: true,
            message: this.props.t('login.passwordEnter')
          },
          {
            min: 6,
            message: this.props.t('login.passwordRequirements')
          },
          {
            validator: this.validateToNextPassword
          }
        ]}>

          <Input.Password
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder={this.props.t('login.passwordNew')}
          />
        </Form.Item>
        <Form.Item name="c_password" rules={[
          {
            required: true,
            message: this.props.t('login.passwordReEnter')
          },
          {
            min: 6,
            message: this.props.t('login.passwordsRequirements')
          },
          {
            validator: this.compareToFirstPassword
          }
        ]}>

          <Input.Password
            // onBlur={this.handleConfirmBlur}
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder={this.props.t('login.passwordRepeatNew')}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ width: '92%' }}
          >
            {this.props.t('login.passwordChange')}
          </Button>
        </Form.Item>
      </Form>
      <Row justify="center">
        <Col>
          <Button
            type="link"
            icon={<RollbackOutlined />}
            onClick={this.handleForgottenPassword}
          >
            {this.props.t('login.backToLogin')}
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  }

  render() {
    let content
    if (!this.state.forgottenPassword) {
      content = this.renderLoginForm()
    } else if (this.state.forgottenPassword && this.state.token === null) {
      // password reset 1.stage
      content = this.renderPasswordResetStageOne()
    } else if (
      this.state.forgottenPassword &&
      this.state.token !== null &&
      this.state.email !== null
    ) {
      // password reset 2.stage
      content = this.renderPasswordResetStageTwo()
    }

    return <React.Fragment>{content} </React.Fragment>
  }
}

export default withTranslation()(withRouter(LoginForm))