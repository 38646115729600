import { withRouter, RouteComponentProps } from 'react-router-dom'
import React, { Component } from 'react'

/* Css */
import './SectionTitle.scss'

/* i18n */
import { WithTranslation, withTranslation } from 'react-i18next'
import MainCard from '../MainCard/MainCard'
import SignInModal from '../../../../Modals/SignInModal/SignInModal'
import EcehButton from '../../../../common/EcehButton/EcehButton'
import { Button } from 'antd'
import { UserAddOutlined } from '@ant-design/icons'


interface IProps extends RouteComponentProps, WithTranslation {
}

interface IState {
  defaultSignInTab: 'login' | 'register'
}

class SectionTitle extends Component<IProps, IState> {
  signInModal: typeof SignInModal | undefined | any

  constructor(props: IProps) {
    super(props)
    this.state = {
      defaultSignInTab: 'login'
    }
  }

  openSignInModal = () => this.signInModal?.openModal()

  login = () => {
    this.setState({ defaultSignInTab: 'login' })
    this.openSignInModal()
  }

  register = () => {
    this.setState({ defaultSignInTab: 'register' })
    this.openSignInModal()
  }

  componentDidMount() {
    /* @see https://stackoverflow.com/questions/35352638/how-to-get-parameter-value-from-query-string */
    const queryParams = new URLSearchParams(this.props.location.search)

    /* Show login->recover password modal, if path matched */
    if (this.props.match.path === '/password/find/:token') {
      this.setState({ defaultSignInTab: 'login' })
      this.signInModal?.openModal()
    }
    /* Show login modal, if query param */
    else if (queryParams.has('sign_in_modal')) {
      this.setState({ defaultSignInTab: 'login' })
      this.signInModal?.openModal()
      queryParams.delete('sign_in_modal')
      this.props.history.replace({ search: queryParams.toString() })
    }
  }

  render() {
    const { t, i18n } = this.props
    return (
      <MainCard heroBackground>
        <SignInModal
          defaultRegistration={this.state.defaultSignInTab === 'register'}
          childRef={(ref: typeof SignInModal | undefined) => (this.signInModal = ref)}
        />
        <div className="eceh-title-section__top">
          <h1>{t('titlePage.titleSection.title')}</h1>
          <p>{t('titlePage.titleSection.description')}</p>

          <div className="eceh-title-section__languages">
            <div className="eceh-title-section__language" onClick={() => i18n.changeLanguage('en')}>
              <img src="/images/title-page/flags/en.svg" alt="English" />
              <span>English</span>
            </div>
            <div className="eceh-title-section__language" onClick={() => i18n.changeLanguage('sk')}>
              <img src="/images/title-page/flags/svk.svg" alt="Slovenčina" />
              <span>Slovenčina</span>
            </div>
          </div>
        </div>

        <div className="eceh-title-section__bottom">
          <EcehButton label={t('welcome.logIn')} onClick={this.login} />

          <Button type="link" onClick={this.register}>
            <UserAddOutlined />
            {t('welcome.registerNow')}
          </Button>
        </div>
      </MainCard>
    )
  }
}

export default withTranslation()(withRouter(SectionTitle))