import { withRouter, RouteComponentProps } from 'react-router-dom'
import React, { Component } from 'react'

/* Css */
import './SectionRealTimeProgress.scss'

/* i18n */
import { WithTranslation, withTranslation } from 'react-i18next'
import MainCard from '../MainCard/MainCard'


interface IProps extends RouteComponentProps, WithTranslation {
}

interface IState {
  selectedLanguage: string;
}

class SectionRealTimeProgress extends Component<IProps, IState> {
  render() {
    const { t } = this.props

    return (
      <MainCard
        title={t('titlePage.progressMonitoringSection.title')}
        description={t('titlePage.progressMonitoringSection.description')}
      >
        <img
          className="eceh-monitoring__hero-image"
          src="/images/title-page/live-monitoring.png"
          alt="Real-time Student Progress Monitoring for Teachers inside ECEH"
        />
      </MainCard>
    )
  }
}

export default withTranslation()(withRouter(SectionRealTimeProgress))