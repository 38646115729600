import { withRouter, RouteComponentProps } from 'react-router-dom'
import React from 'react'
import { gApiKey } from '../../../config'
import { styles } from '../../../utils/MapUtils'
import GoogleMapReact from 'google-map-react'

/* Css */
import './Contact.scss'

/* i18n */
import { WithTranslation, withTranslation } from 'react-i18next'
import Layout from '../Welcome/partials/Layout/Layout'
import Header from '../Welcome/partials/Header/Header'
import SecondaryHeader from '../Welcome/partials/SecondaryHeader/SecondaryHeader'
import Footer from '../Welcome/partials/Footer/Footer'
import MainCard from '../Welcome/partials/MainCard/MainCard'

interface IContactItem {
  title: string,
  children: {
    label: string,
    href?: string
  }[]
}

interface IProps extends RouteComponentProps, WithTranslation {
}

interface IState {
  selectedLanguage: string;
}

class Contact extends React.Component<IProps, IState> {
  render() {
    const { t } = this.props

    const contactItems = t('contactPage.contactItems', { returnObjects: true }) as Array<IContactItem>

    const Marker = (props: any) => (
      <div className="eceh-contact-page__location-container">
        <img src="/images/contact-page/location-marker.svg" alt="" />
      </div>)

    const ecehPosition = {
      center: {
        lat: 48.15348905535179,
        lng: 17.071576585063717
      },
      zoom: 13
    }

    const createMapOptions = (maps: any) => ({ styles: styles })

    return (
      <Layout>
        <Header />
        <SecondaryHeader />

        {/* Content */}
        <MainCard title={t('contactPage.title')} className="eceh-contact-page">
          <div className="eceh-contact-page__address-container">
            <div className="eceh-contact-page__ac-inner">
              {contactItems.map(({ title, children }) => (
                <address className="eceh-contact-page__address" key={title}>
                  <h3 className="eceh-contact-page__address-title">{title}</h3>
                  {children.map(({ label, href }) =>
                    !!href ? (<a href={href} key={label}>{label}</a>) : (<span key={label}>{label}</span>)
                  )}
                </address>
              ))}
            </div>

            <img src="/images/contact-page/address-hero-image.svg" alt="" />
          </div>

          {/* Map */}
          <div className="eceh-contact-page__map-container">
            <GoogleMapReact
              bootstrapURLKeys={{ key: gApiKey }}
              defaultCenter={ecehPosition.center}
              defaultZoom={ecehPosition.zoom}
              options={createMapOptions}
            >
              <Marker lat={ecehPosition.center.lat} lng={ecehPosition.center.lng} />
            </GoogleMapReact>
          </div>

        </MainCard>
        <Footer />
      </Layout>
    )
  }
}

export default withTranslation()(withRouter(Contact))