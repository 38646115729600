import { message } from 'antd'
const notificationService = {
  success(note: string) {
    message.success(note)
  },
  error(note: string, e?: string, showNotification = true, options = {}) {
    if (showNotification) {
      message.error(note);
    }
    if (!process.env.PRODUCTION) {
      console.log(message, e, options);
    }
  },
}

export default notificationService;
