import React from 'react'
import { Spin } from 'antd'

import './Loading.css'


export default function Loading() {
  return (
    <div className="loadingFilter">
      <Spin size="large" />
    </div>
  )
}
