import React from 'react'
import { Link } from 'react-router-dom'
// import { LockOutlined, MailOutlined, UserOutlined, TeamOutlined, EditOutlined } from '@ant-design/icons'
import { MailOutlined, UserOutlined } from '@ant-design/icons'
import '@ant-design/compatible/assets/index.css'
// import { Input, Checkbox, Form, Select } from 'antd'
import { Input, Checkbox, Form } from 'antd'

//other
import api from '../../../../utils/ApiAxios'
import { AuthContext } from '../../../../context/Auth'
import notification from '../../../../utils/notificationService'
import { Store } from 'antd/lib/form/interface'
import { Rule, FormInstance } from 'antd/lib/form'
import { RefObject } from 'react'
import User from '../../../../models/user'
import Organization from '../../../../models/organization'
import LocalStorageAppState from '../../../../models/appState'
import { LabeledValue } from 'antd/lib/tree-select'
import './RegistrationTab.scss'

import { WithTranslation, withTranslation } from 'react-i18next'
import EcehButton from '../../../common/EcehButton/EcehButton'

// const { Option } = Select

interface IProps extends WithTranslation {
  handleOk: () => void;

  /* Initial values */
  initialEmail?: string;
  initialPassword?: string;
}

interface IState {
  confirmPrivatePolicy: boolean;
  confirmDirty: boolean;
  organizationTreeData: LabeledValue[];
  organizationTreeValue: LabeledValue[];
  hasOrganisation: boolean;
  selectedOrganisation: number;
}

class RegistrationTab extends React.Component<IProps, IState> {

  private formRef: RefObject<FormInstance> = React.createRef()

  constructor(props: IProps) {
    super(props)
    this.state = {
      confirmPrivatePolicy: false,
      confirmDirty: false,
      organizationTreeData: [],
      organizationTreeValue: [],
      hasOrganisation: false,
      selectedOrganisation: -1
    }
  }

  static contextType = AuthContext

  componentDidMount() {
    api.organizations
      .getAllOrganizations()
      .then(response => {
        const data = response.data.reduce(
          (result: LabeledValue[], organization: Organization) => {
            if (organization.name && organization.id) {
              result.push({ label: organization.name, value: organization.id })
            }
            return result
          },
          []
        )
        this.setState({
          organizationTreeData: data
        })
      })
  }

  handlePrivacyPolicy = () => {
    this.setState({
      confirmPrivatePolicy: !this.state.confirmPrivatePolicy
    })
  }

  handleOrganisationSelect = (e: number) => {
    this.setState({
      selectedOrganisation: e
    }, () => {
      let temp
      if (this.state.selectedOrganisation > 1) {
        temp = true
      } else {
        temp = false
        if (this.formRef.current) {
          this.formRef.current?.resetFields(['class'])
        }
      }

      this.setState({ hasOrganisation: temp }, () => {
        if (this.formRef.current) {
          this.formRef.current.validateFields(['class'])
        }
      })
    })
  }

  /* Register and if successful login and redirect to '/dashboard' */
  handleRegistrationSubmit = (values: Store) => {
    api.auth
      .requestInvite({
        array: [[values.firstname, values.lastname, values.email, values.textarea]]
    }).then(response => {
      notification.success(this.props.t('login.registrationRequestSuccessful'))
      /* Reload state and set auth api token */
      this.context.reloadAuthStateFromLocalStorageAndSetApiAuthorizationToken()
    }).catch(error => {
      console.error('Error occurred:', error);
      throw error;
    });
    
  } 

  // chceck if user agreed to the privacy policy
  checkPrivacyPolicy = (rule: Rule, value: boolean) => {
    if (value) {
      return Promise.resolve()
    } else {
      return Promise.reject(this.props.t('login.policyAgreement'))
    }
  }

  // check if user with same email isn't registred yet
  checkIfEmailExists = async (rule: Rule, value: string) => {
    try {
      await api.auth.checkIfEmailExists({ email: value })
      return Promise.resolve()

    } catch (err) {
      return Promise.reject(this.props.t('login.emailExists'))
    }
  }

  // compare password
  compareToFirstPassword = (rule: Rule, value: string) => {
    if (value && this.formRef.current && value !== this.formRef.current.getFieldValue('password')) {
      return Promise.reject(this.props.t('login.passwordsNonidentical'))
    } else {
      return Promise.resolve()
    }
  }

  // comapre password
  validateToNextPassword = (rule: Rule, value: string) => {
    if (value && this.formRef.current && this.state.confirmDirty) {
      this.formRef.current.validateFields(['c_password'])
    }
    return Promise.resolve()
  }

  render() {
    return (
      <Form
        onFinish={this.handleRegistrationSubmit}
        className="login-form eceh-registration-tab__login-form"
        ref={this.formRef}
      >
        <h3 className="eceh-registration-tab__h3">{this.props.t('login.personalInformation')}</h3>
        <Form.Item
          validateTrigger="onBlur"
          name="firstname"
          rules={[
            {
              required: true,
              message: this.props.t('login.enterName'),
              whitespace: true
            }
          ]}
        >
          <Input
            autoComplete="given-name"
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={this.props.t('login.name')}
          />
        </Form.Item>
        <Form.Item
          validateTrigger="onBlur"
          name="lastname"
          rules={[
            {
              required: true,
              message: this.props.t('login.enterLastname'),
              whitespace: true
            }
          ]}
        >
          <Input
            autoComplete="family-name"
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={this.props.t('login.lastname')}
          />
        </Form.Item>
        <Form.Item
          name="email"
          validateFirst={true}
          validateTrigger="onBlur"
          rules={[
            {
              type: 'email',
              message: this.props.t('login.emailWrongFormat')
            },
            {
              required: true,
              message: this.props.t('login.emailEnter')
            },
            {
              validator: this.checkIfEmailExists
            }
          ]}
        >
          <Input
            defaultValue={this.props.initialEmail}
            autoComplete="email"
            type="email"
            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email"
          />
        </Form.Item>

        {/* Text field */}
        <h3 className="eceh-registration-tab__h3">{this.props.t('login.textField')}</h3>
        <Form.Item
          name="textarea"
        >
          {/* <Input
              defaultValue={this.props.initialEmail}
              // autoComplete="off"
              // prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={this.props.t('login.textFieldPlaceholder')}
              // style={{ height: '40px', fontSize: '16px', wordWrap: 'break-word' }}
              // autoSize={{ minRows: 2, maxRows: 6 }}
            /> */}
            <Input.TextArea
              autoComplete="given-name"
              autoSize={{ minRows: 3, maxRows: 10 }}
              // prefix={<EditOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={this.props.t('login.textFieldPlaceholder')}
            />
        </Form.Item>

        {/* Password */}
        {/* <h3 className="eceh-registration-tab__h3">{this.props.t('login.password')}</h3>
        <Form.Item
          validateTrigger="onBlur"
          name="password"
          rules={[
            {
              required: true,
              message: this.props.t('login.passwordEnter')
            },
            {
              min: 6,
              message: this.props.t('login.passwordRequirements')
            },
            {
              validator: this.validateToNextPassword
            }
          ]}
        >
          <Input.Password
            defaultValue={this.props.initialPassword}
            autoComplete="new-password"
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder={this.props.t('login.password')}
          />
        </Form.Item>
        <Form.Item
          validateTrigger="onBlur"
          name="c_password"
          rules={[
            {
              required: true,
              message: this.props.t('login.passwordReEnter')
            },
            {
              min: 6,
              message: this.props.t('login.passwordRequirements')
            },
            {
              validator: this.compareToFirstPassword
            }
          ]}
        >
          <Input.Password
            defaultValue={this.props.initialPassword}
            autoComplete="new-password"
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder={this.props.t('login.passwordRepeat')}
          />
        </Form.Item> */}

        {/* Organization */}
        {/* <h3 className="eceh-registration-tab__h3">{this.props.t('login.organization')}</h3>
        <Form.Item
          name="organization"
          validateFirst={true}
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: this.props.t('login.organizationSelect')
            }
          ]}
        >
          <Select
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(input.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
            }
            showSearch
            placeholder={this.props.t('login.organization')}
            onChange={(e) => {
              this.handleOrganisationSelect(e as number)
            }}
          >
            options={this.state.organizationTreeData.map(item => (
            <Option value={item.value} key={item.value} label={item.label}>
              <p>{item.label}</p>
            </Option>
          ))}
          </Select>
        </Form.Item> */}

        {/* <Form.Item
          validateTrigger="onBlur"
          name="class"
          validateFirst={true}
          rules={[
            {
              required: this.state.hasOrganisation,
              message: this.props.t('login.classEnter')
            },
            {
              max: 5,
              message: this.props.t('login.classRequirements')
            }
          ]}
        >
          <Input
            prefix={<TeamOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={this.props.t('login.class')}
            disabled={!this.state.hasOrganisation}
          />
        </Form.Item> */}

        {/* Rest */}
        <Form.Item
          name="confirm_private_policy"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: ' '
            },
            {
              validator: this.checkPrivacyPolicy
            }
          ]}
        >
          <Checkbox onChange={this.handlePrivacyPolicy}>
            {this.props.t('login.policyAgreed')},{' '}
            <Link target="_blank" to="/privatepolicy">
              {this.props.t('login.moreInfo')}
            </Link>
          </Checkbox>
        </Form.Item>
        <Form.Item className="eceh-registration-tab__cta-container">
          <EcehButton htmlType="submit" label={this.props.t('login.registerMe')} xs />
        </Form.Item>
      </Form>
    )
  }
}

export default withTranslation()(RegistrationTab)
