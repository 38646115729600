import React from 'react'
import { Link } from 'react-router-dom'
import { MailOutlined, UserOutlined, UploadOutlined } from '@ant-design/icons'
import '@ant-design/compatible/assets/index.css'
import { Input, Checkbox, Form, Button } from 'antd';

//other
import api from '../../../utils/ApiAxios'
import { AuthContext } from '../../../context/Auth'
import notification from '../../../utils/notificationService'
import { Store } from 'antd/lib/form/interface'
import { Rule, FormInstance } from 'antd/lib/form'
import { RefObject } from 'react'
import Organization from '../../../models/organization'
import { LabeledValue } from 'antd/lib/tree-select'
import './Invite.css'

import { WithTranslation, withTranslation } from 'react-i18next'
import EcehButton from '../../common/EcehButton/EcehButton'

interface IProps extends WithTranslation {
  handleOk: () => void;

  /* Initial values */
  initialEmail?: string;
  initialPassword?: string;
}

interface IState {
  confirmPrivatePolicy: boolean;
  confirmDirty: boolean;
  organizationTreeData: LabeledValue[];
  organizationTreeValue: LabeledValue[];
  hasOrganisation: boolean;
  selectedOrganisation: number;
  csvData: string[][];
}

class Invite extends React.Component<IProps, IState> {

  private formRef: RefObject<FormInstance> = React.createRef()

  constructor(props: IProps) {
    super(props)
    this.state = {
      confirmPrivatePolicy: false,
      confirmDirty: false,
      organizationTreeData: [],
      organizationTreeValue: [],
      hasOrganisation: false,
      selectedOrganisation: -1,
      csvData: [],
    }
  }

  static contextType = AuthContext

  componentDidMount() {
    api.organizations
      .getAllOrganizations()
      .then(response => {
        const data = response.data.reduce(
          (result: LabeledValue[], organization: Organization) => {
            if (organization.name && organization.id) {
              result.push({ label: organization.name, value: organization.id })
            }
            return result
          },
          []
        )
        this.setState({
          organizationTreeData: data
        })
      })
  }

  handlePrivacyPolicy = () => {
    this.setState({
      confirmPrivatePolicy: !this.state.confirmPrivatePolicy
    })
  }

  /*
  api.auth
      .register({
        email: values.email,
        password: values.password,
        c_password: values.c_password,
        confirm_private_policy: values.confirm_private_policy,
        firstname: values.firstname,
        lastname: values.lastname,
        organization_id: values.organization,
        class: values.class === undefined ? null : values.class
      })
      .then(response => {
        const user: User = {
          token: response.data.success.token,
          name: response.data.success.name,
          role_id: response.data.success.role_id,
          role: response.data.success.role,
          email: response.data.success.email,
          id: response.data.user_id
        }

        const appState: LocalStorageAppState = { isLoggedIn: true, user, loginTimestamp: new Date().toString() }
        localStorage.appState = JSON.stringify(appState)

        notification.success(this.props.t('login.registrationSuccessful'))

        this.context.reloadAuthStateFromLocalStorageAndSetApiAuthorizationToken()

        this.props.handleOk()
      })
      .catch(error => notification.error(this.props.t('login.registrationError'), error))
  */

  /* Register and if successful login and redirect to '/dashboard' */
  handleRegistrationSubmit = (values: Store) => {
    api.auth
      .invite({
        array: [[values.firstname, values.lastname, values.email]]
    }).then(response => {
      console.log(response)
      notification.success(this.props.t('login.registrationRequestSuccessful'))
    }).catch(error => {
      console.error('Error occurred:', error);
      throw error;
    });
  }

  handleRegistrationCSV = () =>{
    console.log(this.state.csvData);
    api.auth
      .invite({
        array: this.state.csvData
    }).then(response => {
      console.log(response)
      notification.success(this.props.t('login.registrationRequestSuccessful'))
    }).catch(error => {
      console.error('Error occurred:', error);
      throw error;
    });
  }
  
  // chceck if user agreed to the privacy policy
  checkPrivacyPolicy = (rule: Rule, value: boolean) => {
    if (value) {
      return Promise.resolve()
    } else {
      return Promise.reject(this.props.t('login.policyAgreement'))
    }
  }

  // check if user with same email isn't registred yet
  checkIfEmailExists = async (rule: Rule, value: string) => {
    try {
      await api.auth.checkIfEmailExists({ email: value })
      return Promise.resolve()

    } catch (err) {
      return Promise.reject(this.props.t('login.emailExists'))
    }
  }

  // compare password
  compareToFirstPassword = (rule: Rule, value: string) => {
    if (value && this.formRef.current && value !== this.formRef.current.getFieldValue('password')) {
      return Promise.reject(this.props.t('login.passwordsNonidentical'))
    } else {
      return Promise.resolve()
    }
  }

  // comapre password
  validateToNextPassword = (rule: Rule, value: string) => {
    if (value && this.formRef.current && this.state.confirmDirty) {
      this.formRef.current.validateFields(['c_password'])
    }
    return Promise.resolve()
  }

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }
  
    const file = files[0];
  
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target?.result as string;
      const lines = text.split('\n');
      const data: any[] = [];
  
      for (let i = 0; i < lines.length; i++) { // Start from 0 to include header line
        const fields = lines[i].split(',');
  
        const entry: any[] = [];
        for (let j = 0; j < fields.length; j++) {
          const trimmedValue = fields[j].trim();
          entry.push(trimmedValue);
        }
        data.push(entry);
      }
      this.setState({ csvData: data });
    };
    reader.readAsText(file);
  };

  render() {
    return (
      <>
      <Form
        onFinish={this.handleRegistrationSubmit}
        className="login-form eceh-registration-tab__login-form"
        ref={this.formRef}
      >
        <h3 className="eceh-registration-tab__h3">{this.props.t('login.personalInformation')}</h3>
        <Form.Item
          validateTrigger="onBlur"
          name="firstname"
          rules={[
            {
              required: true,
              message: this.props.t('login.enterName'),
              whitespace: true
            }
          ]}
        >
          <Input
            autoComplete="given-name"
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={this.props.t('login.name')}
          />
        </Form.Item>
        <Form.Item
          validateTrigger="onBlur"
          name="lastname"
          rules={[
            {
              required: true,
              message: this.props.t('login.enterLastname'),
              whitespace: true
            }
          ]}
        >
          <Input
            autoComplete="family-name"
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={this.props.t('login.lastname')}
          />
        </Form.Item>
        <Form.Item
          name="email"
          validateFirst={true}
          validateTrigger="onBlur"
          rules={[
            {
              type: 'email',
              message: this.props.t('login.emailWrongFormat')
            },
            {
              required: true,
              message: this.props.t('login.emailEnter')
            },
            {
              validator: this.checkIfEmailExists
            }
          ]}
        >
          <Input
            defaultValue={this.props.initialEmail}
            autoComplete="email"
            type="email"
            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email"
          />
        </Form.Item>

        {/* Rest */}
        <Form.Item
          name="confirm_private_policy"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: ' '
            },
            {
              validator: this.checkPrivacyPolicy
            }
          ]}
        >
          <Checkbox onChange={this.handlePrivacyPolicy}>
            {this.props.t('login.policyAgreed')},{' '}
            <Link target="_blank" to="/privatepolicy">
              {this.props.t('login.moreInfo')}
            </Link>
          </Checkbox>
        </Form.Item>
        <Form.Item className="eceh-registration-tab__cta-container">
          <EcehButton htmlType="submit" label={this.props.t('login.registerMe')} xs />
        </Form.Item>
      </Form>
      <Form>
        <Form.Item>
        <input
          type="file"
          id="file"
          onChange={this.handleFileChange}
          accept=".csv"
          multiple
        />
        </Form.Item>
        <Button icon={<UploadOutlined />} onClick={this.handleRegistrationCSV}> Nahrať súbor</Button>
      </Form>
      </>
    )
  }
}

export default withTranslation()(Invite)
