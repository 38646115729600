import React, { Component } from 'react'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'

import './EcehButton.scss'


interface IProps extends ButtonProps {
  label: string
  className?: string
  xs?: boolean
}

interface IState {
  selectedLanguage: string;
}

class EcehButton extends Component<IProps, IState> {
  render() {
    const { className: classProp, xs, ...otherProps } = this.props

    const className = `eceh-button ${classProp ? classProp : ''} ` +
      `${xs ? 'eceh-button--xs' : ''}`

    return (
      <Button
        type="primary"
        shape="round"
        size="large"
        className={className}
        {...otherProps}
      >
        {this.props.label}
      </Button>
    )
  }
}

export default EcehButton