import React from 'react'
import {DeleteOutlined} from '@ant-design/icons'
import {Table, Button, Tooltip} from 'antd'
import User from '../../../../models/user'

//translation
import { useTranslation} from 'react-i18next';

interface IProps {
  data: User[]
  onDelete: (id?: number, name?: string) => void
}


const StudentsTable: React.FunctionComponent<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  return (
    <Table<User>
      rowKey={record => (record.id ? record.id : -1)}
      dataSource={props.data}
    >
      <Table.Column title={t('basic.name')} dataIndex="name" />
      <Table.Column title={t('basic.email')} dataIndex="email" />
      <Table.Column title={t('basic.class')} dataIndex="class" />
      <Table.Column
        title={t('basic.actions')}
        render={(text, student: User) => {
          return (
            <Tooltip title={t('activities.subjectRemoveStudent')}>
              <Button
                onClick={e => {
                  e.stopPropagation()
                  props.onDelete && props.onDelete(student.id, student.name)
                }}
                icon={<DeleteOutlined />}
                danger
              />
            </Tooltip>
          )
        }}
      />
    </Table>
  )
}

export default StudentsTable
