import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import React, { Component } from 'react'

/* Css */
import './Header.scss'

/* i18n */
import { WithTranslation, withTranslation } from 'react-i18next'


interface IProps extends RouteComponentProps, WithTranslation {
}

interface IState {
  selectedLanguage: string;
}

class Header extends Component<IProps, IState> {
  render() {
    return (
      <header className="eceh-header">
        <Link to="/">
          <img src="/images/title-page/norway-grants.svg" alt="Logo of Norway Grants" />
        </Link>
        <img src="/images/title-page/coat-of-arms-of-slovakia.svg" alt="Coat of arms of Slovakia" />
      </header>
    )
  }
}

export default withTranslation()(withRouter(Header))