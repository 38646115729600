import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import EventsTable from '../Events/EventsTable/EventsTable'
import ActivitiesTable from '../Activities/ActivitiesTable/ActivitiesTable'
import api from '../../../utils/ApiAxios'
import notification from '../../../utils/notificationService'
import { Collapse, Input } from 'antd'
import Loading from '../../common/Loading/Loading'
import './Dashboard.css'

//other
import { AuthContext } from '../../../context/Auth'
import Activity from '../../../models/activity'
import axios, { AxiosResponse, CancelTokenSource } from 'axios'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'

interface IState {
  events: Event[]
  loading: boolean
  activities: Activity[]
  registeredActivities: Activity[]
}

interface IProps extends RouteComponentProps, WithTranslation {

}

const Panel = Collapse.Panel
let getFilteredEventsCancelToken: CancelTokenSource | undefined
let getFilteredActivitiesCancelToken: CancelTokenSource | undefined
let getSubscribedActivitiesCancelToken: CancelTokenSource | undefined

class Dashboard extends React.Component<IProps, IState> {
  private searched_text = "";

  static contextType = AuthContext
  state = {
    events: [],
    loading: true,
    activities: [],
    registeredActivities: [],
  }

  getFilteredActivities = () => {
    /* Cancel previous request if exists */
    if (getFilteredActivitiesCancelToken) getFilteredActivitiesCancelToken.cancel('Request canceled due to new request')

    /* Create new cancel token */
    getFilteredActivitiesCancelToken = axios.CancelToken.source()


    api.activities
      .getFilteredActivities({ text: this.searched_text, user: this.context.user.id }, getFilteredActivitiesCancelToken)
      .then(response => {
        this.setState({
          activities: response.data,
          loading: false
        })
      })
      .catch(error => notification.error('Obsah stránky sa neporadilo načítať, skúste stránku načítať neskôr', error))
  }

  getSubscribedActivities = () => {
    /* Cancel previous request if exists */
    if (getSubscribedActivitiesCancelToken) getSubscribedActivitiesCancelToken.cancel('Request canceled due to new request')

    /* Create new cancel token */
    getSubscribedActivitiesCancelToken = axios.CancelToken.source()


    api.activities
      .getSubscribedActivities({user: this.context.user.id, text: this.searched_text}, getSubscribedActivitiesCancelToken)
      .then(response => {
        this.setState({
          registeredActivities: response.data,
          loading: false
        })
      })
      .catch(error => notification.error('Obsah stránky sa neporadilo načítať, skúste stránku načítať neskôr', error))
  }

  getFilteredEventsSearch = () => {
    /* Cancel previous request if exists */
    if (getFilteredEventsCancelToken) getFilteredEventsCancelToken.cancel('Request canceled due to new request')

    /* Create new cancel token */
    getFilteredEventsCancelToken = axios.CancelToken.source()

    api.events
      .getFilteredEventsSearch({ text: this.searched_text, user: this.context.user.id }, getFilteredEventsCancelToken)
      .then((response: AxiosResponse) => {
        this.setState({
          events: response.data,
          loading: false
        })
      })
      .catch(error => notification.error('Obsah stránky sa neporadilo načítať, skúste stránku načítať neskôr', error))
  }

  componentDidMount() {
    const user = this.context.user
    const id = user.id

    /* Cancel previous request if exists */
    if (getFilteredEventsCancelToken) getFilteredEventsCancelToken.cancel('Request canceled due to new request')
    if (getSubscribedActivitiesCancelToken) getSubscribedActivitiesCancelToken.cancel('Request canceled due to new request')
    if (getFilteredActivitiesCancelToken) getFilteredActivitiesCancelToken.cancel('Request canceled due to new request')

    /* Create new cancel token */
    getFilteredEventsCancelToken = axios.CancelToken.source()
    getSubscribedActivitiesCancelToken = axios.CancelToken.source()
    getFilteredActivitiesCancelToken = axios.CancelToken.source()

    Promise.all([
      api.activities.getFilteredActivities({text: this.searched_text, user: id }, getFilteredActivitiesCancelToken),
      api.activities.getSubscribedActivities({text: this.searched_text, user: id }, getSubscribedActivitiesCancelToken),
      api.events.getFilteredEventsSearch({ user: id, text: this.searched_text }, getFilteredEventsCancelToken)
    ])
      .then((response: AxiosResponse[]) => {
        //console.log(response[2].data)
        this.setState({
          activities: response[0].data,
          registeredActivities: response[1].data,
          events: response[2].data,
          loading: false
        })
      })
      .catch(error => {
        notification.error('Obsah stránky sa neporadilo načítať, skúste stránku načítať neskôr', error)
      })
  }

  componentWillUnmount() {
    if (getFilteredEventsCancelToken) getFilteredEventsCancelToken.cancel('Request canceled due component unmounting')

    if (getFilteredActivitiesCancelToken) getFilteredActivitiesCancelToken.cancel('Request canceled due component unmounting')

    if (getSubscribedActivitiesCancelToken) getSubscribedActivitiesCancelToken.cancel('Request canceled due component unmounting')
  }

  render() {
    const userType = this.context.user.role === 'student' ? '3' : '2'
    const { t } = this.props
    return (
      <React.Fragment>
        {this.state.loading && <Loading />}
        {!this.state.loading && (
          <div className="style">
            <h2>Dashboard</h2>
            <Collapse accordion bordered={false} defaultActiveKey={userType}>
              <Panel header={t('dashboard.listOfCreatedSubjects')} key="2">
                <Input.Search
                  name="search_bar_created"
                  placeholder={(t('dashboard.enterSearchText'))}
                  onSearch={(e) => {
                    this.searched_text = e
                    this.getFilteredActivities()
                  }}
                />
                <ActivitiesTable activities={this.state.activities} />
              </Panel>
              <Panel header={t('dashboard.listOfRegisteredSubjects')} key="3">
                <Input.Search
                    name="search_bar_registered"
                    placeholder={(t('dashboard.enterSearchText'))}
                    onSearch={(e) => {
                      this.searched_text = e
                      this.getSubscribedActivities()
                    }}
                />
                <ActivitiesTable activities={this.state.registeredActivities} />
              </Panel>
              <Panel header={t('dashboard.listOfCreatedTasks')} key="1">
                <Input.Search
                    name="search_bar_events"
                    placeholder={(t('dashboard.enterSearchText'))}
                    onSearch={(e) => {
                      this.searched_text = e
                      this.getFilteredEventsSearch()
                    }}
                />
                <EventsTable events={this.state.events} />
              </Panel>
            </Collapse>
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default withTranslation()(withRouter(Dashboard))
