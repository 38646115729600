import React from 'react';

class LoggedInBoundary extends React.Component {
  render () {
    if (!this.props.isLoggedIn) {
      window.history.pushState({}, '', '/');
      window.location.reload(true);
    }
    return this.props.children;
  }
}

export default LoggedInBoundary;
