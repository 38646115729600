import React from 'react'
import {Link} from 'react-router-dom'
import {Result, Button} from 'antd'
import {ResultProps} from 'antd/lib/result'

import { WithTranslation, withTranslation } from 'react-i18next';

interface IProps extends WithTranslation{
  errorCode: keyof IState
}
interface errorInformation {
  code: ResultProps['status']
  text: string
}
interface IState {
  403: errorInformation
  404: errorInformation
  500: errorInformation
}
// error page component, automaticaly showing error message based on error code in props
class NotFoundPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      403: {code: '403', text: this.props.t('error.e403')},
      404: {code: '404', text: this.props.t('error.e404')},
      500: {code: '500', text: this.props.t('error.e500')}
    }
  }

  render() {
    return (
      <Result
        status={this.state[this.props.errorCode].code}
        title={this.state[this.props.errorCode].code}
        subTitle={this.state[this.props.errorCode].text}
        extra={
          <Link to="/">
            <Button type="primary">{this.props.t('error.backHome')}</Button>
          </Link>
        }
      />
    )
  }
}

export default withTranslation()(NotFoundPage)
