
import { withRouter, RouteComponentProps } from 'react-router-dom'
import React, { Component } from 'react'

/* Css */
import './MainCard.scss'

/* i18n */
import { WithTranslation, withTranslation } from 'react-i18next'


interface IProps extends RouteComponentProps, WithTranslation {
  title?: string,
  description?: string,
  heroBackground?: boolean,
  dark?: boolean,
  className?: string,
}

interface IState {
  selectedLanguage: string;
}

class MainCard extends Component<IProps, IState> {
  render() {
    const { children, title, description, heroBackground, dark, className = '' } = this.props
    return (
      <div
        className={'eceh-main-card ' + (heroBackground ? 'eceh-main-card--hero ' : '') + (dark ? 'eceh-main-card--dark ' : '') + className}
      >
        {
          title || description ?
            <div className="eceh-main-card__heading">
              {title ? <h2>{title}</h2> : null}
              {description ? <p>{description}</p> : null}
            </div>
            : null
        }
        {children}
      </div>
    )
  }
}

export default withTranslation()(withRouter(MainCard))