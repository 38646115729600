import React, { RefObject } from 'react'
import '@ant-design/compatible/assets/index.css'
import { Input, Button, Radio, InputNumber, List, Checkbox, Card, Form } from 'antd'
// @ts-expect-error: CKEditor doesnt have types - fix if they have them in future
import CKEditor from '@ckeditor/ckeditor5-react'
// @ts-expect-error: CKEditor doesnt have types - fix if they have them in future
import Editor from '@drblmthw/testujsk_custom_ckeditor5/build/ckeditor'
import '@drblmthw/testujsk_custom_ckeditor5/build/translations/en'

import './EventCreation.css'
import { withRouter, RouteComponentProps } from 'react-router'
import api from '../../../utils/ApiAxios'
import { formToEventTransform } from '../../../utils/eventTransformer'
import { isWhitespaceOrEmpty } from '../../../utils/generalHelpers'
import notificationService from '../../../utils/notificationService'
import Options from '../../../models/option'
import Help from '../../../models/help'
import Event from '../../../models/event'
import { FormInstance, Rule } from 'antd/lib/form'
import { Store } from 'antd/lib/form/interface'
import { AuthContext } from '../../../context/Auth'
import { apiBaseUrl } from '../../../config'
//@ts-ignore
import { InlineMath, BlockMath } from 'react-katex'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'

interface IProps extends RouteComponentProps, WithTranslation {

}

interface IState {
  loading: boolean;
  dirty: boolean;
  // options state
  options: Options[];
  optionsEditorActive: boolean;
  answerEditorActive: boolean;
  optionsEditorNumber: number;
  optionsEditor: CKEditor | null;
  editedOption: Options | null;
  editedOptionCorrectnes: boolean;
  withoutAnswers: boolean;
  // helps state
  helps: Help[];
  helpEditorActive: boolean;
  helpEditor: CKEditor | null;
  editedHelp: Help | null;
  // event state
  eventEditor: CKEditor | null; //React CKEditor, doesnt have type
  event: Event;
  optionsToDelete: number[];
  helpsToDelete: number[];
  // category state
}

class EventCreationComponent extends React.Component<IProps, IState> {
  static contextType = AuthContext
  private formRef: RefObject<FormInstance> = React.createRef<FormInstance>()
  // eslint-disable-next-line
  private time_to_complete = new Number

  constructor(props: IProps) {
    super(props)
    this.state = {
      loading: false,
      dirty: false,
      options: [],
      optionsEditorActive: false,
      answerEditorActive: false,
      optionsEditorNumber: 0,
      optionsEditor: null,
      editedOption: null,
      editedOptionCorrectnes: false,
      withoutAnswers: true,
      helps: [],
      helpEditorActive: false,
      helpEditor: null,
      editedHelp: null,
      eventEditor: null,
      event: {
        event_type_id: 1,
        header: '',
        time_to_explain: 0,
        time_to_handle: 0,
        message: '',
        category_ids: []
      },
      optionsToDelete: [],
      helpsToDelete: []
    }
  }

  handleSubmit = (values: Store) => {
    let opt: Options[] = []


    if (this.state.optionsEditorNumber === 3 && this.formRef.current) {
      opt.push({
        answer_data: this.formRef.current.getFieldValue('text_answer'),
        correct_answer: true
      })
    } else {
      opt = this.state.options
    }

    if (
      this.state.options.length < 2 &&
      this.state.optionsEditorNumber !== 7 &&
      this.state.optionsEditorNumber !== 3
    ) {
      notificationService.error(this.props.t('events.numberOfOptions'))
      return
    }
    if (!this.state.eventEditor || isWhitespaceOrEmpty(this.state.eventEditor.getData())) {
      notificationService.error(this.props.t('events.eventDescription'))
      return
    }
    // -------------------------------------------------------
    //if the time values werent set
    if (values.time_hour === undefined) {
      values.time_hour = 0
    }
    if (values.time_min === undefined) {
      values.time_min = 0
    }
    if (values.time_sec === undefined) {
      values.time_sec = 0
    }

    //calculate total time in seconds
    values.timeToFinish = values.time_hour * 3600 + values.time_min * 60 + values.time_sec

    // -------------------------------------------------------
    const event = formToEventTransform(values, this.state.eventEditor)
    //console.log('values', values)
    //console.log('event', event)
    api.events
      .createEvent(event)
      .then((success) => {
        if (success.data.id) {
          api.createEventOptionsAndHelps(opt, this.state.helps, success.data.id).then(() => {
            notificationService.success(this.props.t('events.eventCreateSuccess'))
            this.props.history.push('/events')
          })
        } else {
          throw Error('[ERROR] CREATE EVENT - EventId missing')
        }
      })
      .catch((error) => {
        notificationService.error(this.props.t('events.eventCreateUnsuccess'), error)
      })
  }

  addOptionCKEditor = (data?: Options) => {
    this.setState({ optionsEditorActive: true })

    const success = this.props.t('events.imageUploadSuccess')
    const unsuccess = this.props.t('events.imageUploadUnsuccess')

    function uploaderPlugin(editor: {
      plugins: {
        get: (arg0: string) => {
          (): any;
          new(): any;
          createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; };
        };
      };
    }) {
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new UploadAdapter(loader, success, unsuccess)
      }
    }

    if (!this.state.optionsEditor) {
      Editor.create(document.getElementById('txt_area'), {
        extraPlugins: [uploaderPlugin],
        mediaEmbed: {
          previewsInData: true
        },
        language: this.props.i18n.language,
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'outdent',
          'indent',
          '|',
          'blockQuote',
          'insertTable',
          'imageUpload',
          'mediaEmbed',
          '|',
          'math',
          '|',
          'undo',
          'redo'
        ]
      })
        .then((editor: CKEditor) => {
          if (data) {
            this.setState({
              editedOption: data,
              editedOptionCorrectnes: data.correct_answer
            })
            editor.setData(data.answer_data)
          } else {
            editor.setData('')
          }
          this.setState({ optionsEditor: editor })
        })
        .catch((error: any) => {
          notificationService.error('', error, false)
        })
    }
  }

  addHelpCKEditor = (data?: Help) => {
    this.setState({ helpEditorActive: true })

    const success = this.props.t('events.imageUploadSuccess')
    const unsuccess = this.props.t('events.imageUploadUnsuccess')

    function uploaderPlugin(editor: {
      plugins: {
        get: (arg0: string) => {
          (): any;
          new(): any;
          createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; };
        };
      };
    }) {
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new UploadAdapter(loader, success, unsuccess)
      }
    }

    if (!this.state.helpEditor) {
      Editor.create(document.getElementById('help_area'), {
        extraPlugins: [uploaderPlugin],
        mediaEmbed: {
          previewsInData: true
        },
        language: this.props.i18n.language,
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'outdent',
          'indent',
          '|',
          'blockQuote',
          'insertTable',
          'imageUpload',
          'mediaEmbed',
          '|',
          'math',
          '|',
          'undo',
          'redo'
        ]
      })
        .then((editor: CKEditor) => {
          if (data) {
            this.setState({
              editedHelp: data
            })
            editor.setData(data.url)
          } else {
            editor.setData('')
          }
          this.setState({ helpEditor: editor })
        })
        .catch((error: any) => {
          notificationService.error('', error, false)
        })
    }
  }

  editHelp = (help: Help) => {

    const success = this.props.t('events.imageUploadSuccess')
    const unsuccess = this.props.t('events.imageUploadUnsuccess')

    function uploaderPlugin(editor: {
      plugins: {
        get: (arg0: string) => {
          (): any;
          new(): any;
          createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; };
        };
      };
    }) {
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new UploadAdapter(loader, success, unsuccess)
      }
    }

    if (!this.state.helpEditor) {
      Editor.create(document.getElementById('help_area'), {
        extraPlugins: [uploaderPlugin],
        mediaEmbed: {
          previewsInData: true
        },
        language: this.props.i18n.language,
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'outdent',
          'indent',
          '|',
          'blockQuote',
          'insertTable',
          'imageUpload',
          'mediaEmbed',
          '|',
          'math',
          '|',
          'undo',
          'redo'
        ]
      })
        .then((editor: CKEditor) => {
          this.setState({ helpEditor: editor })

          if (help) {
            this.setState({
                editedHelp: help,
                helpEditorActive: true
              },
              () => {
                this.formRef.current &&
                this.formRef.current.setFieldsValue({
                  helpName: help.name
                })
                this.state.helpEditor.setData(help.url)
              })
            editor.setData(help.url)
          } else {
            editor.setData('')
          }
        })
        .catch((error: any) => {
          notificationService.error('', error, false)
        })
    } else {
      this.setState(
        {
          helpEditorActive: !this.state.helpEditorActive,
          editedHelp: help
        },
        () => {
          this.formRef.current &&
          this.formRef.current.setFieldsValue({
            helpName: help.name
          })
          this.state.helpEditor.setData(help.url)
        }
      )
    }

  }

  saveOption = () => {
    const { options, optionsEditor, editedOptionCorrectnes } = this.state

    if (!optionsEditor || isWhitespaceOrEmpty(optionsEditor.getData())) {
      notificationService.error(this.props.t('events.optionEnterDescription'))
      return
    }
    if (this.state.editedOption !== null) {
      options[options.indexOf(this.state.editedOption)] = {
        answer_data: optionsEditor.getData(),
        correct_answer: editedOptionCorrectnes
      }
    } else {
      options.push({
        answer_data: optionsEditor.getData(),
        correct_answer: editedOptionCorrectnes
      })
    }
    this.setState({
      loading: false,
      options
    })
    this.cancelEditing()
  }

  saveHelp = () => {
    const { helps } = this.state
    if (!this.formRef.current) return

    const helpName = this.formRef.current.getFieldValue('helpName')

    if (isWhitespaceOrEmpty(helpName) || isWhitespaceOrEmpty(this.state.helpEditor.getData())) {
      notificationService.error(this.props.t('events.hintEnterNameAndBody'))
      return
    }
    if (this.state.editedHelp !== null) {
      helps[helps.indexOf(this.state.editedHelp)] = {
        name: helpName,
        url: this.state.helpEditor.getData()
      }
    } else {
      helps.push({
        name: helpName,
        url: this.state.helpEditor.getData()
      })
    }
    this.setState({
      helps
    })
    this.setState({ helpEditorActive: !this.state.helpEditorActive })
    this.formRef.current.setFieldsValue({
      helpName: ' '
    })
    this.state.helpEditor.setData('')
  }

  deleteOption = (option: Options) => {
    if (this.state.editedOption === option) {
      this.cancelEditing()
    }
    const optionsToSplice = this.state.options
    const deletedOption = optionsToSplice.splice(optionsToSplice.indexOf(option), 1)
    const optionsToDelete = this.state.optionsToDelete
    if (deletedOption[0].id) {
      optionsToDelete.push(deletedOption[0].id)
    }
    this.setState({
      options: optionsToSplice,
      optionsToDelete
    })
  }

  deleteHelp = (help: Help) => {
    if (this.state.editedHelp === help) {
      this.setState({ editedHelp: null, helpEditorActive: false })
    }
    const helpToSplice = this.state.helps
    const deletedHelp = helpToSplice.splice(helpToSplice.indexOf(help), 1)
    const helpToDelete = this.state.helpsToDelete
    if (deletedHelp[0].id) {
      helpToDelete.push(deletedHelp[0].id)
    }
    this.setState({ helps: helpToSplice, helpsToDelete: helpToDelete })
  }

  editCorrectness = (option: Options) => {
    const { options } = this.state
    const index = options.indexOf(option)
    if (index !== -1) {
      options[index].correct_answer = !options[index].correct_answer
    }
    this.setState({ options })
  }

  cancelEditing = () => {
    if (this.state.optionsEditor) {
      this.state.optionsEditor.destroy()
      this.setState({
        optionsEditorActive: false,
        optionsEditor: null,
        editedOption: null,
        editedOptionCorrectnes: false
      })
    }
  }

  leqValidator(maxValue: number, rejectMessage: string) {
    return (rule: Rule, value: number) => {
      if (value <= maxValue) {
        return Promise.resolve()
      } else {
        return Promise.reject(rejectMessage)
      }
    }
  }

  /*
  valid means the condition is without any issue -> val_xxx[0]
  custom error message -> val_xxx[1]
  partialy valid means the condition can be ignored, if there is at least 1 valid condition -> val_xxx[2]

  the reason for 2 different evaluations is, that we can ignore empty fields if at least 1 is set correctly, but we cannot ignore invalid values or other logic

  valid condition is also partially valid
  partially valid is not valid
  */
  customValidator() {
    return (rule: Rule, value: number) => {
      const ret_err_def = this.props.t('events.eventEnterDuration')
      const val_hour = this.validateHour(ret_err_def)
      const val_min = this.validateMin(ret_err_def)
      const val_sec = this.validateSec(ret_err_def)

      //at least one condition is valid and the rest is partially valid
      if ((val_hour[0] || val_min[0] || val_sec[0]) && (val_hour[2] && val_min[2] && val_sec[2])) {
        return Promise.resolve()
      }

      //if we detected different error than the default, return it
      if (!val_hour[0] && ret_err_def !== val_hour[1]) {
        return Promise.reject(val_hour[1])
      }

      //if we detected different error than the default, return it
      if (!val_min[0] && ret_err_def !== val_min[1]) {
        return Promise.reject(val_min[1])
      }

      //if we detected different error than the default, return it
      if (!val_sec[0] && ret_err_def !== val_sec[1]) {
        return Promise.reject(val_sec[1])
      }

      //return default error msg
      return Promise.reject(ret_err_def)
    }
  }

  validateHour(def_err_msg: string) {
    let ret_val = false
    let ret_parial = false
    let ret_err = def_err_msg

    if (this.formRef.current?.getFieldValue(['time_hour'])) {
      //hour is 100% initialized
      const hour = this.formRef.current.getFieldValue(['time_hour'])
      let min = 0
      let sec = 0

      if (this.formRef.current?.getFieldValue(['time_min'])) {
        min = this.formRef.current.getFieldValue(['time_min'])
      }

      if (this.formRef.current?.getFieldValue(['time_sec'])) {
        sec = this.formRef.current.getFieldValue(['time_sec'])
      }

      if (hour > 5 || (hour + min / 60 + sec / 360) > 5) {
        ret_val = false
        ret_parial = false
        ret_err = this.props.t('events.eventMaxDuration')
      } else {
        ret_val = true
        ret_parial = true
        ret_err = ''
      }
    }
    //empty field
    else {
      ret_parial = true
    }
    return [ret_val, ret_err, ret_parial]
  }

  validateMin(def_err_msg: string) {
    let ret_val = false
    let ret_parial = false
    let ret_err = def_err_msg

    if (this.formRef.current?.getFieldValue(['time_min'])) {
      if (this.formRef.current.getFieldValue(['time_min']) > 59) {
        ret_val = false
        ret_parial = false
        ret_err = this.props.t('events.eventDurationMinutesExceeded')
      } else {
        ret_val = true
        ret_parial = true
        ret_err = ''
      }
    }
    //empty field
    else {
      ret_parial = true
    }

    return [ret_val, ret_err, ret_parial]
  }

  validateSec(def_err_msg: string) {
    let ret_val = false
    let ret_parial = false
    let ret_err = def_err_msg

    if (this.formRef.current?.getFieldValue(['time_sec'])) {
      //the condition can be ignored if we have at least 1 other valid condition and the rest id partialy valid
      if (this.formRef.current.getFieldValue(['time_sec']) < 10) {
        ret_val = false
        ret_parial = true
        ret_err = this.props.t('events.eventMinDuration')
      } else if (this.formRef.current.getFieldValue(['time_sec']) > 59) {
        ret_val = false
        ret_parial = false
        ret_err = this.props.t('events.eventDurationMinutesExceeded')
      } else {
        ret_val = true
        ret_parial = true
        ret_err = ''
      }
    }
    //empty field
    else {
      ret_parial = true
    }
    return [ret_val, ret_err, ret_parial]
  }

  onChange = (item: any) => {
    this.setState(
      {
        optionsEditorNumber: item.target.value
      },
      () => {
        if (this.state.optionsEditorNumber === 3) {
          this.setState({
            optionsEditorActive: false,
            answerEditorActive: true,
            withoutAnswers: false
          })
        } else if (this.state.optionsEditorNumber === 7) {
          this.setState({
            optionsEditorActive: false,
            answerEditorActive: false,
            withoutAnswers: true
          })
        } else if (this.state.optionsEditorNumber === 1) {
          this.setState({
            // optionsEditorActive: false,
            answerEditorActive: false,
            withoutAnswers: false
          })
        }
      }
    )
  }

  renderTextWithMath(message: string) {

    message = message.replaceAll('<p>', '').replaceAll('</p>', '<br>')

    let indexInline, indexBlock
    let iflag = false

    const elements = []

    while (true) {
      indexInline = message.search('<script type="math/tex">')
      indexBlock = message.search('<script type="math/tex; mode=display">')

      if (indexInline !== -1 && indexBlock !== -1) {
        iflag = (indexInline < indexBlock)
      } else if (indexInline !== -1) {
        iflag = true
      } else if (indexBlock !== -1) {
        iflag = false
      } else {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message }} />)
        break
      }

      const endIndex = message.search('</script>')

      // eslint-disable-next-line
      if (iflag == true) {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message.substring(0, indexInline) }} />)
        elements.push(<InlineMath>{message.substring(indexInline + 24, endIndex)}</InlineMath>)
      } else {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message.substring(0, indexBlock) }} />)
        elements.push(<BlockMath>{message.substring(indexBlock + 38, endIndex)}</BlockMath>)
      }
      message = message.substring(endIndex + 9)
    }

    return elements
  }

  componentDidMount() {
    if (this.props.location.state) {
      // load from existing event
      const id = Number(this.props.location.state)

      Promise.all([
        api.events.getEvent(id),
        api.events.getEventOptions(id),
        api.events.getEventHelp(id),
        api.events.getEventTypes(id)
      ])
        .then(response => {

          console.log(response[1].data)
          console.log(typeof (response[0].data.event_type_id))

          this.setState({
            event: {
              event_type_id: 1,
              header: response[0].data.header,
              time_to_explain: 0,
              time_to_handle: 0,
              message: response[0].data.message,
              category_ids: []
            },
            optionsEditorNumber: response[0].data.event_type_id,
            withoutAnswers: (response[0].data.event_type_id !== 1 && response[0].data.event_type_id !== 3),
            answerEditorActive: (response[0].data.event_type_id === 3),
            options: response[1].data,
            helps: response[2].data
          })

          this.formRef.current?.resetFields()

          this.formRef.current?.setFieldsValue({
            'name': response[0].data.header,
            'type': response[0].data.event_type_id,
            'time_sec': response[0].data.time_to_handle % 60,
            'time_min': Math.floor((response[0].data.time_to_handle - (Math.floor(response[0].data.time_to_handle / 3600) * 3600)) / 60),
            'time_hour': Math.floor(response[0].data.time_to_handle / 3600)
          })

          if (this.state.answerEditorActive) {
            this.formRef.current?.setFieldsValue({
              'text_answer': response[1].data[0].answer_data
            })
          }

        })
        .catch(error =>
          notificationService.error(
            this.props.t('events.errorPageLoad'),
            error
          )
        )
    }
  }

  render() {
    const { TextArea } = Input // eslint-disable-line
    const { innerWidth } = window
    const success = this.props.t('events.imageUploadSuccess')
    const unsuccess = this.props.t('events.imageUploadUnsuccess')

    function uploaderPlugin(editor: {
      plugins: {
        get: (arg0: string) => {
          (): any;
          new(): any;
          createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; };
        };
      };
    }) {
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new UploadAdapter(loader, success, unsuccess)
      }
    }

    return (
      <main>
        <h2>{this.props.t('events.assignmentAdd')}</h2>
        <Form
          ref={this.formRef}
          onFinish={this.handleSubmit}
          onFinishFailed={() => console.log(this.formRef.current)}
          layout="vertical"
        >
          <Form.Item
            label={this.props.t('events.assignmentTitle')}
            name="name"
            rules={[
              {
                required: true,
                message: this.props.t('events.assignmentTitleEnter')
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={this.props.t('basic.content')}
            name="text"
            rules={[
              {
                required: true,
                message: this.props.t('events.assignmentContentEnter')
              }
              // TODO: fix this, when uncommented, user can not create event
              // {
              //   validator: (rule, value: string) => {
              //     console.log(value)
              //     if (
              //       !this.state.eventEditor ||
              //       isWhitespaceOrEmpty(this.state.eventEditor.getData())
              //     ) {
              //       Promise.reject('Prosím doplňte obsah úlohy')
              //     } else {
              //       Promise.resolve()
              //     }
              //   }
              // }
            ]}
          >
            <CKEditor
              editor={Editor}
              onInit={(editor: CKEditor) => {
                this.setState({ eventEditor: editor })
                editor.setData(this.state.event.message)
              }}
              config={{
                extraPlugins: [uploaderPlugin],
                mediaEmbed: {
                  previewsInData: true
                },
                language: this.props.i18n.language,
                toolbar: {
                  items: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'link',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'outdent',
                    'indent',
                    '|',
                    'blockQuote',
                    'insertTable',
                    'imageUpload',
                    'mediaEmbed',
                    '|',
                    'math',
                    '|',
                    'undo',
                    'redo'
                  ]
                }
              }}
            />
          </Form.Item>
          {/* ---------------------------------------------------------- */}

          <Form.Item
            label={this.props.t('events.assignmentType')}
            name="type"
            rules={[
              {
                required: true,
                message: this.props.t('events.assignmentTypeEnter')
              }
            ]}
          >
            <Radio.Group onChange={this.onChange}>
              <Radio value={1}>{this.props.t('events.assignmentMultichoice')}</Radio>
              <br />
              <Radio value={3}>{this.props.t('events.assignmentOpentext')}</Radio>
              <br />
              <Radio value={7}>{this.props.t('events.assignmentNoanswer')}</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={this.props.t('events.assignmenstRecommendedTime')}
            name="time"
            rules={[
              {
                validator: this.customValidator()
              }
            ]}
          >
            <Input.Group compact>
              <Form.Item name="time_hour" className="group_item">
                <InputNumber
                  min={0}
                  //max = {5}
                  placeholder="hh"
                  //neviem preco ked dam vo form.item vyssie validateTrigger na "onChange" tak to nerobi, preto to forcujem takto
                  onChange={() => {
                    this.formRef.current?.validateFields(['time'])
                  }} />
              </Form.Item>
              <Form.Item name="time_min" className="group_item">
                <InputNumber
                  min={0}
                  //max = {59}
                  placeholder="mm"
                  //neviem preco ked dam vo form.item vyssie validateTrigger na "onChange" tak to nerobi, preto to forcujem takto
                  onChange={() => {
                    this.formRef.current?.validateFields(['time'])
                  }} />
              </Form.Item>
              <Form.Item name="time_sec" className="group_item">
                <InputNumber
                  min={0}
                  //max = {59}
                  placeholder="ss"
                  //neviem preco ked dam vo form.item vyssie validateTrigger na "onChange" tak to nerobi, preto to forcujem takto
                  onChange={() => {
                    this.formRef.current?.validateFields(['time'])
                  }} />
              </Form.Item>
            </Input.Group>
          </Form.Item>

          {!this.state.withoutAnswers && <h3>{this.props.t('events.assignmentOptions')}</h3>}

          {!!this.state.options.length &&
            !this.state.withoutAnswers &&
            !this.state.answerEditorActive && (
              <List
                size="large"
                loading={this.state.loading}
                itemLayout={innerWidth <= 768 ? 'vertical' : 'horizontal'}
                dataSource={this.state.options}
                renderItem={(item) => (
                  <List.Item
                    key={item.id}
                    actions={[
                      <Checkbox
                        key={item.id}
                        checked={item.correct_answer}
                        onChange={() => {
                          this.editCorrectness(item)
                        }}
                      >
                        {this.props.t('events.assignmentCorrectAnswer')}
                      </Checkbox>,
                      <div key={item.id}>
                        {!this.state.optionsEditorActive && (
                          <button
                            className="link-button"
                            onClick={() => this.addOptionCKEditor(item)}
                            type="button"
                          >
                            {this.props.t('basic.edit')}
                          </button>
                        )}
                      </div>,
                      <button
                        className="link-button"
                        key={item.id}
                        onClick={() => this.deleteOption(item)}
                        type="button"
                      >
                        {this.props.t('basic.delete')}
                      </button>
                    ]}
                  >
                    <List.Item.Meta
                      description={<div> {this.renderTextWithMath(item.answer_data)} </div>}
                    />
                  </List.Item>
                )}
              />
            )}

          <Card style={this.state.optionsEditorActive ? { display: 'block' } : { display: 'none' }}>
            <Form.Item
              label={this.props.t('events.assignmentNewOption')}
              name="text"
              rules={[
                {
                  required: true,
                  message: this.props.t('events.assignmentNewOptionBody')
                }
              ]}
            >
              <textarea id="txt_area" rows={10} />
            </Form.Item>
            <Form.Item label="" name="correct_answer">
              <Checkbox
                checked={this.state.editedOptionCorrectnes}
                onChange={() =>
                  this.setState({
                    editedOptionCorrectnes: !this.state.editedOptionCorrectnes
                  })
                }
              >
                {this.props.t('events.correct')}
              </Checkbox>
            </Form.Item>
          </Card>

          {/* #####-----AKTIVACIA ODPOVEDI-----##### */}

          {/* Otázka s výberom odpovede */}
          {this.state.optionsEditorActive ? (
              <div className="row">
                <Button
                  className="EventCreation__button"
                  onClick={() => {
                    this.cancelEditing()
                  }}
                >
                  {this.props.t('basic.cancel')}
                </Button>
                <Button className="EventCreation__button" onClick={() => this.saveOption()}>
                  {this.props.t('events.optionAdd')}
                </Button>
              </div>
            ) : // TEXTOVA
            this.state.answerEditorActive ? (
                <div>
                  <Form.Item
                    label={this.props.t('events.assignmentCorrectAnswer')}
                    name="text_answer"
                    rules={[
                      {
                        required: true,
                        message: this.props.t('events.assignmentCorrectAnswerEnter')
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              ) : // BEZ ODPOVEDE
              this.state.withoutAnswers ? (
                <div />
              ) : (
                // PRIDAT ODPOVED
                <Button
                  className="EventCreation__button"
                  onClick={() => {
                    if (this.state.optionsEditorNumber === 1) {
                      this.addOptionCKEditor()
                    }
                  }}
                >
                  {this.props.t('events.optionAddLong')}
                </Button>
              )}
          {/* ---------------------------------------------------------------------------- */}

          <h3>{this.props.t('events.hints')}</h3>
          {!!this.state.helps.length && (
            <List
              size="large"
              loading={this.state.loading}
              itemLayout={innerWidth <= 768 ? 'vertical' : 'horizontal'}
              dataSource={this.state.helps}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <div key={item.id}>
                      {!this.state.helpEditorActive && (
                        <button
                          className="link-button"
                          onClick={() => this.editHelp(item)}
                          type="button"
                        >
                          {this.props.t('basic.edit')}
                        </button>
                      )}
                    </div>,
                    <button
                      key={item.id}
                      className="link-button"
                      onClick={() => this.deleteHelp(item)}
                      type="button"
                    >
                      {this.props.t('basic.delete')}
                    </button>
                  ]}
                >
                  <List.Item.Meta title={item.name} description={this.renderTextWithMath(item.url)} />
                </List.Item>
              )}
            />
          )}

          <Card style={this.state.helpEditorActive ? { display: 'block' } : { display: 'none' }}>
            <Form.Item
              label={this.props.t('events.hintTitle')}
              name="helpName"
              rules={[
                {
                  required: false,
                  message: this.props.t('events.hintBodyEnter')
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={this.props.t('events.hintDescription')}
              name="url"
              rules={[
                {
                  required: false,
                  message: this.props.t('events.enterHintDescription')
                }
              ]}
            >
              <textarea id="help_area" rows={10} />
            </Form.Item>
            <div className="row">
              <Button
                className="EventCreation__button"
                onClick={() => {
                  this.setState({
                    editedHelp: null,
                    helpEditorActive: false
                  })
                }}
              >
                {this.props.t('basic.cancel')}
              </Button>
              <Button className="EventCreation__button" onClick={() => this.saveHelp()}>
                {this.props.t('events.hintAdd')}
              </Button>
            </div>
          </Card>

          {this.state.helpEditorActive ? null : (
            <Button
              className="EventCreation__button"
              onClick={() => this.addHelpCKEditor()}
            >
              {this.props.t('events.hintAdd')}
            </Button>
          )}

          <br />

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {this.props.t('events.assignmentAddNow')}
            </Button>
          </Form.Item>
        </Form>
      </main>
    )
  }
}

class UploadAdapter {
  private loader: any
  private success: string
  private unsuccess: string

  constructor(loader: { file: Promise<any>; }, success: string, unsuccess: string) {
    // The file loader instance to use during the upload.
    this.loader = loader
    this.success = success
    this.unsuccess = unsuccess
  }

  upload() {
    return new Promise((resolve, reject) => {
      const body = new FormData()
      this.loader.file.then((file: string | Blob) => {
        body.append('image', file)
        api.images
          .uploadImage(body)
          .then((success) => {
            if (success.data.id) {
              notificationService.success(this.success)
              resolve({ default: `${apiBaseUrl}/storage/`.concat(success.data.image.replace('public/', '')) })
            } else {
              reject('err')
              throw Error('[ERROR] IMAGE UPLOAD - ImageUpload missing')
            }
          })
          .catch((error) => {
            notificationService.error(this.unsuccess, error)
            reject(error)
          })
      })
    })
  }
}

export default withTranslation()(withRouter(EventCreationComponent))
