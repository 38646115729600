import { withRouter, RouteComponentProps } from 'react-router-dom'
import React, { Component } from 'react'

/* Css */
import './SectionProjectOverview.scss'

/* i18n */
import { WithTranslation, withTranslation } from 'react-i18next'
import MainCard from '../MainCard/MainCard'


interface IProps extends RouteComponentProps, WithTranslation {
}

interface IState {
  selectedLanguage: string;
}

class SectionProjectOverview extends Component<IProps, IState> {
  render() {
    const { t } = this.props
    const overviewItems = t('titlePage.projectOverviewSection.overviewItems', { returnObjects: true }) as Array<any>
    return (
      <MainCard title={t('titlePage.projectOverviewSection.title')}>
        <div className="eceh-project-overview__information-container">
          {overviewItems.map(({ title, description }) => (
            <div key={title}>
              <h3 className="eceh-project-overview__information-title">{title}</h3>
              <p className="eceh-project-overview__information-description">{description}</p>
            </div>
          ))}
        </div>

        <video className="eceh-project-overview__video" src="/videos/how-it-looks-inside-eceh.mp4" controls />
      </MainCard>
    )
  }
}

export default withTranslation()(withRouter(SectionProjectOverview))