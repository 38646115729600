import React from 'react'
import { Typography, Descriptions } from 'antd'
//@ts-ignore
import { InlineMath } from 'react-katex'

//css
import './MathTex.css'

const { Title } = Typography

// page with Latex Math Explanation
const MathTex: React.FunctionComponent = () => {
  return (
    <div className="wrapper" style={{ margin: 20 }}>
      <Title level={2}>Vkladanie matematických zápisov</Title>
      <div style={{ margin: 40 }}>

        <Descriptions title="Vzorové zápisy" bordered column={2}>

          <Descriptions.Item>
            <InlineMath>{'x_{1,2} = \\frac{-b \\pm \\sqrt{D}}{2a}'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'x_{1,2} = \\frac{-b \\pm \\sqrt{D}}{2a}'}
            </code>
          </Descriptions.Item>


        </Descriptions>

        <br />

        <Descriptions title="Základné znaky" bordered column={2}>

          <Descriptions.Item>
            <InlineMath>{'a\''}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'a\''}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'a\'\''}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'a\'\''}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\tilde{a}'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\tilde{a}'}
            </code>
          </Descriptions.Item>


          <Descriptions.Item>
            <InlineMath>{'\\overleftarrow{AB}'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\overleftarrow{AB}'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\underrightarrow{AB}'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\underrightarrow{AB}'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'( )'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'( )'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'[ ]'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'[ ]'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\{ \\}'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\{ \\}'}
            </code>
            <span> alebo </span>
            <code>
              {'\\lbrace \\rbrace'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\langle \\rangle'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'⟨ ⟩'}
            </code>
            <span> alebo </span>
            <code>
              {'\\langle \\rangle'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\vert'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\vert'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\Vert'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\Vert'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\backslash'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\backslash'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\big('}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\big('}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\Big('}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\Big('}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\bigg('}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\bigg('}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\Bigg('}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\Bigg('}
            </code>
          </Descriptions.Item>

        </Descriptions>

        <br />

        <Descriptions title="Matice" bordered column={2}>

          <Descriptions.Item>
            <InlineMath>{'\\begin{matrix} a & b \\\\ c & d \\end{matrix}'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\begin{matrix}'}
              <br />
              {'a & b \\\\'}
              <br />
              {'c & d'}
              <br />
              {'\\end{matrix}'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\begin{pmatrix} a & b \\\\ c & d \\end{pmatrix}'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\begin{pmatrix}'}
              <br />
              {'a & b \\\\'}
              <br />
              {'c & d'}
              <br />
              {'\\end{pmatrix}'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\begin{bmatrix} a & b \\\\ c & d \\end{bmatrix}'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\begin{bmatrix}'}
              <br />
              {'a & b \\\\'}
              <br />
              {'c & d'}
              <br />
              {'\\end{bmatrix}'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\begin{vmatrix} a & b \\\\ c & d \\end{vmatrix}'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\begin{vmatrix}'}
              <br />
              {'a & b \\\\'}
              <br />
              {'c & d'}
              <br />
              {'\\end{vmatrix}'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\begin{Bmatrix} a & b \\\\ c & d \\end{Bmatrix}'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\begin{Bmatrix}'}
              <br />
              {'a & b \\\\'}
              <br />
              {'c & d'}
              <br />
              {'\\end{Bmatrix}'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'x = \\begin{cases} a &\\text{if } b \\\\ c &\\text{if } d \\end{cases}'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'x = \\begin{cases}'}
              <br />
              {'a &\\text{if } b \\\\'}
              <br />
              {'c &\\text{if } d'}
              <br />
              {'\\end{cases}'}
            </code>
          </Descriptions.Item>

        </Descriptions>

        <br />

        <Descriptions title="Grécke písmená" bordered column={2}>

          <Descriptions.Item>
            <InlineMath>{'\\Alpha'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\Alpha'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\Beta'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\Beta'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\Gamma'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\Gamma'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\Delta'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\Delta'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\Theta'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\Theta'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\Sigma'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\Sigma'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\Pi'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\Pi'}
            </code>
          </Descriptions.Item>

          <Descriptions.Item>
            <InlineMath>{'\\Phi'}</InlineMath>
          </Descriptions.Item>
          <Descriptions.Item>
            <code>
              {'\\Phi'}
            </code>
          </Descriptions.Item>

        </Descriptions>
      </div>
    </div>
  )
}

export default MathTex
