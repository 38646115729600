import React from 'react'

class ErrorBoundary extends React.Component {
  state = {
    error: null
  }

  static getDerivedStateFromError(error) {
    // Update state so next render shows fallback UI.
    return {error: error}
  }

  componentDidCatch(error, info) {
    // Log the error to an error reporting service
    // sentry?
  }

  render() {
    if (this.state.error) {
      window.history.pushState({}, '', '/')
    }
    return this.props.children
  }
}

export default ErrorBoundary
