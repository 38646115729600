import React from 'react'
import { Link } from 'react-router-dom'
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons'
import '@ant-design/compatible/assets/index.css'
import { Input, Button, Checkbox, Form, Select, Tooltip } from 'antd'

//other
import api from '../../../../utils/ApiAxios'
import { AuthContext } from '../../../../context/Auth'
import notification from '../../../../utils/notificationService'
import { Store } from 'antd/lib/form/interface'
import { Rule, FormInstance } from 'antd/lib/form'
import { RefObject } from 'react'
import User from '../../../../models/user'
import Organization from '../../../../models/organization'
import LocalStorageAppState from '../../../../models/appState'
import { LabeledValue } from 'antd/lib/tree-select'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'

const { Option } = Select

interface IProps extends WithTranslation {
  handleOk: () => void;
}

interface IState {
  confirmPrivatePolicy: boolean;
  confirmDirty: boolean;
  organizationTreeData: LabeledValue[];
  organizationTreeValue: LabeledValue[];
  hasOrganisation: boolean;
  selectedOrganisation: number;
  inputName: string;
  inputLastname: string;
  inputEmail: string;
}

class RegistrationForm extends React.Component<IProps, IState> {

  private formRef: RefObject<FormInstance> = React.createRef()

  constructor(props: IProps) {
    super(props)
    this.state = {
      confirmPrivatePolicy: false,
      confirmDirty: false,
      organizationTreeData: [],
      organizationTreeValue: [],
      hasOrganisation: false,
      selectedOrganisation: -1,
      inputName: '',
      inputLastname: '',
      inputEmail: ''
    }
  }

  static contextType = AuthContext

  componentDidMount() {
    this.setNameEmail();
    api.organizations
      .getAllOrganizations()
      .then(response => {
        const data = response.data.reduce(
          (result: LabeledValue[], organization: Organization) => {
            if (organization.name && organization.id) {
              result.push({ label: organization.name, value: organization.id })
            }
            return result
          },
          []
        )
        this.setState({
          organizationTreeData: data
        })
      })
  }

  setNameEmail() {
    const url = window.location.href.split('?')[1];
    api.auth
      .decode({
        url: url
      })
      .then(response => {
        this.setState({ inputName: response.data.name });
        this.setState({ inputLastname: response.data.last_name });
        this.setState({ inputEmail: response.data.email });
      })
  }

  handlePrivacyPolicy = () => {
    this.setState({
      confirmPrivatePolicy: !this.state.confirmPrivatePolicy
    })
  }

  handleOrganisationSelect = (e: number) => {
    this.setState({
      selectedOrganisation: e
    }, () => {
      let temp
      if (this.state.selectedOrganisation > 1) {
        temp = true
      } else {
        temp = false
        if (this.formRef.current) {
          this.formRef.current?.resetFields(['class'])
        }
      }

      this.setState({
        hasOrganisation: temp
      }, () => {
        if (this.formRef.current) {
          this.formRef.current.validateFields(['class'])
        }
      })
    })
  }

  // handle click on registration button
  handleRegistrationSubmit = (values: Store) => {

    //handle no class info
    if (values.class === undefined) {
      values.class = null
    }

    api.auth
      .register({
        email: values.email,
        password: values.password,
        c_password: values.c_password,
        confirm_private_policy: values.confirm_private_policy,
        firstname: values.firstname,
        lastname: values.lastname,
        organization_id: values.organization,
        class: values.class
      })
      // login after succes registration
      .then(response => {
        const user: User = {
          token: response.data.success.token,
          name: response.data.success.name,
          role_id: response.data.success.role_id,
          role: response.data.success.role,
          email: response.data.success.email, // undefined kriste ten BE je kokotny
          id: response.data.user_id
        }

        const appState: LocalStorageAppState = {
          isLoggedIn: true,
          user,
          loginTimestamp: new Date().toString()
        }

        // save app state with user date in local storage
        localStorage.appState = JSON.stringify(appState)

        this.context.reloadAuthStateFromLocalStorageAndSetApiAuthorizationToken()
        notification.success(this.props.t('login.registrationSuccessful'))
        this.props.handleOk()
      })
      .catch(error => {
        notification.error(
          this.props.t('login.registrationError'),
          error
        )
      })
  }

  // chceck if user agreed to the privacy policy
  checkPrivacyPolicy = (rule: Rule, value: boolean) => {
    if (value) {
      return Promise.resolve()
    } else {
      return Promise.reject(this.props.t('login.policyAgreement'))
    }
  }

  // check if user with same email isn't registred yet
  checkIfEmailExists = async (rule: Rule, value: string) => {
    try {
      await api.auth.checkIfEmailExists({ email: value })
      return Promise.resolve()

    } catch (err) {
      return Promise.reject(this.props.t('login.emailExists'))
    }
  }

  // compare password
  compareToFirstPassword = (rule: Rule, value: string) => {
    if (value && this.formRef.current && value !== this.formRef.current.getFieldValue('password')) {
      return Promise.reject(this.props.t('login.passwordsNonidentical'))
    } else {
      return Promise.resolve()
    }
  }

  // comapre password
  validateToNextPassword = (rule: Rule, value: string) => {
    if (value && this.formRef.current && this.state.confirmDirty) {
      this.formRef.current.validateFields(['c_password'])
    }
    return Promise.resolve()
  }

  render() {

    return (
      <>
        <Form
          onFinish={this.handleRegistrationSubmit}
          className="login-form"
          style={{ paddingTop: 20, width: '50%', margin: '0 auto' }}
          //how the fuck vsetky validations fungovali bez tohto...
          ref={this.formRef}
          fields={[
            {
              name: ["firstname"],
              value: this.state.inputName
            },
            {
              name: ["lastname"],
              value: this.state.inputLastname
            },
            {
              name: ["email"],
              value: this.state.inputEmail
            },
          ]}
        >
          <Form.Item
            name="firstname"
            rules={[
              {
                required: true,
                message: this.props.t('login.enterName'),
                whitespace: true
              }
            ]}
          >
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={this.props.t('login.name')}
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            name="lastname"
            rules={[
              {
                required: true,
                message: this.props.t('login.enterLastname'),
                whitespace: true
              }
            ]}
          >
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={this.props.t('login.lastname')}
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            name="email"
            validateFirst={true}
            validateTrigger="onBlur"
            rules={[
              {
                type: 'email',
                message: this.props.t('login.emailWrongFormat')
              },
              {
                required: true,
                message: this.props.t('login.emailEnter')
              },
              {
                validator: this.checkIfEmailExists
              }
            ]}
          >
            <Input
              prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            name="organization"
            validateFirst={true}
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: this.props.t('login.organizationSelect')
              }
            ]}
          >
            <Select
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(input.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
              }
              showSearch
              placeholder={this.props.t('login.organization')}
              onChange={(e) => {
                this.handleOrganisationSelect(e as number)
              }}
            >
              options={this.state.organizationTreeData.map(item => (
              <Option
                value={item.value}
                key={item.value}
                label={item.label}
              >
                <Tooltip title={item.label}>
                  <p>
                    {item.label}
                  </p>
                </Tooltip>

              </Option>
            ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="class"
            validateFirst={true}
            validateTrigger="onChange"
            rules={[
              {
                required: this.state.hasOrganisation,
                message: this.props.t('login.classEnter')
              },
              {
                max: 5,
                message: this.props.t('login.classRequirements')
              }
            ]}
          >
            <Input
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={this.props.t('login.class')}
              disabled={!this.state.hasOrganisation}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: this.props.t('login.passwordEnter')
              },
              {
                min: 6,
                message: this.props.t('login.passwordRequirements')
              },
              {
                validator: this.validateToNextPassword
              }
            ]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder={this.props.t('login.password')}
            />
          </Form.Item>
          <Form.Item
            name="c_password"
            rules={[
              {
                required: true,
                message: this.props.t('login.passwordReEnter')
              },
              {
                min: 6,
                message: this.props.t('login.passwordRequirements')
              },
              {
                validator: this.compareToFirstPassword
              }
            ]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder={this.props.t('login.passwordRepeat')}
            />
          </Form.Item>
          <Form.Item
            name="confirm_private_policy"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: ' '
              },
              {
                validator: this.checkPrivacyPolicy
              }
            ]}
          >
            <Checkbox onChange={this.handlePrivacyPolicy}>
              {this.props.t('login.policyAgreed')},{' '}
              <Link target="_blank" to="/privatepolicy">
                {this.props.t('login.moreInfo')}
              </Link>
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              {this.props.t('login.registerMe_mail')}
            </Button>
          </Form.Item>
        </Form>
      </>
    )
  }
}

export default withTranslation()(RegistrationForm)
