import React from 'react'
import {
  CheckCircleOutlined,
  LogoutOutlined,
  RightCircleOutlined,
  InfoCircleOutlined
} from '@ant-design/icons'
import { Button, Card, Row, Col, Typography, Result, Input, Collapse, List, Tag } from 'antd'

import api from '../../../utils/ApiAxios'
import notification from '../../../utils/notificationService'
import TestOption from './TestOption/TestOption'
import './TestContainer.css'
import Loading from '../../common/Loading/Loading'
import { MenuState } from '../../common/HeaderAndMenu/HeaderAndMenu'
import { RouteComponentProps } from 'react-router'
import { Event, Option, Unit } from '../../../models'
import notificationService from '../../../utils/notificationService'
import { AuthContext } from '../../../context/Auth'
//@ts-ignore
import { InlineMath, BlockMath } from 'react-katex'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'

const { Title } = Typography
const { Panel } = Collapse
const ButtonGroup = Button.Group

interface IProps extends RouteComponentProps<RouteParams>, WithTranslation {
  menuState: MenuState;
  handleExitTestFromLastQuestion: () => void;
  handleExitTestResults: () => void;
}

interface RouteParams {
  activityId: string;
  unitId: string;
}

interface IState {
  testEnded: boolean;
  events: Event[];
  index: number; // index on which event i am
  isLoading: boolean;
  isAnswered: boolean;
  unit: Unit | null;
  activityId: number;
  currentSelectedOptions: number[];
  answeredQuestions: number;
  answeredOptions: {
    rightSelected: number;
    rightNotSelected: number;
    wrongSelected: number;
    wrongNotSelected: number;
    notFullyCorrectAnswers: number;
  };
  userAnswer: string;
  questionColHeight: number;
  progress: number[];
  activeKey: string;
  start_time: Date;
  unit_time: Date;
}

class TestContainer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      events: [],
      unit: null,
      // index_order: [],
      index: 0,
      activityId: 0,
      isLoading: false,
      isAnswered: false,
      currentSelectedOptions: [],
      questionColHeight: 300,
      answeredQuestions: 0,
      answeredOptions: {
        rightSelected: 0,
        rightNotSelected: 0,
        wrongSelected: 0,
        wrongNotSelected: 0,
        notFullyCorrectAnswers: 0
      },
      userAnswer: '',
      testEnded: this.props.menuState.testEnded,
      progress: [],
      activeKey: '',
      start_time: new Date(),
      unit_time: new Date(),
    }    
  }

  componentWillUnmount() {
    if (!this.state.testEnded) {
      localStorage.setItem('informativeTestState', JSON.stringify(this.state))
    }

    this.context.setCurrentUserState({ unitId: 0, activityId: 0 })
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.menuState.testEnded !== prevProps.menuState.testEnded) {
      if (this.props.menuState.testEnded) {
        this.endTest()
      }
    }
  }

  componentDidMount() {
    const stringifiedLastTestState = localStorage.getItem('informativeTestState')
    if (stringifiedLastTestState !== null) {
      const lastTestState: IState = JSON.parse(stringifiedLastTestState)
      this.setState(lastTestState)
      localStorage.removeItem('informativeTestState')
    }

    const unitId = parseInt(this.props.match.params.unitId)
    const activityId = parseInt(this.props.match.params.activityId)

    // set actual activity and test ids
    this.context.setCurrentUserState({ unitId, activityId })

    // inform server that user opened the information testing
    api.auth.testingStarted({ unit_id: unitId, activity_id: activityId })
    this.setState({start_time: new Date()})

    if (!this.state.isLoading) {
      Promise.all([
        api.units.getUnit(unitId),
        api.units.getUnitEvents(unitId),
        api.activities.getUserProgress(activityId, unitId)
      ])
        .then(async ([unit, events, progress]) => {
          const data: number[] = progress.data
          let reorderedEvents: Event[] = []
          const eventsDatatmp: Event[] = this.randomEvents(events.data)
          eventsDatatmp.forEach((element) => {
            if (element.event_type_id !== 7) {
              reorderedEvents.push(element)
            }
          })
          const eventsOptions = await Promise.all(
            reorderedEvents.map((event) => api.events.getEventOptions(event.id))
          )
          const eventsHelps = await Promise.all(
            reorderedEvents.map((event) => api.events.getEventHelp(event.id))
          )
          reorderedEvents = reorderedEvents.map((event, index) => ({
            ...event,
            options: this.randomEvents(eventsOptions[index].data),
            help: eventsHelps[index].data
          }))
          this.setState({
            activityId: activityId,
            unit: unit.data,
            events: reorderedEvents,
            isLoading: true,
            progress: data
          })
        })
        .catch((error) =>
          notification.error(
            this.props.t('test.loadError'),
            error
          )
        )
    }
  }

  renderTextWithMath(message: string) {

    message = message.replaceAll('<p>', '').replaceAll('</p>', '<br>')

    let indexInline, indexBlock
    let iflag = false

    const elements = []

    while (true) {
      indexInline = message.search('<script type="math/tex">')
      indexBlock = message.search('<script type="math/tex; mode=display">')

      if (indexInline !== -1 && indexBlock !== -1) {
        iflag = (indexInline < indexBlock)
      } else if (indexInline !== -1) {
        iflag = true
      } else if (indexBlock !== -1) {
        iflag = false
      } else {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message }} />)
        break
      }

      const endIndex = message.search('</script>')

      // eslint-disable-next-line
      if (iflag == true) {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message.substring(0, indexInline) }} />)
        elements.push(<InlineMath>{message.substring(indexInline + 24, endIndex)}</InlineMath>)
      } else {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message.substring(0, indexBlock) }} />)
        elements.push(<BlockMath>{message.substring(indexBlock + 38, endIndex)}</BlockMath>)
      }
      message = message.substring(endIndex + 9)
    }

    return elements
  }

  randomEvents = (array: any[]) => {
    let i = array.length - 1
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      const temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    return array
  }

  handleSetSelectedOption = (id?: number) => () => {
    const selectedOptionsToModify = this.state.currentSelectedOptions
    if (id !== undefined && this.state.currentSelectedOptions.includes(id)) {
      selectedOptionsToModify.splice(this.state.currentSelectedOptions.indexOf(id), 1)
    } else if (id) {
      selectedOptionsToModify.push(id)
    }
    this.setState({
      currentSelectedOptions: selectedOptionsToModify
    })
  }

  onChange = (answer: any) => {
    this.setState({
      userAnswer: answer.target.value
    })
  }

  countAnswers = () => {
    const actualAnswered = this.state.answeredQuestions
    this.setState({ answeredQuestions: actualAnswered + 1 })
    const selOptions = this.state.currentSelectedOptions
    let {
      rightSelected,
      wrongSelected,
      wrongNotSelected,
      rightNotSelected
    } = this.state.answeredOptions
    const { notFullyCorrectAnswers } = this.state.answeredOptions

    const { events, index } = this.state
    const options = events[index].options
    const eventId = events[index].id
    if (options) {
      let notCorrect = false
      options.forEach((element) => {
        if (element.id) {
          if (element.correct_answer) {
            if (selOptions.includes(element.id)) {
              //mozno tu
              rightSelected++
            } else {
              rightNotSelected++
              notCorrect = true
            }
          } else {
            if (selOptions.includes(element.id)) {
              wrongSelected++
              notCorrect = true
            } else {
              wrongNotSelected++
            }
          }
        }
      })

      if (!notCorrect) {
        const progress = this.state.progress
        if (eventId) progress.push(eventId)
        this.setState({
          progress: progress
        })
        api.activities.assignUserProgress(this.state.activityId, this.state.unit?.id, progress)
      }

      this.setState({
        answeredOptions: {
          rightSelected,
          rightNotSelected,
          wrongSelected,
          wrongNotSelected,
          notFullyCorrectAnswers: notCorrect ? notFullyCorrectAnswers + 1 : notFullyCorrectAnswers
        }
      })
    } else {
      notificationService.error(
        this.props.t('test.initAnswerError'),
        `missing options on event ${events[index].id}`
      )
    }
  }

  countTextQuestion = (right: boolean) => {
    let actualAnswered = this.state.answeredQuestions
    let { rightSelected, wrongSelected } = this.state.answeredOptions
    const {
      wrongNotSelected,
      rightNotSelected,
      notFullyCorrectAnswers
    } = this.state.answeredOptions

    if (right) {
      const { events, index } = this.state
      const eventId = events[index].id
      const progress = this.state.progress
      if (eventId) progress.push(eventId)
      this.setState({
        progress: progress
      })
      api.activities.assignUserProgress(this.state.activityId, this.state.unit?.id, progress)
      rightSelected++
      actualAnswered++
    } else {
      wrongSelected++
    }

    this.setState({
      answeredOptions: {
        rightSelected,
        rightNotSelected,
        wrongSelected,
        wrongNotSelected,
        notFullyCorrectAnswers
      },
      answeredQuestions: actualAnswered,
      userAnswer: ''
    })
  }

  handlePageChange = () => {
    const { events, index } = this.state
    const event = events[index]
    const { options } = event
    const ua = this.state.userAnswer

    const event_type = event['event_type_id']

    if (event_type === 3) {
      if (options && options[0] && ua === options[0].answer_data) {
        this.countTextQuestion(true)
      } else {
        this.countTextQuestion(false)
      }
    } else {
      this.countAnswers()
    }

    this.setState({
      index: this.state.index + 1,
      isAnswered: false,
      activeKey: '',
      currentSelectedOptions: []
    })
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  handleCollapse = () => {
    const tmp = this.state.activeKey

    if (tmp) {
      this.setState({
        activeKey: ''
      })
    } else {
      this.setState({
        activeKey: 'task-hints'
      })
    }
  }

  renderQuestion() {
    const { events, index } = this.state
    const event = events[index]
    const { options, header, message } = event
    const { isAnswered } = this.state
    const menuState = this.props.menuState

    let nextQuestionButton
    let doneTag

    const event_type = event['event_type_id']

    if (index + 1 === events.length) {
      nextQuestionButton = (
        <Button
          style={{ margin: 0 }}
          onClick={this.props.handleExitTestFromLastQuestion}
          disabled={!isAnswered}
        >
          {this.props.t('test.displayResults')}
          <LogoutOutlined />
        </Button>
      )
    } else {
      nextQuestionButton = (
        <Button style={{ margin: 0 }} onClick={this.handlePageChange} disabled={!isAnswered}>
          <RightCircleOutlined />
          {this.props.t('test.nextQuestion')}
        </Button>
      )
    }

    const checkButton = (
      <Button type="primary" onClick={this.handleAnswer} disabled={isAnswered}>
        <CheckCircleOutlined />
        {this.props.t('test.evaluate')}
      </Button>
    )

    const hint = (
      <Collapse
        className="border-bottom-collapse-header"
        ghost
        style={{ margin: 5 }}
        onChange={this.handleCollapse}
        activeKey={this.state.activeKey}
      >
        <Panel header={<Title level={4}>{this.props.t('test.hints')}</Title>} key="task-hints">
          <List
            dataSource={event.help}
            renderItem={(item) => (
              <List.Item>{this.renderTextWithMath(item.url)}</List.Item>
            )}
          />
        </Panel>
      </Collapse>
    )

    if (event.id && this.state.progress.includes(event.id)) {
      doneTag = (
        <Tag color={'green'} style={{ marginLeft: 10 }}>
          <CheckCircleOutlined style={{ marginRight: 5 }} />
          {this.props.t('test.questionAlreadeAnswered')}
        </Tag>
      )
    } else {
      doneTag = <div />
    }

    let questionBox
    if (menuState.showHelps) {
      questionBox = (
        <Card className="rounded-bottom">
          <Row>
            <Col md={24} lg={16} xl={18}>
              <Row>
                <Col span={24}>{this.renderOptions(options)}</Col>
              </Row>
              <Row justify="end">
                <Col>
                  <ButtonGroup style={{ margin: 5 }}>
                    {checkButton}
                    {nextQuestionButton}
                  </ButtonGroup>
                </Col>
              </Row>
            </Col>
            <Col md={24} lg={8} xl={6}>
              <Card
                title={this.props.t('test.hints')}
                type="inner"
                className="help-card rounded"
                headStyle={{
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  backgroundColor: 'rgb(240,242,245, 1.0)'
                }}
                bodyStyle={{
                  minHeight: this.state.questionColHeight,
                  maxHeight: this.state.questionColHeight + 100,
                  overflow: 'auto',
                  padding: 0
                }}
              >
                {hint}
              </Card>
            </Col>
          </Row>
        </Card>
      )
    } else {
      if (event_type === 3) {
        const initValue = this.state.userAnswer

        questionBox = (
          <Card className="rounded-bottom">
            <Row>
              <Input onChange={this.onChange} value={initValue} disabled={isAnswered} />
            </Row>
            <Row justify="end">
              <Col style={{ paddingRight: 10 }}>
                {checkButton}
                {nextQuestionButton}
              </Col>
            </Row>
          </Card>
        )
      } else {
        questionBox = (
          <Card className="rounded-bottom">
            <Row>
              <Col span={24}>{this.renderOptions(options)}</Col>
            </Row>
            <Row justify="end">
              <Col style={{ paddingRight: 10 }}>
                {checkButton}
                {nextQuestionButton}
              </Col>
            </Row>
          </Card>
        )
      }
    }

    return (
      <>
        <Card
          className="rounded-top"
          bodyStyle={{ padding: 10, paddingLeft: 24 }}
          style={{ marginBottom: 5 }}
        >
          <Title level={3}>
            {header}
            {doneTag}
          </Title>
          <div className="left-space">
            {this.renderTextWithMath(message)}
          </div>
        </Card>
        {questionBox}
        {hint}
      </>
    )
  }

  renderOptions(options?: Option[]) {
    const { currentSelectedOptions } = this.state

    return (
      options &&
      options.map((option) => {
        const { id } = option || {}
        const isSelected = id !== undefined && currentSelectedOptions.includes(id)
        const { isAnswered } = this.state
        if (isAnswered) {
          return (
            <TestOption key={id} isSelected={isSelected} isAnsweredQ={isAnswered} option={option} />
          )
        } else {
          return (
            <TestOption
              key={id}
              isSelected={isSelected}
              isAnsweredQ={isAnswered}
              onClick={this.handleSetSelectedOption(id)}
              option={option}
            />
          )
        }
      })
    )
  }

  handleAnswer = () => {
    const { currentSelectedOptions, index, events, unit, activityId } = this.state
    const eventOptions = events[index].options

    const answersCorrectness = currentSelectedOptions.map((currentSelectedOption) => {
      if (eventOptions !== undefined) {
        const option = eventOptions.find((option) => option.id === currentSelectedOption)
        if (option?.correct_answer === undefined) {
          return false
        }
        return option.correct_answer
      }
      return false
    })

    let answerIsCorrect =
      answersCorrectness.length > 0 ? answersCorrectness.every((answer) => answer) : false

    //Open question and question without correct option evaluation
    if (eventOptions !== undefined && answersCorrectness.length === 0) {
      const allAreFalse = eventOptions.every((item) => item.correct_answer === false)
      if (allAreFalse) answerIsCorrect = true
      if (eventOptions[0].answer_data === this.state.userAnswer) answerIsCorrect = true
    }
    var elapsed_time = new Date();
    if (unit?.id !== undefined) {
      api.events.sendEventProgress({
        unit_id: unit.id,
        activity_id: activityId,
        correct_answer: answerIsCorrect,
        unit_time: Math.floor( elapsed_time.getTime() - this.state.start_time.getTime()),
      })
    }

    this.setState({ isAnswered: true })
  }

  endTest = () => {
    localStorage.removeItem('informativeTestState')
    this.setState({
      testEnded: true,
    })
    const { events, index } = this.state
    const event = events[index]
    const { options } = event
    const ua = this.state.userAnswer

    const event_type = event['event_type_id']
    if (event_type === 3) {
      if (options && options[0] && ua === options[0].answer_data) {
        this.countTextQuestion(true)
      } else {
        this.countTextQuestion(false)
      }
    } else {
      this.countAnswers()
    }
  }

  handleResultEnd = () => {
    this.props.handleExitTestResults()
  }

  renderResults() {
    localStorage.removeItem('informativeTestState')
    const events = this.state.events
    const { notFullyCorrectAnswers } = this.state.answeredOptions
    const answeredQuestions = this.state.answeredQuestions
    const optionsRight =
      events.length - (notFullyCorrectAnswers + (events.length - answeredQuestions))
    let finalPercentage = 0
    if (optionsRight > 0) {
      finalPercentage = (optionsRight * 100) / events.length
    }

    let color
    if (finalPercentage < 35) {
      color = 'result_red'
    } else {
      if (finalPercentage < 70) {
        color = 'result_orange'
      } else {
        color = 'result_green'
      }
    }
    return (
      <Card
        className="rounded"
        bodyStyle={{ padding: 10, paddingLeft: 24 }}
        style={{ marginBottom: 5 }}
      >
        <Title level={3}>{this.props.t('test.results')}</Title>
        <Result
          icon={<InfoCircleOutlined />}
          title="Testovanie bolo ukončené"
          extra={
            <div style={{ textAlign: 'center' }}>
              <Button onClick={this.handleResultEnd} type="primary">
                {this.props.t('test.closeTest')}
              </Button>
              {
                //<p>{this.props.t('test.numberCorrect')} {rightSelected}</p>
                //<p>{this.props.t('test.numberUncorrect')} {wrongSelected}</p>
              }
              <p>
                {this.props.t('test.correctAnswers')} {optionsRight} / {events.length}
              </p>
              <p className={color}>{this.props.t('test.successRate')} {finalPercentage.toFixed(2)} %</p>
            </div>
          }
        />
      </Card>
    )
  }

  static contextType = AuthContext

  render() {
    const { isLoading, testEnded, unit } = this.state

    if (!isLoading) {
      return <Loading />
    }
    return (
      <div className="test-container">
        <Card
          className="rounded"
          style={{ margin: 0, marginBottom: 10 }}
          bodyStyle={{ padding: 10, paddingLeft: 24 }}
        >
          <Title level={3} style={{ marginBottom: 0 }}>
            {this.props.t('test.informativeTesting')}
          </Title>
          <p className="left-space">{unit ? unit.title : this.props.t('test.lessonTitleError')}</p>
        </Card>
        {isLoading && testEnded ? this.renderResults() : this.renderQuestion()}
      </div>
    )
  }
}

export default withTranslation()(TestContainer)