import React, { RefObject } from 'react'
import '@ant-design/compatible/assets/index.css'
import { Input, Button, Checkbox, Form, Col, Row } from 'antd'
// @ts-expect-error: CKEditor doesnt have types - fix if they have them in future
import CKEditor from '@ckeditor/ckeditor5-react'
// @ts-expect-error: CKEditor doesnt have types - fix if they have them in future
import Editor from '@drblmthw/testujsk_custom_ckeditor5/build/ckeditor'
import '@drblmthw/testujsk_custom_ckeditor5/build/translations/en'

import { withRouter, RouteComponentProps } from 'react-router'
import api from '../../../utils/ApiAxios'
import notificationService from '../../../utils/notificationService'
import { FormInstance } from 'antd/lib/form'
import { Activity, ICKEditor } from '../../../models'
import { AxiosResponse } from 'axios'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'

interface IProps extends RouteComponentProps<RouteParams>, WithTranslation {

}

interface RouteParams {
  id: string;
}

interface IState {
  activity: Activity;
  activityEditor: ICKEditor | null;
  //studyFields: StudyField[];
}


class ActivityCreationComponent extends React.Component<IProps, IState> {
  private formRef: RefObject<FormInstance> = React.createRef<FormInstance>()

  constructor(props: IProps) {
    super(props)
    this.state = {
      activityEditor: null,
      activity: {
        public: false,
        content: '',
        title: '',
        validated: false,
        total_time: 0,
      }
      //studyFields: []
    }
  }

  componentDidMount() {
    //api.activities
    //  .getActivityStudyFields()
    //  .then(response => {
    //    this.setState({
    //      studyFields: response.data
    //    })
    //  })
    //  .catch(error => {
    //    notificationService.error('Študijné odbory sa nepodarilo načítať, skúste stránku načítať neskôr', error)
    //  })
  }

  handleSubmit = async () => {
    if (this.state.activityEditor && this.formRef.current) {
      const { setFieldsValue, validateFields } = this.formRef.current
      setFieldsValue({ content: this.state.activityEditor.getData() })
      const validatedValues = await validateFields()
      validatedValues.public = this.state.activity.public
      api.activities
        .createActivity(validatedValues as Activity)
        .then((resp: AxiosResponse<Activity>) => {
          console.log(validatedValues)
          notificationService.success(this.props.t('activities.subjectCreateSuccess'))
          this.props.history.push(resp.data.id ? `/activity/detail/${resp.data.id}` : '/activities')
        })
        .catch(error => {
          notificationService.error(this.props.t('activities.subjectCreateUnsuccess'), error)
        })
    }
  }

  render() {

    return (
      <main>
        <h2>{this.props.t('activities.subjectCreate')}</h2>
        <Form ref={this.formRef} onFinish={this.handleSubmit} layout="vertical">
          <Form.Item
            label={this.props.t('activities.subjectName')}
            name="title"
            rules={[
              {
                required: true,
                message: this.props.t('activities.subjectEnterName')
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label={this.props.t('activities.subjectField')}
                name="program"
                rules={[
                  {
                    required: false
                    //message: this.props.t('activities.')
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={this.props.t('activities.subjectFieldCode')}
                name="code"
                rules={[
                  {
                    required: false
                    //message: this.props.t('activities.')
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={this.props.t('activities.subjectYear')}
                name="year"
                rules={[
                  {
                    required: false
                    //message: this.props.t('activities.')
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={this.props.t('basic.content')}
            name="content"
            rules={[
              {
                required: true,
                message: this.props.t('activities.subjectEnterContent')
              }
            ]}
          >
            <CKEditor
              editor={Editor}
              onInit={(editor: ICKEditor) => {
                this.setState({ activityEditor: editor })
                editor.setData(
                  this.state.activity.content ? this.state.activity.content : ''
                )
              }}
              config={{
                language: this.props.i18n.language,
                toolbar: {
                  items: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'link',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'outdent',
                    'indent',
                    '|',
                    'blockQuote',
                    'insertTable',
                    'mediaEmbed',
                    '|',
                    'math',
                    '|',
                    'undo',
                    'redo'
                  ]
                },
                mediaEmbed: {
                  previewsInData: true
                }
              }}
            />
          </Form.Item>
          <Form.Item label={this.props.t('activities.subjectDisplayInfo')} name="public">
            <Checkbox
              checked={this.state.activity.public}
              onChange={() => {
                const activityToChange = { ...this.state.activity }
                activityToChange.public = !this.state.activity.public
                this.setState({ activity: activityToChange })
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {this.props.t('activities.subjectCreate')}
            </Button>
          </Form.Item>
        </Form>
      </main>
    )
  }
}

export default withTranslation()(withRouter(ActivityCreationComponent))
