import React from 'react'
import {
  LeftCircleOutlined,
  RightCircleOutlined,
  InfoCircleOutlined
} from '@ant-design/icons'
import { Button, Card, Result, Row, Col, Modal, Typography } from 'antd'
import { withRouter } from 'react-router-dom'
import api from '../../../utils/ApiAxios'
import { Prompt, RouteComponentProps } from 'react-router'
import moment from 'moment'

import Loading from '../../common/Loading/Loading'
import ExamOption from './ExamOption/ExamOption'
import Countdown from 'react-countdown'
import './ExamContainer.css'
import notificationService from '../../../utils/notificationService'

import { Event, Option } from '../../../models'
import { MenuState } from '../../common/HeaderAndMenu/HeaderAndMenu'

//@ts-ignore
import { InlineMath, BlockMath } from 'react-katex'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'

const { confirm } = Modal
const { Title } = Typography

interface IProps extends RouteComponentProps<RouteParams, any, { activityId: number }>, WithTranslation {
  menuState: MenuState
  handleErrorExitTest: () => void
  handleExitTestResults: (activityId: number) => void
  handleExitTestFromLastQuestion: () => void
}

interface RouteParams {
  testId: string
}

interface IState {
  events: Event[]
  submited: boolean
  submiting: boolean
  loading: boolean
  startTime: Date
  testEndDate?: number
  eventHandlingStart: number
  selectedOptionsIds: { [eventId: number]: number[] } // hash map of options ids for every event id
  currentSelectedOptionsIds: number[]
  timeSpentOnEvents: { [eventId: number]: number } // hash map for every event ID with time spent on it
  index: number
  totalObtainedPoints: number
  shouldSubmit: boolean
}

interface EventAnswerData {
  event_id: number
  end_time: string
  start_time: string
  time_spent: number
  answer: string
  obtained_points: number
}

class ExamContainer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      events: [],
      index: 0,
      loading: true,
      currentSelectedOptionsIds: [],
      selectedOptionsIds: {},
      timeSpentOnEvents: {},
      submited: false,
      submiting: false,
      startTime: new Date(),
      eventHandlingStart: Date.now(),
      totalObtainedPoints: 0,
      shouldSubmit: true
    }
  }

  componentDidMount() {

    //check if there are already answers in the db
    api.exams.checkSubmitted(parseInt(this.props.match.params.testId)).then(resp => {
      this.setState({ shouldSubmit: true })
    }, error => {
      notificationService.error(
        this.props.t('exams.alreadyCompletedError'),
        error
      )
      this.setState({ shouldSubmit: false }, () => {
        this.props.history.push('/exam/student/result/' + parseInt(this.props.match.params.testId))
      })
    })

    this.setState({
      ...this.state,
      loading: true
    })
    api.exams.getExam(parseInt(this.props.match.params.testId)).then(resp => {
      const { events } = resp.data
      const timeToHandleTest = events.reduce(
        (prevVal: number, nextVal: Event) =>
          prevVal + nextVal.time_to_handle * 1000,
        0
      )

      api.exams.getExamEventsOptions(events).then(resp => {
        events.forEach((event: Event, index: number) => {
          event.options = resp[index].data
        })
        this.setState({
          ...this.state,
          loading: false,
          testEndDate: Date.now() + timeToHandleTest,
          events,
          eventHandlingStart: Date.now()
        })
      })
    })
  }

  componentWillUnmount() {
    if (!this.state.submited) {
      this.handleSubmit()
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.menuState.testEnded !== prevProps.menuState.testEnded) {
      if (this.props.menuState.testEnded && !this.state.submited) {
        this.handleSubmit()
      }
    }
  }

  handleSetSelectedOption = (id: number) => () => {
    const selectedOptionsToModify = [...this.state.currentSelectedOptionsIds]
    if (this.state.currentSelectedOptionsIds.includes(id)) {
      // remove option if it is selected
      selectedOptionsToModify.splice(
        this.state.currentSelectedOptionsIds.indexOf(id),
        1
      )
    } else {
      // add option if not
      selectedOptionsToModify.push(id)
    }
    this.setState({ currentSelectedOptionsIds: selectedOptionsToModify })
  }

  checkForNotAnsweredEvents() {
    const { selectedOptionsIds } = this.state
    const timeSpentOnEvents = this.state.timeSpentOnEvents
    for (const event of this.state.events) {
      if (
        event.id &&
        !Object.keys(selectedOptionsIds).includes(event.id.toString())
      ) {
        selectedOptionsIds[event.id] = []
        timeSpentOnEvents[event.id] = 0
      }
    }
    this.setState({
      ...this.state,
      selectedOptionsIds,
      timeSpentOnEvents
    })
  }

  getObtainedPoints(event: Event, optionIds: number[]) {
    if (!event.options) {
      return 0
    }
    const numberOfPossibleCorrect = event.options.reduce((prevVal, nextVal) => {
      return nextVal.correct_answer ? prevVal + 1 : prevVal
    }, 0)
    let correctAnswered = 0
    for (const optionId of optionIds) {
      const selectedOption = event.options.find(
        option => option.id === optionId
      )
      if (selectedOption && selectedOption.correct_answer) {
        correctAnswered++
      }
    }

    //TODO: possible fix this
    //in theory, this shouldnt happen but there are cases when the exam has no given answers (e.g. if the teacher wants you to write the answer on the paper so no option is given (math))
    // eslint-disable-next-line
    if (numberOfPossibleCorrect == 0) {
      return 1
    }

    return (
      (1 / numberOfPossibleCorrect) * correctAnswered -
      (1 / (event.options.length - numberOfPossibleCorrect)) *
      (optionIds.length - correctAnswered)
    )
  }

  handleSubmit = () => {
    //if the test answer is already in the db
    if (!this.state.shouldSubmit) {
      return
    }

    this.setState({
      ...this.state,
      submiting: true
    })

    this.saveCurrentTimeAndOptions(this.state.events[this.state.index])
    this.checkForNotAnsweredEvents()
    const endTime = new Date()
    let totalPoints = 0
    const answerBodyforBE = this.state.events.reduce(
      (eventAnswers: EventAnswerData[], event: Event) => {
        if (event.id) {
          const obtainedPoints = event.id
            ? this.getObtainedPoints(
              event,
              this.state.selectedOptionsIds[event.id]
            )
            : 0
          totalPoints += obtainedPoints > 0 ? obtainedPoints : 0
          eventAnswers.push({
            event_id: event.id,
            end_time: moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
            start_time: moment(this.state.startTime).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            time_spent: this.state.timeSpentOnEvents[event.id],
            answer: JSON.stringify({
              options: this.state.selectedOptionsIds[event.id]
            }),
            obtained_points: obtainedPoints < 0 ? 0 : obtainedPoints
          })
        }

        return eventAnswers
      },
      []
    )

    api.exams
      .submitExam(parseInt(this.props.match.params.testId), answerBodyforBE)
      .then(
        () => {
          this.setState({
            ...this.state,
            submited: true,
            submiting: false,
            totalObtainedPoints: totalPoints
          })
        },
        error => {
          this.props.handleErrorExitTest()
          this.setState({
            ...this.state,
            submiting: false
          })
          notificationService.error(
            this.props.t('exams.submitError'),
            error
          )
        }
      )
  }

  saveCurrentTimeAndOptions(event: Event) {
    const { eventHandlingStart } = this.state
    const finalSelectedOptions = this.state.selectedOptionsIds
    const timeSpentOnEvents = this.state.timeSpentOnEvents
    if (event.id !== undefined) {
      finalSelectedOptions[event.id] = [...this.state.currentSelectedOptionsIds]
      if (!timeSpentOnEvents[event.id]) {
        timeSpentOnEvents[event.id] = 0
      }
      timeSpentOnEvents[event.id] += Date.now() - eventHandlingStart
      this.setState({
        ...this.state,
        selectedOptionsIds: finalSelectedOptions,
        timeSpentOnEvents,
        eventHandlingStart: Date.now()
      })
    } else {
      notificationService.error(
        this.props.t('exams.pageExamError')
      )
      return
    }
  }

  handlePageChangeBack = () => {
    this.handlePageChange(-1)
  }

  handlePageChangeNext = () => {
    this.handlePageChange(1)
  }

  handlePageChange = (inc: any) => {
    const { events, index } = this.state
    const currentEvent = events[index]

    this.saveCurrentTimeAndOptions(events[index])
    if (currentEvent && currentEvent.id) {
      this.setState(
        {
          index: index + inc,
          currentSelectedOptionsIds: this.state.selectedOptionsIds[
            currentEvent.id
            ]
            ? this.state.selectedOptionsIds[currentEvent.id]
            : []
        },
        () =>
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
      )
    } else {
      notificationService.error(
        this.props.t('exams.pageExamError'),
        `[ERROR] HANDLE PAGE CHANGE - EventId missing ${this.props.match.params.testId}`
      )
    }
  }

  renderQuestion() {
    const { events, index } = this.state
    const { header, message, options } = events[index] || {}

    return (
      <div className="question">
        <Card>
          <Title level={3}>{header}</Title>
          <p>
            {this.renderTextWithMath(message)}
          </p>
        </Card>
        <Card>{this.renderOptions(options ? options : [])}</Card>
      </div>
    )
  }

  renderOptions(options: Option[]) {
    const { currentSelectedOptionsIds } = this.state

    return (
      options &&
      options.map(option => {
        const { id } = option
        if (id) {
          const isSelected = currentSelectedOptionsIds.includes(id)

          return (
            <ExamOption
              key={id}
              isSelected={isSelected}
              onClick={this.handleSetSelectedOption(id)}
              option={option}
            />
          )
        } else {
          return <></>
        }
      })
    )
  }

  renderTextWithMath(message: string) {

    message = message.replaceAll('<p>', '').replaceAll('</p>', '<br>')

    let indexInline, indexBlock
    let iflag = false

    const elements = []

    while (true) {
      indexInline = message.search('<script type="math/tex">')
      indexBlock = message.search('<script type="math/tex; mode=display">')

      if (indexInline !== -1 && indexBlock !== -1) {
        iflag = (indexInline < indexBlock)
      } else if (indexInline !== -1) {
        iflag = true
      } else if (indexBlock !== -1) {
        iflag = false
      } else {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message }} />)
        break
      }

      const endIndex = message.search('</script>')

      // eslint-disable-next-line
      if (iflag == true) {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message.substring(0, indexInline) }} />)
        elements.push(<InlineMath>{message.substring(indexInline + 24, endIndex)}</InlineMath>)
      } else {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message.substring(0, indexBlock) }} />)
        elements.push(<BlockMath>{message.substring(indexBlock + 38, endIndex)}</BlockMath>)
      }
      message = message.substring(endIndex + 9)
    }

    return elements
  }

  render() {
    const { loading, submited } = this.state

    if (loading) {
      return <Loading />
    }
    if (submited) {
      return (
        <div className="center-verticaly">
          <Card className="rounded resultCard">
            <Title level={3}>{this.props.t('exams.results')}</Title>
            <Result
              icon={<InfoCircleOutlined />}
              title={this.props.t('exams.examFinished')}
              extra={
                <div>
                  <span>
                    {this.props.t('exams.examPointsEarned')}{' '}
                    {Math.round(
                      (this.state.totalObtainedPoints + Number.EPSILON) * 100
                    ) / 100}{' '}
                    {this.props.t('exams.examPointsTotal')}{this.state.events.length}
                  </span>
                  <Button
                    type="primary"
                    className="display-small-screen close-results-button"
                    onClick={() =>
                      this.props.handleExitTestResults(
                        this.props.location.state.activityId
                      )
                    }
                  >
                    {this.props.t('exams.examClose')}
                  </Button>
                </div>
              }
            />
          </Card>
        </div>
      )
    }
    return (
      <div className="test-container">
        {this.state.submiting && <Loading />}
        <Prompt
          when={this.state.shouldSubmit}
          message={this.props.t('exams.examCloseWarning')}
        />
        {this.renderQuestion()}
        <Row justify="space-between">
          <Col
            xs={{ span: 12, order: 2 }}
            md={{ span: 5, order: 1 }}
            lg={{ span: 4, order: 1 }}
          >
            <Countdown
              date={this.state.testEndDate}
              daysInHours
              onComplete={this.handleSubmit}
              autoStart={true}
            />
            <Button
              type="primary"
              danger
              onClick={() => {
                confirm({
                  title: this.props.t('exams.examFinishQuestion'),
                  okText: this.props.t('basic.yes'),
                  cancelText: this.props.t('basic.no'),
                  onOk: this.props.handleExitTestFromLastQuestion
                })
              }}
              className="submit-test"
            >
              {this.props.t('exams.examSubmit')}
            </Button>
          </Col>
          <Col
            xs={{ span: 12, order: 3 }}
            md={{ span: 5, order: 3 }}
            lg={{ span: 5, order: 3 }}
          >
            {this.state.index !== 0 ? (
              <Button onClick={this.handlePageChangeBack} type="primary">
                {this.props.t('exams.prevQuestion')}
                <LeftCircleOutlined />
              </Button>
            ) : (
              <Button
                onClick={this.handlePageChangeBack}
                type="primary"
                disabled={true}
              >
                {this.props.t('exams.prevQuestion')}
                <LeftCircleOutlined />
              </Button>
            )}
            {this.state.index !== this.state.events.length - 1 ? (
              <Button onClick={this.handlePageChangeNext} type="primary">
                <RightCircleOutlined />
                {this.props.t('exams.nextQuestion')}
              </Button>
            ) : (
              <Button
                onClick={this.handlePageChangeNext}
                type="primary"
                disabled={true}
              >
                <RightCircleOutlined />
                {this.props.t('exams.nextQuestion')}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    )
  }
}

export default withTranslation()(withRouter(ExamContainer))
