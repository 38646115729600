import React, { Component } from 'react'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { Card, Row, Col } from 'antd'

import './TestOption.css'
import { Option } from '../../../../models'
//@ts-ignore
import { InlineMath, BlockMath } from 'react-katex'

interface IProps {
  isSelected: boolean;
  isAnsweredQ: boolean;
  option: Option;
  onClick?: () => void;
}

class TestOption extends Component<IProps> {
  getSelectedClassName() {
    const { isSelected } = this.props

    if (isSelected) {
      return 'selected'
    }
    return ''
  }

  getSelectedAnswerName(correct_answer: boolean) {
    const { isSelected } = this.props

    if (isSelected && correct_answer) {
      return <CheckCircleFilled style={{ color: 'green' }} />
    } else if (isSelected && !correct_answer) {
      return <CloseCircleFilled style={{ color: 'red' }} />
    } else if (!isSelected && correct_answer) {
      return <CheckCircleFilled style={{ color: 'green' }} />
    } else {
      return <></>
    }
  }

  renderTextWithMath(message: string) {

    message = message.replaceAll('<p>', '').replaceAll('</p>', '<br>')

    let indexInline, indexBlock
    let iflag = false

    const elements = []

    while (true) {
      indexInline = message.search('<script type="math/tex">')
      indexBlock = message.search('<script type="math/tex; mode=display">')

      if (indexInline !== -1 && indexBlock !== -1) {
        iflag = (indexInline < indexBlock)
      } else if (indexInline !== -1) {
        iflag = true
      } else if (indexBlock !== -1) {
        iflag = false
      } else {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message }} />)
        break
      }

      const endIndex = message.search('</script>')

      if (iflag == true) {// eslint-disable-line
        elements.push(<span dangerouslySetInnerHTML={{ __html: message.substring(0, indexInline) }} />)
        elements.push(<InlineMath>{message.substring(indexInline + 24, endIndex)}</InlineMath>)
      } else {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message.substring(0, indexBlock) }} />)
        elements.push(<BlockMath>{message.substring(indexBlock + 38, endIndex)}</BlockMath>)
      }
      message = message.substring(endIndex + 9)
    }

    return elements
  }

  render() {
    const { onClick, option } = this.props || {}
    const { answer_data } = option || {}
    const { isAnsweredQ } = this.props
    const { correct_answer } = option || {}

    let icon
    if (isAnsweredQ) {
      icon = this.getSelectedAnswerName(correct_answer)
    }

    const selectedClassname = this.getSelectedClassName()

    return (
      <Card
        bordered={false}
        className={`rounded default-silver ${selectedClassname}`}
        onClick={onClick}
        style={{ margin: 10 }}
      >
        <Row align="middle">
          <Col span={23}>
            <b>
              <p style={{ margin: 0 }}>
                {this.renderTextWithMath(answer_data)}
              </p>
            </b>
          </Col>
          <Col span={1} style={{ textAlign: 'right' }}>
            {icon}
          </Col>
        </Row>
      </Card>
    )
  }
}

export default TestOption
