import { withRouter, RouteComponentProps } from 'react-router-dom'
import React, { Component } from 'react'

/* Css */
import './SectionGrants.scss'

/* i18n */
import { WithTranslation, withTranslation } from 'react-i18next'
import MainCard from '../MainCard/MainCard'


interface IProps extends RouteComponentProps, WithTranslation {
}

interface IState {
  selectedLanguage: string;
}

class SectionGrants extends Component<IProps, IState> {
  render() {
    const { t } = this.props
    const prioritiesItems = t('titlePage.grantsSection.priorities.items', { returnObjects: true }) as Array<string>
    return (
      <MainCard
        title={t('titlePage.grantsSection.title')}
        description={t('titlePage.grantsSection.subtitle')}
      >

        {/* Norway grants description */}
        <div className="eceh-grants__description-container">
          <div className="eceh-grants__description-left">
           <div className="eceh-grants__description-paragraphs-container">
             <p>{t('titlePage.grantsSection.description.paragraph1')}</p>
             <p>{t('titlePage.grantsSection.description.paragraph2')}</p>
           </div>

            {/* Medium and higher screen sizes */}
            <div className="eceh-grants__priorities">
              <h3>{t('titlePage.grantsSection.priorities.title')}</h3>

              {prioritiesItems.map(item => <p key={item}>{item}</p>)}
            </div>
          </div>

          <img className="eceh-grants__description-hero" src="/images/title-page/education.jpg" alt="" />
        </div>

        {/* Priorities on XS and Small screen sizes */}
        <div className="eceh-grants__priorities">
          <h3>{t('titlePage.grantsSection.priorities.title')}</h3>

          {prioritiesItems.map(item => <p key={item}>{item}</p>)}
        </div>

        {/* Norway grants website information */}
        <div className="eceh-grants__website-source">
          <p className="eceh-grants__website-source-description">
            {
              t('titlePage.grantsSection.grantsUrlInfo') + ' '
            } <a
            href={t('titlePage.grantsSection.linkUrl')}
            target="_blank"
            rel="noopener noreferrer"
          >
            www.norwaygrants.sk
          </a>.
          </p>
          <a href="https://www.norwaygrants.sk" target="_blank" rel="noopener noreferrer">
            <img src="/images/title-page/norway-grants--gray.svg" alt="Norway grants" />
          </a>
        </div>
      </MainCard>
    )
  }
}

export default withTranslation()(withRouter(SectionGrants))