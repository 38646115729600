import React, {RefObject} from 'react'
import {withRouter, RouteComponentProps} from 'react-router-dom'
import {AuthContext} from '../../../context/Auth'
import api from '../../../utils/ApiAxios'
import notification from '../../../utils/notificationService'
import User from '../../../models/user'
import Organization from '../../../models/organization'
import LocalStorageAppState from '../../../models/appState'
import {Input, Modal, Button, Form, Select, Descriptions} from 'antd'
import {LockOutlined} from '@ant-design/icons'
import {Rule, FormInstance} from 'antd/lib/form'
import {Store} from 'antd/lib/form/interface'
import {LabeledValue} from 'antd/lib/tree-select'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'

const {Option} = Select

interface IProps extends RouteComponentProps, WithTranslation {

}

interface IState {
  changeUserInfoModal: boolean
  changePasswordModal: boolean
  confirmDirty: boolean
  user: User
  userOrganization: number
  hasOrganisation: boolean
  userOrganizationName: string
  organizationTreeValue: LabeledValue[]
  organizationTreeData: LabeledValue[]
}

class Profile extends React.Component<IProps, IState> {
  static contextType = AuthContext
  private formRef: RefObject<FormInstance> = React.createRef<FormInstance>()

  constructor(props: IProps) {
    super(props)
    this.state = {
      changeUserInfoModal: false,
      changePasswordModal: false,
      confirmDirty: false,
      user: {
        name: '',
        email: '',
        class: ''
      },
      userOrganization: -1,
      hasOrganisation: false,
      userOrganizationName: '',
      organizationTreeData: [],
      organizationTreeValue: []
    }
  }

  componentDidMount() {
    const datas = JSON.parse(localStorage.appState) //podľa lognutého usera sa dorobí
    api.users
      .getUserInfo(datas.user.id)
      .then(response => {
        this.setState({
          user: response.data[0],
          userOrganization: response.data[0].organization_id,
          userOrganizationName: response.data[0].organization.name
        })
        let temp
        if (response.data[0].organization_id > 1){
          temp = true
        }
        else{
          temp = false
        }
        this.setState({
          hasOrganisation: temp
        }, () => {
          if (this.formRef.current) {
            this.formRef.current.validateFields([this.props.t('profile.class')])
          }
        })
      })
      .catch(error =>
        notification.error(
          this.props.t('profile.pageError'),
          error
        )
      )
  }

  // compare password
  compareToFirstPassword = (rule: Rule, value: string) => {
    if (
      value &&
      this.formRef.current &&
      value !== this.formRef.current.getFieldValue('password')
    ) {
      return Promise.reject(this.props.t('profile.passwordDoNotMatch'))
    } else {
      return Promise.resolve()
    }
  }

  // comapre password
  validateToNextPassword = (rule: Rule, value: string) => {
    if (value && this.formRef.current && this.state.confirmDirty) {
      this.formRef.current.validateFields(['c_password'])
    }
    return Promise.resolve()
  }

  showChangeInfoModal = () => {
    this.setState({
      changeUserInfoModal: true
    })

    api.organizations.getAllOrganizations().then(response => {
      const data = response.data.reduce(
        (result: LabeledValue[], organization: Organization) => {
          if (organization.name && organization.id) {
            result.push({
              label: organization.name,
              value: organization.id
            })
          }
          return result
        },
        []
      )
      this.setState({
        ...this.state,
        organizationTreeData: data
      })
    })
  }

  showChangePasswordModal = () => {
    this.setState({
      changePasswordModal: true
    })
  }

  hideChangeUserInfo = () => {
    this.setState({
      changeUserInfoModal: false,
      changePasswordModal: false
    })
  }

  handleChangeUserInfo = (values: Store) => {
    const datas = JSON.parse(localStorage.appState)

    this.hideChangeUserInfo()
    api.users
      .changeUserInfo({
        name: `${values.Meno} ${values.Priezvisko}`,
        email: values.Email,
        organization_id: values.Organizacia,
        class: values.Trieda
      })
      .then(() => {
        this.componentDidMount() // to refresh user info

        const user = {
          token: datas.user.token,
          name: `${values.Meno} ${values.Priezvisko}`,
          role_id: datas.user.role_id,
          role: datas.user.role,
          email: this.state.user.email,
          id: this.state.user.id,
          class: this.state.user.class
        }

        const appState: LocalStorageAppState = {
          isLoggedIn: true,
          user,
          loginTimestamp: datas.loginTimeStamp
        }

        localStorage.appState = JSON.stringify(appState) // to refresh the name in header
        window.location.reload()
        notification.success(this.props.t('profile.changeSuccess'))
      })
      .catch(error => {
        notification.error(
          this.props.t('profile.changeUnsuccess'),
          error
        )
      })
  }

  handleChangeUserPassword = (values: Store) => {
    this.hideChangeUserInfo()

    api.users
      .changePassword({
        email: this.state.user.email,
        password: values.old_password,
        password_new: values.password,
        c_password_new: values.c_password
      })
      .then(() => {
        notification.success(this.props.t('profile.passwordChangeSuccess'))
      })
      .catch(error =>
        notification.error(this.props.t('profile.passwordChangeUnsuccess'), error)
      )
  }

  handleOrganisationSelect = (e : number) => {
      let temp
      if (e > 1){
        temp = true
        this.formRef.current?.resetFields([this.props.t('profile.class')])
      }
      else{
        temp = false
        this.formRef.current?.setFields([
          {
            name: this.props.t('profile.class'),
            value: null
          },
       ])
      }

      this.setState({
        hasOrganisation: temp
      }, () => {
        this.formRef.current?.validateFields([this.props.t('profile.class')])
      })
  }

  render() {
    return (
      <main>
        <h2>Profil</h2>
        <Descriptions
          title={this.props.t('profile.userInfo')}
          column={2}
          bordered={true}
        >
          <Descriptions.Item>
            <h3>{this.props.t('profile.name')}</h3>
          </Descriptions.Item>
          <Descriptions.Item>{this.state.user.name}</Descriptions.Item>

          <Descriptions.Item>
            <h3>{this.props.t('profile.email')}</h3>
          </Descriptions.Item>
          <Descriptions.Item>{this.state.user.email}</Descriptions.Item>

          <Descriptions.Item>
            <h3>{this.props.t('profile.organization')}</h3>
          </Descriptions.Item>
          <Descriptions.Item>
            {this.state.userOrganizationName}
          </Descriptions.Item>
          <Descriptions.Item>
            <h3>{this.props.t('profile.class')}</h3>
          </Descriptions.Item>
          <Descriptions.Item>
            {this.state.user.class}
          </Descriptions.Item>
        </Descriptions>

        <Button onClick={this.showChangePasswordModal}>{this.props.t('profile.changePassword')}</Button>

        <Button onClick={this.showChangeInfoModal}>{this.props.t('profile.changeUserInfo')}</Button>

        <Modal
          width={600}
          centered
          closable
          title={this.props.t('profile.changeOfUserInfo')}
          visible={this.state.changeUserInfoModal}
          onCancel={this.hideChangeUserInfo}
          footer={[
            <Button key="back" onClick={this.hideChangeUserInfo}>
              {this.props.t('basic.cancel')}
            </Button>,
            <Button
              form="user info"
              key="submit"
              type="primary"
              htmlType="submit"
            >
              {this.props.t('basic.save')}
            </Button>
          ]}
        >
          <Form
            id="user info"
            ref={this.formRef}
            onFinish={this.handleChangeUserInfo}
            layout="vertical"
            initialValues={{
              Meno: this.state.user.name.split(' ')[0],
              Priezvisko: this.state.user.name.split(' ')[1],
              Email: this.state.user.email,
              Organizacia: this.state.userOrganization,
              Trieda: this.state.user.class
            }}
          >
            <Form.Item
              name="Meno"
              label={this.props.t('profile.name')}
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="Priezvisko"
              label={this.props.t('profile.surname')}
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="Email"
              label={this.props.t('profile.email')}
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="Organizacia"
              label={this.props.t('profile.organization')}
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                    (option?.label ?? '').toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
                }
                
                onChange={(e) => {this.handleOrganisationSelect(e as number)}}
              >
                {this.state.organizationTreeData.map(item => (
                  <Option
                    value={item.value}
                    key={item.value}
                    label={item.label}
                  >
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="Trieda"
              label={this.props.t('profile.class')}
              rules={[
                {
                  required: this.state.hasOrganisation
                }
              ]}
            >
              <Input placeholder={this.props.t('profile.class')}
                disabled = {!this.state.hasOrganisation}/>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          width={400}
          centered
          closable
          title={this.props.t('profile.changeOfPassword')}
          visible={this.state.changePasswordModal}
          onCancel={this.hideChangeUserInfo}
          footer={[
            <Button key="back" onClick={this.hideChangeUserInfo}>
              {this.props.t('basic.cancel')}
            </Button>,
            <Button
              form="user password"
              key="submit"
              type="primary"
              htmlType="submit"
            >
              {this.props.t('basic.save')}
            </Button>
          ]}
        >
          <Form
            id="user password"
            ref={this.formRef}
            onFinish={this.handleChangeUserPassword}
            layout="vertical"
          >
            <Form.Item
              name="old_password"
              rules={[
                {
                  required: true,
                  message: this.props.t('profile.enterOldPassword')
                },
                {
                  min: 6,
                  message: this.props.t('profile.passwordMinRequirements')
                }
              ]}
            >
              <Input.Password
                prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
                type="password"
                placeholder={this.props.t('profile.oldPassword')}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: this.props.t('profile.enterNewPassword')
                },
                {
                  min: 6,
                  message: this.props.t('profile.passwordMinRequirements')
                },
                {
                  validator: this.validateToNextPassword
                }
              ]}
            >
              <Input.Password
                prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
                type="password"
                placeholder={this.props.t('profile.newPassword')}
              />
            </Form.Item>
            <Form.Item
              name="c_password"
              rules={[
                {
                  required: true,
                  message: this.props.t('profile.enterNewPasswordRepeart')
                },
                {
                  min: 6,
                  message: this.props.t('profile.passwordMinRequirements')
                },
                {
                  validator: this.compareToFirstPassword
                }
              ]}
            >
              <Input.Password
                prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
                type="password"
                placeholder={this.props.t('profile.repeatNewPassword')}
              />
            </Form.Item>
          </Form>
        </Modal>
      </main>
    )
  }
}

export default withTranslation()(withRouter(Profile))
