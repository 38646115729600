// RegistrationPage.js
import React, { Component } from 'react';
import './Registration.css'
//import RegistrationForm from './RegistrationForm'; // Import the RegistrationForm component


/* i18n */
import { WithTranslation, withTranslation } from 'react-i18next'
import Layout from '../Welcome/partials/Layout/Layout'
import Header from '../Welcome/partials/Header/Header'
import SecondaryHeader from '../Welcome/partials/SecondaryHeader/SecondaryHeader'
import Footer from '../Welcome/partials/Footer/Footer'
import MainCard from '../Welcome/partials/MainCard/MainCard'
import RegistrationForm from '../../common/LoginAndRegistrationModal/LoginAndRegistrationModalRgistrationForm/RegistrationForm_mail';



class RegistrationPage extends Component {
  handleRegistrationSuccess = () => {
    // Handle successful registration if needed
  };

  render() {
    const { t } = this.props as WithTranslation;

    return (
      <Layout>
        <Header />
        <SecondaryHeader />

        {/* Content */}
        <MainCard title={t('login.registration_header')} className="eceh-contact-page"> {/* Use t here */}
          <div className="eceh">
            <div className="">
              <RegistrationForm handleOk={this.handleRegistrationSuccess} />
            </div>
          </div>
        </MainCard>
        <Footer />
      </Layout>
    );
  }
}

export default withTranslation()(RegistrationPage); 