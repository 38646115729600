import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import React from 'react'

/* Css */
import './SecondaryHeader.scss'

/* i18n */
import { WithTranslation, withTranslation } from 'react-i18next'
import { AuthContext } from '../../../../../context/Auth'

interface IProps extends RouteComponentProps, WithTranslation {
}

interface IState {
  selectedLanguage: string;
}

interface ISecondaryHeaderLink {
  label: string,
  url: string,
  target: string,
  external: boolean,
}

class SecondaryHeader extends React.Component<IProps, IState> {
  static contextType = AuthContext

  render() {
    const { t, match } = this.props
    const links = t('titlePage.secondaryHeader.links', { returnObjects: true }) as Array<ISecondaryHeaderLink>


    return (
      <header className="eceh-secondary-header">
        <nav className="eceh-secondary-header__navigation-links-container">
          {
            links.map(link => link.external ?
              <a href={link.url} target={link.target} key={link.url}>{link.label}</a> :
              <Link to={link.url} target={link.target} key={link.url}>{link.label}</Link>)
          }
        </nav>

        {
          (match.path !== '/') &&
          <nav className="eceh-secondary-header__home-container">
            <Link to="/" className="eceh-secondary-header__home-item">
              <img src="/icons/home.svg" alt="" />
              <span>{t('titlePage.secondaryHeader.home')}</span>
            </Link>
          </nav>
        }

        {
          this.context.isLoggedIn && (match.path ==='/') &&
          <nav className="eceh-secondary-header__logged-in-user">
            <div className="eceh-secondary-header__lu-item">
              <img src="/icons/logged-in-user.svg" alt="" />
              <span>
                {t('titlePage.secondaryHeader.greeting', { user: this.context.user.name.split(' ')[0] })}
              </span>
            </div>

            <Link to="/dashboard" className="eceh-secondary-header__lu-item">
              <img src="/icons/dashboard-icon.svg" alt="" />
              <span>{t('titlePage.secondaryHeader.dashboard')}</span>
            </Link>
          </nav>
        }
      </header>
    )
  }
}

export default withTranslation()(withRouter(SecondaryHeader))