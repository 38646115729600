import api from '../../../../utils/ApiAxios'
import React, { ReactText } from 'react'
import { CopyOutlined } from '@ant-design/icons'
import { Table, Button, Tooltip } from 'antd'
import 'antd/dist/antd.css'
import {
  withRouter, RouteComponentProps
} from 'react-router-dom'

import { Event, User } from '../../../../models'
import Column from 'antd/lib/table/Column'
import { AuthContext } from '../../../../context/Auth'
import axios, { CancelTokenSource } from 'axios'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'


interface IProps extends RouteComponentProps, WithTranslation {
  events: Event[];
  onDelete?: (eventId?: number, eventHeader?: string) => void;
  showActions?: boolean;
}

interface IState {
  loading: boolean;
  users: User[];
}

let getAllStudentsCancelToken: CancelTokenSource | undefined


  class EventsTable extends React.Component<IProps, IState> {
    static contextType = AuthContext

    constructor(props: IProps) {
      super(props)
      this.renderButtons = this.renderButtons.bind(this)
      this.state = {
        loading: false,
        users: []
      }
    }

    renderButtons(event: Event) {
      //const { onClone } = this.props

      return (
        <div>
          <Tooltip title={this.props.t('activities.copyTask')}>
            <Button
              onClick={e => {
                e.stopPropagation()
                this.props.history.push
                ({
                  pathname: '/event/new',
                  state: event.id
                })
              }}
              icon={<CopyOutlined />}
              type="primary"
            />
          </Tooltip>
        </div>
      )
  
      /*
      const { onDelete } = this.props
      if (event.author_id === this.context.user.id && false) {
        return (
          <div>
            <Tooltip title={this.props.t('events.assignmentDelete')}>
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  onDelete && onDelete(event.id, event.header)
                }}
                icon={<DeleteOutlined />}
                danger
              >
              </Button>
            </Tooltip>
          </div>
        )
      } else {
        return (
          <Tooltip title={this.props.t('events.noActionAvailable')}>
            <Button disabled>
              <DeleteOutlined />
            </Button>
          </Tooltip>
        )
      }*/
    }

    renderAuthorName = (author_id: number) => {
      const result = this.state.users
        .find((user: User) => user.id === author_id)

      return <span>{result ? result.name : this.props.t('events.authorUnknown')}</span>
    }

    componentDidMount() {
      /* Cancel previous request if exists */
      if (getAllStudentsCancelToken) getAllStudentsCancelToken.cancel('Request canceled due to new request')

      /* Create new cancel token */
      getAllStudentsCancelToken = axios.CancelToken.source()

      api.users
        .getAllStudents(getAllStudentsCancelToken)
        .then(response => {
          this.setState({
            users: response.data
          })
        })
        .catch(error => console.log(error))
    }

    componentWillUnmount() {
      if (getAllStudentsCancelToken) {
        getAllStudentsCancelToken.cancel('Request canceled due component unmounting')
      }
    }

    render() {
      return (
        <Table<Event>
          loading={this.state.loading}
          rowKey={(record: Event) => String(record.id)}
          scroll={{ x: true }}
          onRow={(event: Event) => {
            return {
              onClick: () => {
                this.props.history.push(`/event/detail/${event.id}`)
              }
            }
          }}
          dataSource={this.props.events}
        >
          <Column title={this.props.t('events.title')} dataIndex="header" />
          <Column
            title={this.props.t('events.author')}
            dataIndex="author_id"
            render={(author_id: number) => this.renderAuthorName(author_id)}
          />
          {this.props.showActions && (
          <Column
            title={this.props.t('basic.actions')}
            key="actions"
            render={(text: ReactText, event: Event) =>
              this.renderButtons(event)
            }
          />
        )}
        </Table>
      )
    }
  }
  
  export default withTranslation()(withRouter(EventsTable))
