import React, { MouseEvent, ReactNode } from 'react'

import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FormOutlined,
  LikeOutlined,
  PlusCircleTwoTone,
  MinusCircleTwoTone,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons'

import '@ant-design/compatible/assets/index.css'

import {
  Button,
  Row,
  Col,
  Collapse,
  Modal,
  Tooltip,
  Typography,
  Card,
  Input,
  Select,
  Form,
  Switch
} from 'antd'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd'
import { withRouter, RouteComponentProps, Redirect } from 'react-router'
import { MenuInfo } from 'rc-menu/lib/interface'

import StudentsTable from './StudentsTable/StudentsTable'
import UnitComponent from './Unit/Unit'
import ActivityForm from './ActivityForm/ActivityForm'
import notification from '../../../utils/notificationService'
import api from '../../../utils/ApiAxios'
import './ActivityDetail.css'
import Loading from '../../common/Loading/Loading'

//other
import { AuthContext } from '../../../context/Auth'
import { User, Activity, Unit, Event, Organization } from '../../../models'
import { Store } from 'antd/lib/form/interface'
import { createRef } from 'react'
import { FormInstance } from 'antd/lib/form'
import { LabeledValue } from '@wr1ngl/custom_antd/lib/select'
import axios, { CancelTokenSource } from 'axios'
//translation
import { WithTranslation, withTranslation } from 'react-i18next'

const { Title } = Typography
const { Panel } = Collapse
const { confirm } = Modal
const { Option } = Select

interface RouteParams {
  id: string
}

interface IProps extends RouteComponentProps<RouteParams>, WithTranslation {

}

interface IState {
  loading: boolean
  saving: boolean
  editing: boolean // flag showing if activity is being edited
  komisar: boolean // komisar flag
  owner: boolean // owner flag
  subscriber: boolean // subscriber flag
  activity: Activity | null // activity itself
  students: User[] //users subscribed to activity
  units: Unit[]
  questions: Event[]
  tasks: Event[]
  tasksUnit: Unit[]
  questionsUnit: Unit[]
  addNewUnitModalVisible: boolean
  editingUnit: boolean
  editedUnitIndex: number
  eventsTreeValue: LabeledValue[] // key is event.id, value is event.id label is event.header
  eventsTreeData: LabeledValue[] // key is event.id, value is event.id, label is event.header
  authorsTreeValue: LabeledValue[] // key is event.id, value is event.id label is event.header
  authorsTreeData: LabeledValue[] // key is event.id, value is event.id, label is event.header
  assignUnitEventsModalVisible: boolean
  studentsTreeValue: LabeledValue[]
  studentsTreeData: LabeledValue[]
  addStudentsModalVisible: boolean
  organizationTreeValue: LabeledValue[]
  organizationTreeData: LabeledValue[]
  showMyEvents: boolean
  progress: any
  unitName: string
  unitDescription: string
  count_time: string
  total_time: number
}

let getFilteredEventsCancelToken: CancelTokenSource | undefined
let getAllStudentsCancelToken: CancelTokenSource | undefined
let getAuthorCancelToken: CancelTokenSource | undefined

class ActivityDetail extends React.Component<IProps, IState> {
  static contextType = AuthContext
  unitEditFormRef = createRef<FormInstance>()
  private searched_text = ''

  constructor(props: IProps) {
    super(props)
    this.state = {
      komisar: false,
      subscriber: false,
      owner: false,
      activity: null,
      units: [],
      questions: [],
      tasks: [],
      tasksUnit: [],
      questionsUnit: [],
      loading: true,
      saving: false,
      editing: false,
      addNewUnitModalVisible: false,
      addStudentsModalVisible: false,
      assignUnitEventsModalVisible: false,
      editingUnit: false,
      editedUnitIndex: -1,
      eventsTreeValue: [],
      eventsTreeData: [],
      authorsTreeValue: [],
      authorsTreeData: [],
      students: [],
      studentsTreeValue: [],
      studentsTreeData: [],
      organizationTreeValue: [],
      organizationTreeData: [],
      showMyEvents: true,
      progress: {},
      unitName: '',
      unitDescription: '',
      count_time: '0',
      total_time: 0,
    }
    this.reorderUnits = this.reorderUnits.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    const user = this.context.user
    const id = parseInt(this.props.match.params.id, 10)
    this.fetchActivity(id, user)
  }

  componentWillUnmount() {
    if (getFilteredEventsCancelToken)
      getFilteredEventsCancelToken.cancel(
        'Request canceled due component unmounting'
      )

    if (getAuthorCancelToken)
      getAuthorCancelToken.cancel(
        'Request canceled due component unmounting'
      )

    if (getAllStudentsCancelToken)
      getAllStudentsCancelToken.cancel(
        'Request canceled due component unmounting'
      )
  }

  fetchActivity = (id: number, user: User) => {
    this.setState({ loading: true })
    api.activities.getActivity(id).then(response => {
      const activity: Activity = response.data.activity;

      /* Return to list of activities if user is not author, or activity is not public or user is not student of activity */
      if (!(this.context.user.id === activity.author_id || activity.public || activity.isStudent)) {
        this.props.history.push('/activities')
        return
      }


      this.setState({
        activity,
        students: response.data.activity.subscriber ? response.data.activity.subscriber : [],
        owner: response.data.activity.author_id === user.id,
        komisar: user.role === 'komisar' && !response.data.activity.validated,
        subscriber: (response.data.activity.subscriber as User[]).some(subscriber => subscriber.id === user.id),
        total_time: response.data.activity.total_time
      })

      api.activities
        .getActivityUnits(id)
        .then(response => {
          const units: Unit[] = response.data

          units.forEach(unit => {
            unit.events = undefined
            unit.tests = undefined
          })
          this.setState({
            units,
            questionsUnit: JSON.parse(JSON.stringify(units)), // ze vraj to je deepcopy, moze robit pizdec ak objekt nieje serializovatelny
            tasksUnit: JSON.parse(JSON.stringify(units)), // bolo by rozlisit otazku a ulohu uz na BE
            loading: false,
          })
        })
        .catch(error => {
          this.setState({ loading: false })
          notification.error(
            this.props.t('activities.errorPageLoad'),
            error
          )
        })
    })
  }

  activityNotInitializedMessage() {
    notification.error(
      this.props.t('activities.errorSubjectLoad'),
      `[ERROR] ACTIVITY NOT INITIALIZED - Activity or ActivityId are undefined: ${this.state.activity}`,
      true
    )
  }

  addTest = (unitId?: number) => {
    if (unitId) {
      this.props.history.push({
        pathname: '/test/new',
        state: {
          activity_id: this.state.activity ? this.state.activity.id : undefined,
          unit_id: unitId
        }
      })
    } else {
      notification.error(
        this.props.t('activities.errorSubjectLoad'),
        '[ERROR] ADD TEST - UnitId is undefined',
        true
      )
    }
  }

  cloneActivity = (id: number) => {
    this.setState({
      loading: true
    })

    api.activities
      .cloneActivity(id)
      .then(response => {
        this.setState(
          {
            ...this.state,
            loading: false
          },
          () => {
            notification.success(this.props.t('activities.subjectCopySuccess'))
            this.props.history.push(
              `/activity/detail/${response.data.activity.id}`
            )
          }
        )
      })
      .catch(error => {
        this.setState({
          loading: false
        })
        notification.error(this.props.t('activities.subjectCopyUnsuccess'), error)
      })
  }

  handleActvityClone = (id?: number) => {
    if (!id) {
      this.activityNotInitializedMessage()
      return
    }
    confirm({
      title: this.props.t('activities.subjectCopyQuestion'),
      content: this.props.t('activities.subjectCopyWarning'),
      okText: this.props.t('activities.subjectCopy'),
      cancelText: this.props.t('basic.cancel'),
      onOk: () => {
        this.cloneActivity(id)
      }
    })
  }

  onSubmit = (values: Store) => {
    if (!this.state.activity) {
      this.activityNotInitializedMessage()
      return
    }
    const newActivity: Activity = {
      ...this.state.activity,
      ...values
    }
    this.setState({ loading: true })
    api.activities
      .editActivity(newActivity, newActivity.id)
      .then(() => {
        const orderedUnitIds = this.state.units.map(({ id }) => (id ? id : -1))
        if (orderedUnitIds.length > 0) {
          api.activities
            .orderActivityUnits(orderedUnitIds, newActivity.id)
            .then(() => {
              this.setState({ loading: false }, () => {
                notification.success(this.props.t('activities.subjectUpdateSuccess'))
                //the code responsible for showing the order needs to reload the page to show it correctly, so if you want to change that, you need to rewrite the code as well
                window.location.reload()
              })
            })
        } else {
          this.setState({ loading: false }, () => {
            notification.success(this.props.t('activities.subjectUpdateSuccess'))
            //the code responsible for showing the order needs to reload the page to show it correctly, so if you want to change that, you need to rewrite the code as well
            window.location.reload()
          })
        }
      })
      .catch(error => {
        notification.error(this.props.t('activities.subjectUpdateUnsuccess'), error)
      })
    this.setState({ editing: !this.state.editing, activity: newActivity })
  }

  onCancelEdit = () => {
    const user = this.context.user
    if (this.state.activity && this.state.activity.id) {
      this.fetchActivity(this.state.activity.id, user)
    } else {
      this.activityNotInitializedMessage()
    }
    this.setState({ editing: false })
  }

  reorderUnits(result: DropResult) {
    if (result.destination) {
      const units = [...this.state.units]
      const movedUnit = units.splice(result.source.index, 1)
      units.splice(result.destination.index, 0, movedUnit[0])
      this.setState({
        ...this.state,
        units
      })
    }
  }

  showConfirm(id?: number) {
    if (id) {
      confirm({
        title: this.props.t('activities.subjectDeleteQuestion'),
        content: this.props.t('activities.subjectDeleteWarning'),
        okText: this.props.t('basic.delete'),
        cancelText: this.props.t('basic.cancel'),
        onOk: () => {
          api.activities
            .deleteActivity(id)
            .then(() => {
              notification.success(this.props.t('activities.subjectDeletedSuccessful'))
              this.props.history.push('/activities')
            })
            .catch(error => {
              notification.error(
                this.props.t('activities.subjectDeleteUnsuccessful'),
                error
              )
            })
        }
      })
    } else {
      this.activityNotInitializedMessage()
    }
  }

  renderActivity() {
    const { activity, editing } = this.state
    if (activity) {
      return (
        <React.Fragment>
          <Row>
            <Col xs={16} md={10}>
              <Title level={2}>{this.props.t('activities.subjectDetail')}</Title>
            </Col>
            {this.state.owner ? (
              <Col xs={8} md={14} className="button-container-col">
                <Button
                  onClick={() => this.handleAddStudentModal()}
                  className="display-small-screen"
                  shape="circle"
                  icon={<PlusCircleTwoTone style={{ paddingTop: 5 }} />}
                  type="default"
                />
                <Button
                  onClick={() => this.handleActvityClone(activity.id)}
                  className="display-small-screen"
                  shape="circle"
                  icon={<CopyOutlined />}
                  type="default"
                />
                <Button
                  onClick={() => this.setState({ editing: !editing })}
                  className="display-small-screen"
                  shape="circle"
                  icon={<EditOutlined />}
                  type="primary"
                />
                <Button
                  className="display-small-screen"
                  onClick={() => {
                    this.showConfirm(activity.id)
                  }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                  danger
                />
                <Button
                  className="display-large-screen"
                  onClick={() => this.handleAddStudentModal()}
                  icon={<PlusCircleTwoTone />}
                  type="default"
                >
                  {this.props.t('activities.sortStudents')}
                </Button>
                <Button
                  className="display-large-screen"
                  onClick={() => this.handleActvityClone(activity.id)}
                  icon={<CopyOutlined />}
                  type="default"
                >
                  {this.props.t('activities.copySubject')}
                </Button>
                <Button
                  onClick={() => this.setState({ editing: !editing })}
                  className="display-large-screen"
                  icon={<EditOutlined />}
                  type="primary"
                >
                  {this.props.t('activities.editSubject')}
                </Button>
                <Button
                  className="display-large-screen"
                  onClick={() => {
                    this.showConfirm(activity.id)
                  }}
                  icon={<DeleteOutlined />}
                  danger
                >
                  {this.props.t('activities.deleteSubject')}
                </Button>
              </Col>
            ) : (
              activity.public ?
                (<Col xs={8} md={14} className="button-container-col">
                  <Button
                    onClick={() => this.handleActvityClone(activity.id)}
                    className="display-small-screen"
                    shape="circle"
                    icon={<CopyOutlined />}
                    type="default"
                  />
                  <Button
                    className="display-large-screen"
                    onClick={() => this.handleActvityClone(activity.id)}
                    icon={<CopyOutlined />}
                    type="default"
                  >
                    {this.props.t('activities.copySubject')}
                  </Button>
                </Col>) : (<></>)
            )}
          </Row>
          <Row>
            <Col span={17}>
              <Title level={3}>
                <Tooltip
                  title={activity.public ? this.props.t('activities.publicSubject') : this.props.t('activities.privateSubject')}>
                  {activity.public
                    ? <img className="public-img" src="/unlocked.svg" alt="Verejný predmet" />
                    : <img className="public-img" src="/locked.svg" alt="Súkromný predmet" />}
                </Tooltip>
                {activity.title}
              </Title>
            </Col>
          </Row>
          <div>
            <Row>
              <Col span={24} style={{ paddingBottom: '20px' }}>
                <Title level={4}>{this.props.t('activities.subjectField')}</Title>
                {activity.program ? activity.program : this.props.t('activities.notListed')}
              </Col>
            </Row>

            <Row>
              <Col span={24} style={{ paddingBottom: '20px' }}>
                <Title level={4}>{this.props.t('activities.subjectFieldCode')}</Title>
                {activity.code ? activity.code : this.props.t('activities.notListed')}
              </Col>
            </Row>

            <Row>
              <Col span={24} style={{ paddingBottom: '20px' }}>
                <Title level={4}>{this.props.t('activities.subjectYear')}</Title>
                {activity.year ? activity.year : this.props.t('activities.notListed')}
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col span={24}>
                <Title level={4}>{this.props.t('activities.description')}</Title>{' '}
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ paddingBottom: '20px' }}>
                <p
                  style={{ marginBottom: 5 }}
                  dangerouslySetInnerHTML={{ __html: activity.content }}
                />
              </Col>
            </Row>
          </div>
        </React.Fragment>
      )
    } else {
      this.activityNotInitializedMessage()
      return <Redirect to="/404" />
    }
  }

  removeFromActivityButton = (
    handler: (event: any) => void,
    tooltipText: string //event is mouse event, but typing it is hella pain
  ) =>
    this.state.owner ? (
      <Tooltip key={tooltipText} title={tooltipText}>
        <Button onClick={handler}>
          <MinusCircleTwoTone twoToneColor="#eb2f96" />
        </Button>
      </Tooltip>
    ) : (
      <></>
    )

  addNewToActivityButton = (
    handler: (event: any) => void,
    tooltipText: string //event is mouse event, but typing it is hella pain
  ) =>
    this.state.owner ? (
      <Tooltip key={tooltipText} title={tooltipText}>
        <Button onClick={handler}>
          <PlusCircleTwoTone />
        </Button>
      </Tooltip>
    ) : (
      <></>
    )
  //unit section
  // unit lazy load
  lazyLoadUnit = async (unitIndex: number) => {
    const unit = this.state.units[unitIndex]
    const tasksUnit = this.state.tasksUnit[unitIndex]
    const questionsUnit = this.state.questionsUnit[unitIndex]
    const tasks: Event[] = []
    const questions: Event[] = []
    const activityId = this.state.activity ? this.state.activity.id : undefined
    const user = this.context.user

    if (unit && (!unit.tests || !unit.events)) {
      try {
        const resp = await Promise.all([
          api.units.getUnitEvents(unit.id),
          api.units.getUnitTests(activityId, unit.id),
          api.activities.getUserProgress(activityId, unit.id)
        ])
        unit.events = resp[0].data
        unit.events.forEach(element => {
          if (element.event_type_id === 7) {
            tasks.push(element)
          } else {
            questions.push(element)
          }
        })
        tasksUnit.events = tasks
        questionsUnit.events = questions

        unit.tests = resp[1].data
        if (unit.tests) {
          const testAnswers = await Promise.all(
            unit.tests.map(unitTest =>
              api.exams.getUserAnswers(unitTest.id, user.id)
            )
          )
          unit.tests = unit.tests.map((test, index) => ({
            ...test,
            answers: testAnswers[index].data
          }))
        }
        const data: number = resp[2].data

        const progress = this.state.progress
        const unitProgress = { unitId: unit.id, data: data }
        const unitId: number = unit.id ? unit.id : -1
        progress[unitId] = unitProgress

        this.setState({
          progress: progress
        })
      } catch (error) {
        notification.error(
          this.props.t('activities.errorPageLoad'),
          error as string
        )
      }
      this.setState({ ...this.state })
    }
  }
  // unit delete
  deleteUnitFromActivity = (unitIndex: number) => {
    this.setState({
      loading: true
    })

    const units = [...this.state.units]
    const unitId = units[unitIndex].id

    api.activities
      .removeActivityUnit(
        unitId,
        this.state.activity ? this.state.activity.id : undefined
      )
      .then((response) => {
        //console.log(response)
        this.setState({
          loading: false,
          units: response.data
        })
        notification.success(this.props.t('activities.lessonDeleteSuccess'))
      })
      .catch(error => {
        console.log(error)
        this.setState({
          loading: false
        })
        notification.error(this.props.t('activities.lessonDeleteUnsuccess'), error)
      })
  }

  handleUnitDelete = (e: MenuInfo, unitIndex: number) => {
    e.domEvent.stopPropagation()
    confirm({
      title: this.props.t('activities.lessonDeleteQuestion'),
      content:
        this.props.t('activities.lessonDeleteWarning'),
      okText: this.props.t('basic.delete'),
      cancelText: this.props.t('basic.cancel'),
      onOk: () => {
        this.deleteUnitFromActivity(unitIndex)
      }
    })
  }
  //renders
  UnitCollapse = (props: { children: ReactNode }) => {
    return (
      <Collapse
        bordered={false}
        className="units"
        defaultActiveKey={this.props.t('activities.listOfLessons')}
      >
        <Panel
          key={this.props.t('activities.listOfLessons')}
          header={<span>{this.props.t('activities.listOfLessons') + ' <' + this.formatTimeFromNum(this.state.total_time) + '>'}</span>}
          extra={this.addNewToActivityButton((event: MouseEvent) => {
            event.stopPropagation()
            this.setState({
              ...this.state,
              addNewUnitModalVisible: true
            })
          }, this.props.t('activities.lessonAddNew'))}
        >
          {props.children}
        </Panel>
      </Collapse>
    )
  }

  renderUnitComponent(
    unit: Unit,
    tasksUnit: Unit,
    questionsUnit: Unit,
    index: number,
    activity: Activity,
    progress: any
  ) {
    return (
      <UnitComponent
        userID={this.context.user.id}
        activityId={activity.id ? activity.id : -1}
        key={unit.id}
        index={index}
        unit={unit}
        tasksUnit={tasksUnit}
        questionsUnit={questionsUnit}
        owner={this.state.owner}
        progress={progress}
        subscriber={this.state.subscriber}
        total_time={unit.total_time}
        handleEventAssign={this.handleEventAssign}
        handleUnitDelete={this.handleUnitDelete}
        handleUnitEdit={this.handleUnitEdit}
        addTest={this.addTest}
        onCollapseChange={() => this.lazyLoadUnit(index)}
      />
    )
  }

  renderEditableUnits() {
    return (
      <DragDropContext onDragEnd={this.reorderUnits}>
        <this.UnitCollapse>
          <Droppable droppableId="unitPanel">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {this.state.units &&
                  this.state.units.map((unit, index) => {
                    const tasksUnit = this.state.tasksUnit[index]
                    const unitId: number = unit.id ? unit.id : -1
                    const questionsUnit = this.state.questionsUnit[index]
                    const { activity } = this.state
                    const { progress } = this.state
                    if (unit.id && activity && activity.id) {
                      return (
                        <Draggable
                          key={unit.id}
                          index={index}
                          draggableId={unit.id.toString()}
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {this.renderUnitComponent(
                                unit,
                                tasksUnit,
                                questionsUnit,
                                index,
                                activity,
                                progress[unitId]
                              )}
                            </div>
                          )}
                        </Draggable>
                      )
                    } else {
                      return <></>
                    }
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </this.UnitCollapse>
      </DragDropContext>
    )
  }

  renderUnits() {
    return (
      <this.UnitCollapse>
        {this.state.units &&
          this.state.units.map((unit, index) => {
            const tasksUnit = this.state.tasksUnit[index]
            const unitId: number = unit.id ? unit.id : -1
            const questionsUnit = this.state.questionsUnit[index]
            const { activity } = this.state
            const { progress } = this.state
            if (unit.id && activity && activity.id) {
              return this.renderUnitComponent(
                unit,
                tasksUnit,
                questionsUnit,
                index,
                activity,
                progress[unitId]
              )
            } else {
              return <></>
            }
          })}
      </this.UnitCollapse>
    )
  }

  // unit edit
  // opens edit modal
  handleUnitEdit = (e: MenuInfo, unitIndex: number) => {
    e.domEvent.stopPropagation()
    this.setState({
      editingUnit: true,
      editedUnitIndex: unitIndex,
      addNewUnitModalVisible: true,
      unitName: this.state.units[unitIndex].title,
      unitDescription: this.state.units[unitIndex].description
    })
    if (this.unitEditFormRef.current) {
      const { setFieldsValue } = this.unitEditFormRef.current

      setFieldsValue({
        title: this.state.units[unitIndex].title,
        description: this.state.units[unitIndex].description
      })
    }
  }

  // edit and create unit modal ok
  handleEditUnitModalButtonClick = () => {
    if (this.unitEditFormRef.current) {
      this.setState({
        editingUnit: false,
        editedUnitIndex: -1,
        addNewUnitModalVisible: false
      })
      this.unitEditFormRef.current.setFieldsValue({
        title: '',
        description: '',
        activity_ids: []
      })
    } else {
      notification.error(
        this.props.t('activities.errorPageLoad'),
        '[ERROR] EDIT UNIT MODAL - Form reference is null or undefined'
      )
    }
  }
  // unit create
  handleCreateUnitSubmit = (values: Store) => {
    if (!this.state.activity || !this.state.activity.id) {
      this.activityNotInitializedMessage()
      return
    }
    values.activity_ids = [this.state.activity.id]

    if (this.state.editingUnit) {
      api.units
        .updateUnit(
          values as Unit,
          this.state.units[this.state.editedUnitIndex].id
        )
        .then(response => {
          const currentUnits = [...this.state.units]
          currentUnits[this.state.editedUnitIndex].title = response.data.title
          currentUnits[this.state.editedUnitIndex].description =
            response.data.description
          this.setState({
            units: currentUnits
          })
          notification.success(this.props.t('activities.lessonUpdateSuccess'))
          this.handleEditUnitModalButtonClick()
          window.location.reload()
        })
        .catch(error => {
          this.setState({ loading: false })
          if (error.response && error.response.status === 401) {
            notification.error(this.props.t('activities.lessonAutorshipError'), error)

          } else {
            notification.error(this.props.t('activities.lessonUpdateUnsuccess'), error)
          }

          this.handleEditUnitModalButtonClick()
        })
    } else {
      api.units
        .createUnit(values as Unit)
        .then(response => {
          this.setState({
            loading: false
          })
          notification.success(
            this.props.t('activities.lessonAddedToSubject')
          )
          const { units } = this.state
          units.push(response.data)
          this.setState({ ...this.state, units })
          this.handleEditUnitModalButtonClick()
          window.location.reload()
        })
        .catch(() => {
          this.setState({
            loading: false
          })
          notification.error(this.props.t('activities.lessonCreateUnsuccess'))
          this.handleEditUnitModalButtonClick()
        })
    }
  }

  getFilteredUsers = () => {
    let orgId = -1

    for (const [key, value] of Object.entries(
      this.state.organizationTreeValue
    )) {
      if (key === 'value') {
        orgId = Number(value)
      }
    }

    api.users
      .getFilteredUsersSearch({ organization_id: orgId, text: this.searched_text })
      .then(response => {
        const treeData = response.data.reduce(
          (result: LabeledValue[], student: User) => {
            if (student.id) {
              if (student.class == null) {
                result.push({
                  label: `${student.name} <${student.email}>`,
                  value: student.id,
                  time: 0
                })
              } else {
                result.push({
                  label: `${student.name} <${student.email}> <${student.class}>`,
                  value: student.id,
                  time: 0
                })
              }
            }
            return result
          },
          []
        )
        this.setState({
          ...this.state,
          studentsTreeData: treeData
        })
      })
      .catch(error => {
        this.setState({
          loading: false
        })
        notification.error(
          this.props.t('activities.studentsListLoadUnsuccess'),
          error
        )
      })
  }

  updateFilteredEvents = () => {
    let userId = null
    if (this.state.authorsTreeValue.length) {
      userId = this.state.authorsTreeValue.map((e) => e.value)
    }

    //if i want only my activities, ignore the author search bar
    if (this.state.showMyEvents) {
      userId = [this.context.user.id]
    }

    /* Cancel previous request if exists */
    if (getFilteredEventsCancelToken)
      getFilteredEventsCancelToken.cancel('Request canceled due to new request')

    /* Create new cancel token */
    getFilteredEventsCancelToken = axios.CancelToken.source()

    api.events
      .getFilteredEventsMultiple({ users: userId }, getFilteredEventsCancelToken)
      .then(response => {
        if (response.data === null) {
          response.data = []
        }
        this.setState({
          eventsTreeData: response.data.reduce(
            (result: LabeledValue[], event: Event) => {
              if (event.header && event.id) {
                result.push({ label: event.header, value: event.id, time: event.time_to_handle })
              }
              return result
            },
            []
          )
        })
      })
      .catch(error =>
        notification.error(
          this.props.t('activities.errorPageLoad'),
          error
        )
      )
  }

  // remove and add events section
  handleEventTreeChange = (key: LabeledValue[]) => {
    const ret: LabeledValue[] = []

    //prebehnem kazdy predmet ako kokot
    key.forEach(element => {
      let treevalue_data = this.state.eventsTreeValue.find(op => op.value === element.value) as LabeledValue
      let treedata_data = this.state.eventsTreeData.find(op => op.value === element.value) as LabeledValue

      //prekopirujem ulohy co tam uz su ako kokot po 1 lebo inak neviem zachovat casovy udaj; stringify je tam na to aby sa spravila deep copy
      if (treevalue_data) {
        treevalue_data = JSON.parse(JSON.stringify(treevalue_data))
        ret.push(treevalue_data)
      }
      //ak pridavam novu ulohu zo zoznamu; stringify je tam na to aby sa spravila deep copy
      else {
        treedata_data = JSON.parse(JSON.stringify(treedata_data))
        treedata_data.label = this.formatTime(treedata_data.label, treedata_data.time, '<', '>')
        ret.push(treedata_data)
      }
    })

    this.setState({
      eventsTreeValue: ret
    }, () => {
      console.log(this.state.eventsTreeValue)
      this.setState({
        count_time: this.update_time()
      })
    })
  }

  handleAuthorTreeChange = (key: any) => {
    this.setState({ authorsTreeValue: key }, () => {
      this.updateFilteredEvents()
    })
    //console.log(this.state.authorsTreeValue)
  }

  handleOrganizationTreeChange = (key: any) => {
    this.setState({ organizationTreeValue: key }, () => this.getFilteredUsers())
  }
  //handleClassTreeChange = (key: any) => {
  //  this.setState({ classTreeValue: key }, () => this.getFilteredUsers())
  //};

  // handler function on opening add event modal, gets all events, puts them in treeData
  handleEventAssign = async (e: MenuInfo, unitIndex: number) => {
    e.domEvent.stopPropagation()
    this.setState({
      assignUnitEventsModalVisible: true
    })

    /* Cancel previous request if exists */
    if (getFilteredEventsCancelToken)
      getFilteredEventsCancelToken.cancel('Request canceled due to new request')

    /* Create new cancel token */
    getFilteredEventsCancelToken = axios.CancelToken.source()

    /* Cancel previous request if exists */
    if (getAuthorCancelToken)
      getAuthorCancelToken.cancel('Request canceled due to new request')

    /* Create new cancel token */
    getAuthorCancelToken = axios.CancelToken.source()

    api.users.getAllStudents(getAuthorCancelToken).then(response => {
      const data = response.data.reduce(
        (result: LabeledValue[], user: User) => {
          if (user.name && user.id) {
            result.push({
              label: user.name,
              value: user.id,
              time: 0
            })
          }
          return result
        },
        []
      )
      this.setState({
        ...this.state,
        authorsTreeData: data
      })
    })

    //load only my activities by default
    let users = [this.context.user.id]
    if (!this.state.showMyEvents) {
      users = this.state.authorsTreeValue.map((e) => e.value)
    }

    api.events
      .getFilteredEventsMultiple(
        { users: users },
        getFilteredEventsCancelToken
      )
      .then(async response => {
        await this.lazyLoadUnit(unitIndex)
        if (response.data === null) {
          response.data = []
        }
        const { events } = this.state.units[unitIndex]
        const eventsTreeValue = events
          ? events.reduce((result: LabeledValue[], event: Event) => {
            if (event && event.id) {
              result.push({
                value: event.id,
                label: this.formatTime(event.header, event.time_to_handle, ' <', '>'),
                time: event.time_to_handle
              })
            }
            return result
          }, [])
          : []

        const treeData = response.data.reduce(
          (result: LabeledValue[], event: Event) => {
            if (event && event.id) {
              result.push({ label: event.header, value: event.id, time: event.time_to_handle })
            }
            return result
          },
          []
        )
        this.setState({
          editedUnitIndex: unitIndex,
          eventsTreeData: treeData,
          eventsTreeValue
        }, () => {
          this.setState({
            count_time: this.update_time()
          })
        })
      })
      .catch(error => {
        notification.error(
          this.props.t('activities.lessonsLoadUnsuccess'),
          error,
          error
        )
      })
  }

  // on modal cancel
  handleAssignUnitEventCancel = () => {
    this.setState({
      editedUnitIndex: -1,
      eventsTreeValue: [],
      assignUnitEventsModalVisible: false
    })
  }

  // onOK in add events to unit modal
  handleAssignUnitEventOk = () => {
    const selectedEventsIds = this.state.eventsTreeValue.map(
      event => event.value as number
    )
    api.units
      .assignEventsToUnit(
        {
          activity_id: this.state.activity ? this.state.activity.id : undefined,
          event_ids: selectedEventsIds
        },
        this.state.units[this.state.editedUnitIndex].id
      )
      .then(response => {
        const units = [...this.state.units]
        units[this.state.editedUnitIndex].events = response.data
        this.setState({
          units,
          editedUnitIndex: -1,
          eventsTreeValue: [],
          loading: false,
          assignUnitEventsModalVisible: false
        })
        notification.success(this.props.t('activities.tasksAddSuccess'))
        window.location.reload()
      })
      .catch(error => {
        notification.error(
          this.props.t('activities.tasksLoadUnsuccess'),
          error
        )
      })
  }

  handleAddStudentModal = () => {
    this.handleStudentAssign()
    this.handleOrganizationAssign()
  }

  // remove and add students section
  // add student to activity section
  handleStudentTreeChange = (key: LabeledValue[]) => {
    this.setState({ studentsTreeValue: key })
  }

  handleAddStudentsCancel = () => {
    this.setState({
      addStudentsModalVisible: false,
      organizationTreeValue: []
    })
  }

  handleAddAllUsers = () => {
    const allStudents: LabeledValue[] = []

    this.state.studentsTreeData.map(student => allStudents.push(student))
    this.state.studentsTreeValue.map(student => allStudents.push(student))

    this.setState({
      studentsTreeValue: allStudents.reduce((unique: any, o: any) => {
        if (
          !unique.some(
            (obj: { label: any; value: any }) =>
              obj.label === o.label && obj.value === o.value
          )
        ) {
          unique.push(o)
        }
        return unique
      }, [])
    }, () => this.getFilteredUsers())
  }

  // function handler on opening add student modal
  handleStudentAssign = () => {
    this.setState({
      ...this.state,
      addStudentsModalVisible: true
    })

    /* Cancel previous request if exists */
    if (getAllStudentsCancelToken)
      getAllStudentsCancelToken.cancel('Request canceled due to new request')

    /* Create new cancel token */
    getAllStudentsCancelToken = axios.CancelToken.source()

    api.users
      .getAllStudents(getAllStudentsCancelToken)
      .then(response => {
        const studentsTreeValue = this.state.students.reduce(
          (result: LabeledValue[], student: User) => {
            if (student.id) {
              if (student.class == null) {
                result.push({
                  label: `${student.name} <${student.email}>`,
                  value: student.id,
                  time: 0
                })
              } else {
                result.push({
                  label: `${student.name} <${student.email}> <${student.class}>`,
                  value: student.id,
                  time: 0
                })
              }
            }
            return result
          },
          []
        )
        const treeData = response.data.reduce(
          (result: LabeledValue[], student: User) => {
            if (student.id) {
              if (student.class == null) {
                result.push({
                  label: `${student.name} <${student.email}>`,
                  value: student.id,
                  time: 0
                })
              } else {
                result.push({
                  label: `${student.name} <${student.email}> <${student.class}>`,
                  value: student.id,
                  time: 0
                })
              }
            }
            return result
          },
          []
        )
        this.setState({
          ...this.state,
          studentsTreeData: treeData,
          studentsTreeValue
        })
      })
      .catch(error => {
        this.setState({
          loading: false
        })
        notification.error(this.props.t('activities.studentsLoadUnsuccess'), error)
      })
  }

  // function handler after confirming student selection
  handleAddStudentsOk = () => {
    if (!this.state.activity || !this.state.activity.id) {
      this.activityNotInitializedMessage()
      return
    }
    const selectedStudentsIds = this.state.studentsTreeValue.map(
      student => student.value as number
    )

    api.activities
      .assignActivitySubscribers(selectedStudentsIds, this.state.activity.id)
      .then(response => {
        this.setState({
          ...this.state,
          studentsTreeValue: [],
          addStudentsModalVisible: false,
          students: response.data,
          organizationTreeValue: []
        })
        notification.success(
          this.props.t('activities.studentsListUpdateSuccess')
        )
      })
      .catch(error => {
        this.setState({
          loading: false
        })
        notification.error(
          this.props.t('activities.studentsListUpdateUnsuccess'),
          error
        )
      })
  }

  removeAllStudents = () => {
    if (!this.state.activity || !this.state.activity.id) {
      this.activityNotInitializedMessage()
      return
    }

    api.activities
      .assignActivitySubscribers([], this.state.activity.id)
      .then(response => {
        this.setState({
          students: response.data
        })
        notification.success(
          this.props.t('activities.studentsListUpdateSuccess')
        )
      })
      .catch(error => {
        this.setState({
          loading: false
        })
        notification.error(
          this.props.t('activities.studentsListUpdateUnsuccess'),
          error
        )
      })
  }

  // remove students section
  // assign students without deleted one
  removeStudent = (idToDelete?: number) => {
    if (!this.state.activity || !this.state.activity.id) {
      this.activityNotInitializedMessage()
      return
    }
    const ids = this.state.students.reduce((idsSoFar: number[], student) => {
      if (student.id && student.id !== idToDelete) {
        idsSoFar.push(student.id)
      }
      return idsSoFar
    }, [])

    api.activities
      .assignActivitySubscribers(ids, this.state.activity.id)
      .then(() => {
        const students = [...this.state.students]
        students.splice(
          students.findIndex(student => student.id === idToDelete),
          1
        )
        this.setState({
          students
        })
        notification.success(
          this.props.t('activities.studentsListUpdateSuccess')
        )
      })
      .catch(error => {
        this.setState({
          loading: false
        })
        notification.error(
          this.props.t('activities.studentsListUpdateUnsuccess'),
          error
        )
      })
  }
  // popconfirm to confirm student removal
  confirmStudentRemove = (idToDelete?: number, name?: string) => {
    confirm({
      title: this.props.t('activities.studentRemoveQuestion'),
      content: this.props.t('activities.studentRemoveWarning'),
      cancelText: this.props.t('basic.cancel'),
      okText: this.props.t('basic.remove'),
      onOk: () => this.removeStudent(idToDelete)
      // onCancel() {}
    })
  }

  // popconfirm to confirm student removal
  confirmAllStudentRemove = () => {
    confirm({
      title: this.props.t('activities.'),
      content: this.props.t('activities.'),
      cancelText: this.props.t('basic.cancel'),
      okText: this.props.t('basic.remove'),
      onOk: () => this.removeAllStudents()
      // onCancel() {}
    })
  }

  // function handler on opening add student modal
  handleOrganizationAssign = () => {
    api.organizations.getAllOrganizations().then(response => {
      const organizationTreeData = response.data.reduce(
        (result: LabeledValue[], organization: Organization) => {
          if (organization.id) {
            result.push({
              value: organization.id,
              label: organization.name,
              time: 0
            })
          }
          return result
        },
        []
      )

      this.setState({
        ...this.state,
        organizationTreeData: organizationTreeData
      })
    })
    // .catch(error => {
    //   this.setState({
    //     loading: false
    //   })
    //   notificationService.error('Nepodarilo sa načítať študentov', error)
    // })
  }

  handleMyEventsSwitch = (checked: boolean) => {
    this.setState(
      {
        showMyEvents: checked
      },
      () => {
        this.updateFilteredEvents()
      }
    )
  }

  handleFckngCancel = () => {
    this.handleEditUnitModalButtonClick()
    this.setState({
      editingUnit: false,
      addNewUnitModalVisible: false
    })
  }

  formatTime = (label: React.ReactNode, time: number, start_sep: string, end_sep: string) => {
    if (time < 60) {
      return label + start_sep + time.toString() + 's' + end_sep
    } else if (time < 3600) {
      return label + start_sep + Math.floor(time / 60).toString() + 'm ' + (time % 60).toString() + 's' + end_sep
    }
    return label + start_sep + Math.floor(time / 3600).toString() + 'h ' + Math.floor((time % 3600) / 60).toString() + 'm ' + (time % 60).toString() + 's' + end_sep
  }

  formatTimeFromNum(time: number) {
    if (time < 60) {
      return time.toString() + 's'
    } else if (time < 3600) {
      return Math.floor(time / 60).toString() + 'm ' + (time % 60).toString() + 's'
    }
    return Math.floor(time / 3600).toString() + 'h ' + Math.floor((time % 3600) / 60).toString() + 'm ' + (time % 60).toString() + 's'
  }


  update_time = () => {
    let time = 0

    this.state.eventsTreeValue.forEach(element => {
      time += element.time
    })

    return this.formatTime('', time, '', '')
  }

  render() {
    const filteredEventsTreeData = this.state.eventsTreeData.filter(
      o => !this.state.eventsTreeValue.includes(o)
    )
    const filteredStudentsTreeData = this.state.studentsTreeData.filter(
      o => !this.state.studentsTreeValue.includes(o)
    )
    return (
      <React.Fragment>
        {this.state.saving && <Loading />}
        <Modal
          closable
          bodyStyle={{ padding: '0' }}
          centered
          title={
            this.state.editingUnit
              ? this.props.t('activities.lessonEdit')
              : this.props.t('activities.lessonAddToSubject')
          }
          visible={this.state.addNewUnitModalVisible}
          onCancel={this.handleFckngCancel}
          footer={[
            <Button key="back" onClick={this.handleEditUnitModalButtonClick}>
              Zrušiť
            </Button>,
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              loading={this.state.loading}
              onClick={() =>
                this.unitEditFormRef.current &&
                this.unitEditFormRef.current.submit()
              }
            >
              {this.state.editingUnit ? this.props.t('activities.lessonEdit') : this.props.t('activities.lessonAdd')}
            </Button>
          ]}
        >
          <Card style={{ border: '0' }}>
            <Form
              id="edit-unit-form"
              ref={this.unitEditFormRef}
              onFinish={this.handleCreateUnitSubmit}
              className="new-unit-form"
              style={{ paddingTop: 20 }}
              initialValues={{
                title: this.state.unitName,
                description: this.state.unitDescription
              }}
            >
              <Form.Item
                label={this.props.t('activities.title')}
                name="title"
                rules={[
                  {
                    required: true,
                    message: this.props.t('activities.enterTitle')
                  }
                ]}
              >
                <Input
                  prefix={<FormOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                />
              </Form.Item>
              <Form.Item
                label={this.props.t('activities.description')}
                name="description"
                rules={[
                  {
                    required: true,
                    message: this.props.t('activities.enterDescription')
                  }
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Form>
          </Card>
        </Modal>

        <Modal
          bodyStyle={{ padding: '0' }}
          centered
          closable
          destroyOnClose
          title={this.props.t('activities.tasksAddFromLesson')}
          okText={this.props.t('menu.addTask')}
          cancelText={this.props.t('basic.cancel')}
          visible={this.state.assignUnitEventsModalVisible}
          onOk={this.handleAssignUnitEventOk}
          onCancel={this.handleAssignUnitEventCancel}
        >
          <Card style={{ border: '0' }}>

            <h3>Celkový čas úloh:</h3>
            <div style={{ marginBottom: 10 }}>
              <p>
                {this.state.count_time}
              </p>
            </div>

            <h3>Filter</h3>
            <div style={{ marginBottom: 10 }}>
              {this.props.t('activities.tasksDisplayMy')}
              <Switch
                checked={this.state.showMyEvents}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={this.handleMyEventsSwitch}
                style={{ marginLeft: 20 }}
              />
            </div>

            <h4>{this.props.t('activities.authorsList')}</h4>
            <div style={{ marginBottom: 10 }}>
              <Select
                mode="multiple"
                disabled={this.state.showMyEvents}
                labelInValue
                optionFilterProp="label"
                value={this.state.authorsTreeValue}
                placeholder={this.props.t('activities.authorsSelect')}
                onChange={this.handleAuthorTreeChange}
                style={{ width: '100%' }}
              >
                {this.state.authorsTreeData.map(author => (
                  // props "value" is passed to values as a key
                  // label props is used to filter options
                  <Option
                    value={author.value}
                    key={author.value}
                    label={author.label}
                  >
                    {author.label}
                  </Option>
                ))}
              </Select>
            </div>

            <h4>{this.props.t('activities.assignmentList')}</h4>
            <div style={{ paddingBottom: 20 }}>
              <Select
                mode="multiple"
                labelInValue
                optionFilterProp="label"
                value={this.state.eventsTreeValue}
                placeholder={this.props.t('activities.lessonsSelect')}
                onChange={this.handleEventTreeChange}
                style={{ width: '100%' }}
              >
                {filteredEventsTreeData.map(event => (
                  // props "value" is passed to values as a key
                  // label props is used to filter options
                  <Option
                    value={event.value}
                    key={event.value}
                    label={event.label}
                    time={event.time}
                  >
                    {this.formatTime(event.label, event.time, ' <', '>')}
                  </Option>
                ))}
              </Select>

              <small>{this.props.t('activities.taskEditWarning')}</small>
            </div>
          </Card>
        </Modal>

        <Modal
          bodyStyle={{ padding: '0' }}
          // width={400}
          centered
          closable
          destroyOnClose
          title={this.props.t('activities.subjectAddStudents')}
          visible={this.state.addStudentsModalVisible}
          onCancel={this.handleAddStudentsCancel}
          footer={[
            <Button key="addAllStudents" onClick={this.handleAddAllUsers}>
              {this.props.t('activities.addAll')}
            </Button>,
            <Button
              key="addStudentsCancel"
              onClick={this.handleAddStudentsCancel}
            >
              {this.props.t('basic.cancel')}
            </Button>,
            <Button
              key="addStudentsOk"
              type="primary"
              onClick={this.handleAddStudentsOk}
            >
              {this.props.t('activities.addStudents')}
            </Button>
          ]}
        >
          <Card style={{ border: '0' }}>
            <h4>{this.props.t('activities.organizations')}</h4>
            <div style={{ paddingBottom: 10 }}>
              <Select
                labelInValue
                optionFilterProp="label"
                value={this.state.organizationTreeValue}
                placeholder={this.props.t('activities.selectOrganizationType')}
                onChange={this.handleOrganizationTreeChange}
                style={{ width: '50%' }}
              >
                {this.state.organizationTreeData.map(item => (
                  <Option
                    value={item.value}
                    key={item.value}
                    label={item.label}
                  >
                    {item.label}
                  </Option>
                ))}
              </Select>
            </div>

            <h4>{this.props.t('activities.filter')}</h4>
            <div style={{ paddingBottom: 10 }}>
              <Input.Search
                name="search_bar"
                placeholder={this.props.t('dashboard.enterSearchText')}
                onChange={(e) => {
                  this.searched_text = e.target.value
                }}
                onSearch={(e) => {
                  this.searched_text = e
                  this.getFilteredUsers()
                }}

              />
            </div>

            <h4>{this.props.t('activities.students')}</h4>
            <div style={{ paddingBottom: 20 }}>
              <Select
                mode="multiple"
                labelInValue
                optionFilterProp="label"
                value={this.state.studentsTreeValue}
                placeholder={this.props.t('activities.selectStudents')}
                onChange={this.handleStudentTreeChange}
                style={{ width: '100%' }}
              >
                {filteredStudentsTreeData.map(student => (
                  <Option
                    key={student.value}
                    value={student.value}
                    label={student.label}
                  >
                    {student.label}
                  </Option>
                ))}
              </Select>
            </div>
          </Card>
        </Modal>

        {this.state.loading ? (
          <Loading />
        ) : (
          <div className="style">
            {this.state.editing && this.state.activity ? (
              <ActivityForm
                activity={this.state.activity}
                onSubmit={this.onSubmit}
                //studyFields={this.state.studyFields}
                onCancel={this.onCancelEdit} />
            ) : (
              this.renderActivity()
            )}
            {this.state.editing
              ? this.renderEditableUnits()
              : this.renderUnits()}
            <div className="style">
              <Row>
                <Col span={3}>
                  {this.state.komisar ? (
                    <Button>
                      <LikeOutlined />
                      {this.props.t('activities.validate')}
                    </Button>
                  ) : (
                    <> </>
                  )}
                </Col>
              </Row>
            </div>
            {this.state.owner ? (
              <Collapse bordered={false} className="students">
                <Panel
                  key={this.props.t('activities.studentsList')}
                  header={this.props.t('activities.studentsList')}
                  extra={[
                    this.addNewToActivityButton(
                      this.handleAddStudentModal,
                      this.props.t('activities.subjectAddStudents')
                    ),
                    this.removeFromActivityButton(
                      this.confirmAllStudentRemove,
                      this.props.t('activities.subjectRemoveStudents')
                    )
                  ]}
                >
                  <StudentsTable
                    data={this.state.students}
                    onDelete={this.confirmStudentRemove} />
                </Panel>
              </Collapse>
            ) : (
              <> </>
            )}
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default withTranslation()(withRouter(ActivityDetail))