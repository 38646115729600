import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import React, { Component } from 'react'

/* Css */
import './Footer.scss'

/* i18n */
import { WithTranslation, withTranslation } from 'react-i18next'


interface IProps extends RouteComponentProps, WithTranslation {
}

interface IState {
  selectedLanguage: string;
}

class Footer extends Component<IProps, IState> {
  render() {
    const { t, i18n } = this.props
    const section1Items = t('titlePage.footer.section1.items', { returnObjects: true }) as Array<any>
    const section2Items = t('titlePage.footer.section2.items', { returnObjects: true }) as Array<any>

    return (
      <footer className="eceh-footer">
        <section className="eceh-footer-left">
          <div className="eceh-footer__title-container">
            <h2 className="eceh-footer__title">{t('titlePage.footer.title')}</h2>

            <div className="eceh-footer__languages-container">
              <div className="eceh-footer-language" onClick={() => i18n.changeLanguage('en')}>
                <img src="/images/title-page/flags/en.svg" alt="English" />
                <span>English</span>
              </div>
              <div className="eceh-footer-language" onClick={() => i18n.changeLanguage('sk')}>
                <img src="/images/title-page/flags/svk.svg" alt="Slovenčina" />
                <span>Slovenčina</span>
              </div>
            </div>

            <p className="eceh-footer__description">{t('titlePage.footer.description')}</p>
          </div>

          <div className="eceh-footer__socials">
            <a
              href="https://www.norwaygrants.sk/"
              className="eceh-footer__socials-grants"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/title-page/norway-grants.svg" alt="Logo of Norway Grants" />
            </a>

            <a
              href="https://www.fiit.stuba.sk/"
              className="eceh-footer__socials-fiit"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/title-page/fiit-stu.svg"
                alt="Faculty of Informatics and Information Technologies STU"
              />
            </a>
          </div>
        </section>

        <div className="eceh-footer-right">
          <section className="eceh-footer__site">
            <h3>{t('titlePage.footer.section1.title')}</h3>
            {section1Items.map(({ label, href, external }) => !!external ?
              <a href={href} key={label}>{label}</a> : <Link to={href} key={label}>{label}</Link>)}
          </section>

          <section className="eceh-footer__address">
            <h3>{t('titlePage.footer.section2.title')}</h3>
            {section2Items.map(label => <span key={label}>{label}</span>)}
          </section>
        </div>
      </footer>
    )
  }
}

export default withTranslation()(withRouter(Footer))