import axios, { AxiosResponse, CancelTokenSource } from 'axios'
import { Event, Option, Help, Activity, Unit, Exam, User, Organization, Student } from '../models'
import { apiBaseUrl } from '../config'
import { initialAppState } from './generalHelpers'


const api = axios.create({
  baseURL: `${apiBaseUrl}/api/`,
  responseType: 'json'
})

api.interceptors.response.use(response => response, error => {
  const errorCode = error?.response?.status
  const requestPath = error?.response?.config?.url
  if ((errorCode === 401 || errorCode === 403) && requestPath !== '/login') {
    localStorage.appState = JSON.stringify(initialAppState)
    window.location.reload()
  }
  return Promise.reject(error)
})

export default {
  global: {
    setToken(token: string) {
      return (api.defaults.headers.common.token = token)
    },
    setHeader(headerName: string, value: any) {
      return (api.defaults.headers.common[headerName] = value)
    },
    removeHeader(headerName: string) {
      delete api.defaults.headers.common[headerName]
    },
    setCsrfToken(csrftoken: string) {
      return (api.defaults.headers.post['X-CSRF-TOKEN'] = csrftoken)
    }
  },
  auth: {
    register(payload: {
      firstname: string;
      lastname: string;
      confirm_private_policy: boolean;
      email: string;
      password: string;
      c_password: string;
      organization_id: number;
      class: string | null;
    }) {
      return api.post('/register', payload)
    },
    login(payload: { email: string; password: string }) {
      return api.post('/login', payload)
    },
    checkIfEmailExists(payload: { email: string }) {
      return api.post('/email/check', payload)
    },
    decode(payload: { url: string }) {
      return api.post(`/decode`, payload)
    },
    invite(payload: { array: Array<Array<string>>; }) {
      return api.post('/invite', payload)
    },
    requestInvite(payload: { array: Array<Array<string>>; }) {
      return api.post('/requestInvite', payload)
    },
    generateResetToken(payload: { email: string }) {
      return api.post('/password/create', payload)
    },
    checkToken(token: string) {
      return api.get(`/password/find/${token}`)
    },
    resetPassword(payload: { email: string; password: string; c_password: string; token: string }) {
      return api.post('/password/reset', payload)
    },
    update(payload: { unit_id: number; activity_id: number }) {
      return api.post('/update', payload)
    },
    testingStarted(payload: { unit_id: number; activity_id: number }) {
      return api.post('/information_testing', payload)
    }
  },
  events: {
    //could be possibly removed but it may be usefull
    getFilteredEvents(data: object, source: CancelTokenSource): Promise<AxiosResponse<Event[]>> {
      return api.post('/events/filter', data, {
        cancelToken: source.token
      })
    },
    getFilteredEventsSearch(data: object, source: CancelTokenSource): Promise<AxiosResponse<Event[]>> {
      return api.post('/events/filterSearch', data, {
        cancelToken: source.token
      })
    },
    getFilteredEventsMultiple(data: object, source: CancelTokenSource): Promise<AxiosResponse<Event[]>> {
      return api.post('/events/filterMultiple', data, {
        cancelToken: source.token
      })
    },

    getEventWithoutHelps(id: number) {
      return Promise.all([this.getEvent(id), this.getEventOptions(id)])
    },
    getWholeEvent(id: number) {
      return Promise.all([this.getEvent(id), this.getEventOptions(id), this.getEventHelp(id)])
    },
    deleteEvent(id: number) {
      return api.delete(`/events/${id}`)
    },
    getEvent(id: number): Promise<AxiosResponse<Event>> {
      return api.get(`/events/${id}`)
    },
    getEventOptions(id?: number): Promise<AxiosResponse<Option[]>> {
      if (!id) {
        throw new Error('[ERROR] HTTP GET OPTIONS: EventId not provided')
      }
      return api.get(`/events/${id}/options`)
    },
    createEvent(event: Event): Promise<AxiosResponse<Event>> {
      return api.post('/events', event)
    },
    updateEvent(event: Event) {
      return api.put(`/events/${event.id}`, event)
    },
    getEventHelp(id?: number): Promise<AxiosResponse<Help[]>> {
      if (!id) {
        throw new Error('[ERROR] HTTP GET HELP: EventId not provided')
      }
      return api.get(`events/${id}/event_helps`)
    },
    getEventTypes(id: number) {
      return api.get(`events/${id}/event_types`)
    },
    sendEventProgress(payload: { unit_id: number; activity_id: number; correct_answer: boolean, unit_time: number }) {
      return api.post('/event_progress', payload)
    }
    /*,
    getAllEvents(): Promise<AxiosResponse<Event[]>> {
      return api.get('/events')
    } */
  },
  options: {
    deleteAllOptions(eventId: number) {
      return api.delete(`/events/${eventId}/options`)
    },
    createOptions(options: Option[], event_id: number) {
      if (options.length > 0) {
        const optionPromises = options.map((option) =>
          api.post('/options', { ...option, event_id })
        )
        return Promise.all(optionPromises)
      }
      return Promise.all([])
    },

    deleteOptions(idsOfOptionsToDelete: number[]) {
      if (idsOfOptionsToDelete.length > 0) {
        const deletePromises = idsOfOptionsToDelete.map((id) => api.delete(`/options/${id}`))
        return Promise.all(deletePromises)
      }
      return Promise.all([])
    },
    editOptions(optionsToEdit: Option[]) {
      return api.put('/options', optionsToEdit)
    },

    handleOptions(options: Option[], idsOfDeletedOptions: number[], event_id: number) {
      if (options.length > 0) {
        return Promise.all([
          this.editOptions(options.filter((option) => option.id !== undefined)),
          this.deleteOptions(idsOfDeletedOptions),
          this.createOptions(
            options.filter((option) => option.id === undefined),
            event_id
          )
        ])
      }
    }
  },
  help: {
    createHelps(helps: Help[], event_id: number) {
      if (helps.length > 0) {
        const helpPromises = helps.map((help) => api.post('/helps', { ...help, event_id }))
        return Promise.all(helpPromises)
      }
      return Promise.all([])
    },

    deleteHelps(idsOfHelpsToDelete: number[]) {
      if (idsOfHelpsToDelete.length > 0) {
        const helpPromises = idsOfHelpsToDelete.map((id) => api.delete(`/helps/${id}`))
        return Promise.all(helpPromises)
      }
      return Promise.all([])
    },

    editHelps(helpsToEdit: Help[]) {
      if (helpsToEdit.length > 0) {
        const helpPromises = helpsToEdit.map((help) => api.put(`/helps/${help.id}`, help))
        return Promise.all(helpPromises)
      }
      return Promise.all([])
    },

    handleHelps(helps: Help[], idsOfDeletedHelps: number[], event_id: number) {
      if (helps.length > 0 || idsOfDeletedHelps.length > 0) {
        return Promise.all([
          this.editHelps(helps.filter((option) => option.id !== undefined)),
          this.deleteHelps(idsOfDeletedHelps),
          this.createHelps(
            helps.filter((option) => !option.id),
            event_id
          )
        ])
      }
    }
  },
  activities: {
    getFilteredActivities(
      data: object,
      source: CancelTokenSource
    ): Promise<AxiosResponse<Activity[]>> {
      return api.post('/activity/filter', data, { cancelToken: source.token })
    },
    getSubscribedActivities(
      data: object,
      source: CancelTokenSource
    ): Promise<AxiosResponse<Activity[]>> {
      return api.post('/activity/subscribedSearch', data, { cancelToken: source.token })
    },
    getRegisteredActivities(userId: number): Promise<AxiosResponse<Activity[]>> {
      return api.get(`/activity/subscribed/${userId}`)
    },
    getActivity(activityId: number): Promise<AxiosResponse<{ activity: Activity }>> {
      return api.get(`/activity/${activityId}`)
    },
    createActivity(activity: Activity): Promise<AxiosResponse<Activity>> {
      return api.post('/activity', activity)
    },
    editActivity(activity: Activity, activityId?: number) {
      if (!activityId) {
        throw new Error('[ERROR] HTTP PUT ACTIVITY: ActivityId not provided')
      }
      return api.put(`/activity/${activityId}`, activity)
    },
    deleteActivity(activityId: number) {
      return api.delete(`/activity/${activityId}`)
    },
    getActivityUnits(activityId: number): Promise<AxiosResponse<Unit[]>> {
      return api.get(`/activity/${activityId}/units`)
    },
    orderActivityUnits(orderedUnitIds: number[], activityId?: number) {
      if (!activityId) {
        throw new Error('[ERROR] ORDER ACTIVITY UNITS: ActivityId not provided')
      }
      return api.put(`/activity/${activityId}/order/units`, {
        unit_ids: orderedUnitIds
      })
    },
    removeActivityUnit(unitId?: number, activityId?: number) {
      if (!activityId) {
        throw new Error('[ERROR] ASSIGN ACTIVITY UNITS: ActivityId not provided')
      }
      return api.put(
        `/activity/${activityId}/units`, { unit_id: unitId }
      )
    },
    cloneActivity(activityId: number): Promise<AxiosResponse<{ activity: Activity }>> {
      return api.post(`/activity/${activityId}/clone`)
    },
    getActivitySubscribers(activityId: number): Promise<AxiosResponse<User[]>> {
      return api.get(`/activity/${activityId}/subscribers`)
    },
    assignActivitySubscribers(subscriber_ids: number[], activityId: number) {
      return api.put(`/activity/${activityId}/student`, {
        student_ids: subscriber_ids
      })
    },
    getUserProgress(activityId?: number, unitId?: number) {
      return api.get(`/activity/${activityId}/${unitId}`)
    },
    assignUserProgress(activity_id?: number, unit_id?: number, states?: number[]) {
      return api.put('/activity/save', { activity_id, unit_id, states })
    }
  },
  units: {
    getUnit(unitId: number) {
      return api.get(`/units/${unitId}`)
    },
    getUnitsTests(activityId: number, units: Unit[]) {
      const unitTestPromises = units.map((unit) =>
        api.get(`/unit/${unit.id}/activity/${activityId}/exams`)
      )
      return Promise.all(unitTestPromises)
    },
    getUnitTests(activityId?: number, unitId?: number) {
      if (!activityId || !unitId) {
        throw new Error(
          `[ERROR] HTTP GET UNIT TESTS: ActivityId (${activityId}) or unitId (${unitId}) not provided`
        )
      }
      return api.get(`/unit/${unitId}/activity/${activityId}/exams`)
    },
    getUnitsEvents(units: Unit[]) {
      const events = units.map((unit) => api.get(`/units/${unit.id}/events`))
      return Promise.all(events)
    },
    getUnitEvents(unitId?: number): Promise<AxiosResponse<Event[]>> {
      if (!unitId) {
        throw new Error('[ERROR] HTTP GET UNIT EVENTS: UnitId not provided')
      }
      return api.get(`/units/${unitId}/events`)
    },
    createUnit(unit: Unit): Promise<AxiosResponse<Unit>> {
      return api.post('/units', unit)
    },
    updateUnit(unit: Unit, unitId?: number): Promise<AxiosResponse<Unit>> {
      if (!unitId) {
        throw new Error('[ERROR] HTTP PUT UNIT: UnitId not provided')
      }
      return api.put(`/units/${unitId}`, unit)
    },
    assignEventsToUnit(payload: { activity_id?: number; event_ids: number[] }, unitId?: number) {
      if (!payload.activity_id || !unitId) {
        throw new Error(
          `[ERROR] ASSIGN EVENTS TO UNIT: ActivityId (${payload.activity_id}) or UnitId (${unitId}) not supplied`
        )
      }
      return api.put(`/units/${unitId}/events`, payload)
    },
    unitSummary(activity_id: number, unit_id: number): Promise<AxiosResponse<Student[]>> {
      return api.get(`/unit_summary/${activity_id}/${unit_id}`)
    },
    sendResults(payload: { activity_id: number; unit_id: number; user_ids: number[] }) {
      return api.post('/send_results', payload)
    }
  },
  users: {
    getAllStudents(source: CancelTokenSource): Promise<AxiosResponse<User[]>> {
      return api.get('/users', { cancelToken: source.token })
    },
    getUserInfo(userId: number): Promise<AxiosResponse<any>> {
      return api.get(`/users/${userId}`)
    },
    changePassword(payload: {
      email: string;
      password: string;
      password_new: string;
      c_password_new: string;
    }) {
      return api.post('/password/change', payload)
    },
    getFilteredUsers(args: object): Promise<AxiosResponse<User[]>> {
      return api.post('/users/filter', args)
    },
    //mozno treba cancellation token \_o_/
    getFilteredUsersSearch(payload: { organization_id: number, text: string }): Promise<AxiosResponse<User[]>> {
      return api.post('/users/filterSearch', payload)
    },
    sendEmail(payload: { email: string }) {
      return api.post('/users/sendEmail', payload)
    },
    changeUserInfo(payload: { name: string; email: string, organization_id: number, class: string }) {
      return api.put('users/changeUserInfo', payload)
    }
  },
  organizations: {
    getAllOrganizations(): Promise<AxiosResponse<Organization[]>> {
      return api.get('/organization/all')
    }
  },
  exams: {
    getUserAnswers(examId?: number, userId?: number): Promise<AxiosResponse<any[]>> {
      if (!examId || !userId) {
        throw new Error(`[ERROR] HTTP GET ExamId (${examId}) or UserId (${userId}) not provided`)
      }
      return api.get(`/exam/${examId}/user/${userId}`)
    },
    submitExam(
      examId: number,
      answers: {
        answer: string;
        start_time: string;
        end_time: string;
        time_spent: number;
        obtained_points: number;
        event_id: number;
      }[]
    ) {
      return api.post(`/exam/${examId}/createEventTestAnswers`, answers)
    },
    createExam(payload: Exam) {
      return api.post('/exam', {
        ...payload,
        event_ids: payload.events.map((event) => event.id)
      })
    },
    getExam(examId: number): Promise<AxiosResponse<Exam>> {
      return api.get(`/exam/${examId}`)
    },
    getExamEventsOptions(events: Event[]) {
      const eventsOptionsPromises = events.map((event) => api.get(`/events/${event.id}/options`))
      return Promise.all(eventsOptionsPromises)
    },
    updateExam(examId: number, exam: Exam) {
      return api.put(`/exam/${examId}`, {
        ...exam,
        event_ids: exam.events.map((event) => event.id)
      })
    },
    checkSubmitted(examId: number): Promise<AxiosResponse<any[]>> {
      return api.get(`/exam/${examId}/checkSubmitted`)
    }
  },
  images: {
    uploadImage(payload: FormData) {
      return api.post('/images/upload', payload, { headers: { 'content-type': 'multipart/form-data' } })
    }
  },
  updateWholeEvent(
    event: Event,
    options: Option[],
    idsOfDeletedOptions: number[],
    helps: Help[],
    idsOfDeletedHelps: number[]
  ) {
    return Promise.all([
      this.events.updateEvent(event),
      this.options.handleOptions(options, idsOfDeletedOptions, event.id ? event.id : -1),
      this.help.handleHelps(helps, idsOfDeletedHelps, event.id ? event.id : -1)
    ])
  },
  createEventOptionsAndHelps(options: Option[], helps: Help[], event_id: number) {
    return Promise.all([
      this.options.createOptions(options, event_id),
      this.help.createHelps(helps, event_id)
    ])
  }
}
