import React from 'react'
import {SendOutlined} from '@ant-design/icons'
import {withRouter, RouteComponentProps} from 'react-router-dom'
import {Table, Button, Tag, Progress, Tooltip, Modal, Card, Select} from 'antd'
import notification from '../../../../utils/notificationService'
import api from '../../../../utils/ApiAxios'
import {Student} from './../../../../models'
import 'antd/dist/antd.css'
import './Panel.css'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'

const {Option} = Select

type Status = 'Vo vyučovacej hodine' | 'Offline' | 'Mimo vyučovacej hodiny'

const toStatus = (status: boolean | null): Status => {
  if (status === true) {
    return 'Vo vyučovacej hodine'
  } else if (status === false) {
    return 'Mimo vyučovacej hodiny'
  } else {
    return 'Offline'
  }
}

// Time format from millisecond
const formatTime = (milliseconds: number) => {
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

interface IRouteParams {
  activityId: string
  unitId: string
}

interface IProps extends RouteComponentProps<IRouteParams>, WithTranslation {
  students: Student[]
}
interface IState {
  selectedStudents: Student[]
  selectedRowKeys: any[]
  isSendOutModalVisible: boolean
}

const columns = [
  {
    title: 'Meno žiaka',
    dataIndex: 'name',
    width: '15%',
    sorter: (a: {name: string}, b: {name: string}) =>
      a.name.localeCompare(b.name)
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status: boolean) => {
      let color = '#cccccc'
      let statusString = toStatus(null)
      let tooltipText = 'Žiak je odhlásený.'
      if (status) {
        color = 'green'
        statusString = toStatus(status)
        tooltipText = 'Žiak aktuálne pracuje na vyučovacej hodine.'
      }
      if (status === false) {
        color = 'orange'
        statusString = toStatus(status)
        tooltipText =
          'Žiak je prihlásený ale nepracuje na aktuálnej vyučovacej hodine.'
      }
      return (
        <div>
          <Tooltip placement="topLeft" title={tooltipText}>
            <Tag color={color}>{statusString}</Tag>
          </Tooltip>
        </div>
      )
    },
    width: '20%',
    sorter: (a: {status: boolean}, b: {status: boolean}) =>
      toStatus(a.status).length - toStatus(b.status).length
  },
  {
    title: 'Úspešnosť',
    dataIndex: ['best_result', 'total_questions'],
    width: '45%',
    render: (text: number, record: Student) => (
      <React.Fragment>
        <Tooltip
          placement="topLeft"
          title="Najlepšie dosiahnutý výsledok zo všetkých pokusov."
        >
          <span className="panel-progress-bar">
            <Progress
              strokeColor={{
                from: '#ff9e00',
                to: '#87d068'
              }}
              percent={Math.round(
                (record.best_result / record.total_questions) * 100
              )}
            />
          </span>
        </Tooltip>
        <Tooltip title="Počet správne odpovedaných otázok">
          <span className="panel-completed-tasks">
            {record.best_result}/{record.total_questions}
          </span>
        </Tooltip>
      </React.Fragment>
    ),
    sorter: (a: {best_result: number}, b: {best_result: number}) =>
      a.best_result - b.best_result
  },
  {
    title: 'Pokusy',
    dataIndex: 'attempts',
    width: '5%',
    render: (attempts: number) => (
      <div className="text-columns">
        <Tooltip placement="topRight" title="Celkový počet spustení vyučovacej hodiny.">
          <span>{attempts}</span>
        </Tooltip>
      </div>
    ),
    sorter: (a: {attempts: number}, b: {attempts: number}) =>
      a.attempts - b.attempts
  },
  {
    title: 'Celkový Čas',
    dataIndex: 'events_time',
    width: '7.5%',
    render: (events_time: number) => (
      <div className="text-columns">
        <Tooltip placement="topRight" title="Celkový čas vyučovacej hodiny.">
          <span>{formatTime(events_time)}</span>
        </Tooltip>
      </div>
    ),
  },
  {
    title: 'Čas ',
    dataIndex: 'time',
    width: '7.5%',
    render: (time: number) => (
      <div className="text-columns">
        <Tooltip placement="topRight" title="Celkový čas vyučovacej hodiny.">
          <span>{formatTime(time)}</span>
        </Tooltip>
      </div>
    ),
  }
]

  class Panel extends React.Component<IProps, IState> {
    constructor(props: IProps) {
      super(props)
      this.state = {
        selectedStudents: [],
        selectedRowKeys: [], // Check here to configure the default column
        isSendOutModalVisible: false
      }
    }

    // not working string typ on selectedRowKeys
    onSelectChange = (selectedRowKeys: any, selectedStudents: Student[]) => {
      this.setState({
        selectedRowKeys,
        selectedStudents
      })
    }

    handleSendOutModalButtonClick = () => {
      this.setState({
        isSendOutModalVisible: true
      })
    }

    handleSendOutModalOk = () => {
      const activity_id = parseInt(this.props.match.params.activityId, 10)
      const unit_id = parseInt(this.props.match.params.unitId, 10)
      const user_ids: number[] = []
      this.state.selectedStudents.forEach(element => {
        user_ids.push(parseInt(element.key, 10))
      })

      api.units
        .sendResults({
          activity_id,
          unit_id: unit_id,
          user_ids: user_ids
        })
        .then(() => {
          notification.success(
            'Výsledky boli úspešne zaslané vybraným študentom'
          )
          this.setState({
            isSendOutModalVisible: false,
            selectedStudents: [],
            selectedRowKeys: []
          })
        })
        .catch(error => {
          notification.error(
            'Výsledky sa nepodarilo rozposlať vybraným študentom',
            error
          )
          this.setState({
            isSendOutModalVisible: false
          })
        })
    }

    handleSendOutModalCancel = () => {
      this.setState({
        isSendOutModalVisible: false
      })
    }

    //ant reactjs doesn't support type Option
    handleSelectedStudentsChange = (value: string[], option: any) => {
      const selectedKeys = option.map((op: {key: string}) => op.key)

      const selectedStudents: Student[] = []

      selectedKeys.forEach((key: string) =>
        selectedStudents.push(
          ...this.props.students.filter(student => student.key === key)
        )
      )

      this.setState({
        selectedStudents,
        selectedRowKeys: selectedKeys
      })
    }

    render() {
      const {selectedRowKeys, selectedStudents} = this.state
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange
      }

      const hasSelected = selectedRowKeys.length > 0
      return (
        <React.Fragment>
          <Table
            locale={{
              triggerDesc: 'Zoradiť zostupne',
              triggerAsc: 'Zoradiť vzostupne',
              cancelSort: 'Vypnúť zoradenie'
            }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={this.props.students}
            scroll={{x: 600}}
          />
          <Button
            style={{marginBottom: 10, marginTop: 10}}
            type="primary"
            icon={<SendOutlined />}
            className="button-sendout-results"
            onClick={() => this.handleSendOutModalButtonClick()}
            disabled={!hasSelected}
          >
            Rozposlať výsledky{' '}
            {hasSelected ? `(${selectedRowKeys.length})` : ''}
          </Button>
          <Modal
            bodyStyle={{padding: '0'}}
            centered
            closable
            title="Rozposlanie výsledkov"
            visible={this.state.isSendOutModalVisible}
            onCancel={this.handleSendOutModalCancel}
            footer={[
              <Button
                key={'CancelButton'}
                onClick={this.handleSendOutModalCancel}
              >
                Zrušiť
              </Button>,
              <Button
                key={'SendOutButton'}
                type="primary"
                disabled={!hasSelected}
                onClick={this.handleSendOutModalOk}
              >
                Rozposlať výsledky{' '}
                {hasSelected ? `(${selectedRowKeys.length})` : ''}
              </Button>
            ]}
          >
            <Card>
              <h4>Výsledky budú poslané na maily uvedené nižšie:</h4>
              <div style={{paddingBottom: 20}}>
                <Select
                  mode="multiple"
                  value={selectedStudents.map(student => student.name)}
                  onChange={this.handleSelectedStudentsChange}
                  style={{width: '100%'}}
                >
                  {this.props.students.map(student => (
                    <Option value={student.name} key={student.key}>
                      {student.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </Card>
          </Modal>
        </React.Fragment>
      )
    }
  }


export default withTranslation()(withRouter(Panel))
