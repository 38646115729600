import React, { FC } from 'react'
import { Card } from 'antd'

import './ExamOption.css'
import { Option } from '../../../../models'

//@ts-ignore
import { InlineMath, BlockMath } from 'react-katex'

interface IProps {
  onClick: () => void
  option: Option
  isSelected: boolean
}

const ExamOption: FC<IProps> = (props: IProps) => {
  const { onClick, option, isSelected } = props


  const renderTextWithMath = (message: string) => {
    message = message.replaceAll('<p>', '').replaceAll('</p>', '<br>')

    let indexInline, indexBlock
    let iflag = false

    const elements = []

    while (true) {
      indexInline = message.search('<script type="math/tex">')
      indexBlock = message.search('<script type="math/tex; mode=display">')

      if (indexInline !== -1 && indexBlock !== -1) {
        iflag = (indexInline < indexBlock)
      } else if (indexInline !== -1) {
        iflag = true
      } else if (indexBlock !== -1) {
        iflag = false
      } else {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message }} />)
        break
      }

      const endIndex = message.search('</script>')

      // eslint-disable-next-line
      if (iflag == true) {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message.substring(0, indexInline) }} />)
        elements.push(<InlineMath>{message.substring(indexInline + 24, endIndex)}</InlineMath>)
      } else {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message.substring(0, indexBlock) }} />)
        elements.push(<BlockMath>{message.substring(indexBlock + 38, endIndex)}</BlockMath>)
      }
      message = message.substring(endIndex + 9)
    }

    return elements
  }

  return (
    <Card
      bordered={false}
      className={`option ${isSelected ? 'selected' : ''}`}
      onClick={onClick}
    >
      <p>
        {renderTextWithMath(option.answer_data)}
      </p>
    </Card>
  )
}

export default ExamOption