import { withRouter, RouteComponentProps } from 'react-router-dom'
import React, { Component } from 'react'

/* Css */
import './SectionTryFree.scss'

/* i18n */
import { WithTranslation, withTranslation } from 'react-i18next'
import MainCard from '../MainCard/MainCard'
import { Input } from 'antd'
import EcehButton from '../../../../common/EcehButton/EcehButton'
import SignInModal from '../../../../Modals/SignInModal/SignInModal'


interface IProps extends RouteComponentProps, WithTranslation {
}

interface IState {
  email: string,
  password: string,
}

class SectionTryFree extends Component<IProps, IState> {
  signInModal: typeof SignInModal | undefined | any

  constructor(props: IProps) {
    super(props)
    this.state = {
      email: '',
      password: ''
    }
  }

  openSignInModal = () => this.signInModal?.openModal()

  render() {
    const { t } = this.props
    return (
      <MainCard dark className="eceh-try-free-section">
        <SignInModal
          defaultRegistration
          childRef={(ref: typeof SignInModal | undefined) => (this.signInModal = ref)}
          initialEmail={this.state.email}
          initialPassword={this.state.password}
        />

        <h2>
          {t('titlePage.tryFreeSection.titleRow1')}<br />
          {t('titlePage.tryFreeSection.titleRow2')}
        </h2>
        <div className="eceh-try-free-section__signup-container">
          <div className="eceh-try-free-section__signup-form-fields">
            <Input
              autoComplete="email"
              placeholder={t('basic.email')}
              type="email"
              value={this.state.email}
              onChange={event => this.setState({ email: event.target.value })}
            />
            <Input.Password
              autoComplete="new-password"
              placeholder={t('basic.password')}
              value={this.state.password}
              onChange={event => this.setState({ password: event.target.value })}
            />
          </div>
          <EcehButton onClick={this.openSignInModal} label={t('titlePage.tryFreeSection.cta')} />
        </div>
      </MainCard>
    )
  }
}

export default withTranslation()(withRouter(SectionTryFree))