import React, {useEffect} from 'react'
import {DeleteOutlined, SaveOutlined, StopOutlined} from '@ant-design/icons'
import '@ant-design/compatible/assets/index.css'
import {Button, Row, Col, Typography, Input, Checkbox, Form} from 'antd'
// @ts-expect-error: CKEditor doesnt have types - fix if they have them in future
import CKEditor from '@ckeditor/ckeditor5-react'
// @ts-expect-error: CKEditor doesnt have types - fix if they have them in future
import Editor from '@drblmthw/testujsk_custom_ckeditor5/build/ckeditor'
import '@drblmthw/testujsk_custom_ckeditor5/build/translations/en'

import {Store} from 'antd/lib/form/interface'
import notificationService from '../../../../utils/notificationService'
import {Activity, ICKEditor} from '../../../../models'
import {useForm} from 'antd/lib/form/Form'
import {useState} from 'react'

//translation
import { useTranslation } from 'react-i18next'

interface IProps {
  activity: Activity
  onSubmit: (values: Store) => void
  onCancel: () => void
}

const ActivityForm: React.FunctionComponent<IProps> = (props: IProps) => {

  const { t, i18n } = useTranslation()

  const [form] = useForm()
  const [activityEditor, setActivityEditor] = useState<ICKEditor | null>(null)

  useEffect(() => {
    form.setFieldsValue({title: props.activity.title})
    form.setFieldsValue({program: props.activity.program})
    form.setFieldsValue({code: props.activity.code})
    form.setFieldsValue({year: props.activity.year})
    form.setFieldsValue({
      public: props.activity.public
    })
    //form.setFieldsValue({
    //  study_field_id: props.activity.study_field_id
    //})
    // eslint-disable-next-line
  }, [form])

  useEffect(() => {
    if (activityEditor) {
      activityEditor.setData(props.activity.content)
    }
    // eslint-disable-next-line
  }, [activityEditor])

  function handleSubmit(values: Store): void {
    if (activityEditor && form) {
      props.onSubmit &&
        props.onSubmit({...values, content: activityEditor.getData()})
    } else {
      notificationService.error(
        t('activities.subjectRefreshError'),
        '[ERROR] SUBMIT ACTIVITY - Nonexistent CKEditor or form on activity creation, ActivityForm.tsx',
        true
      )
    }
  }

  const {Title} = Typography
  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <Row>
        <Col xs={16} md={10}>
          <Title level={2}>{t('activities.subjectEdit')}</Title>
        </Col>
        <Col xs={8} md={14} className="button-container-col">
          <Button
            className="display-small-screen"
            shape="circle"
            icon={<SaveOutlined />}
            type="primary"
            htmlType="submit"
          />
          <Button
            className="display-small-screen"
            onClick={props.onCancel}
            shape="circle"
            icon={<StopOutlined />}
            danger
          />
          <Button
            htmlType="submit"
            className="display-large-screen"
            icon={<SaveOutlined />}
            type="primary"
          >
            {t('activities.subjectSave')}
          </Button>
          <Button
            className="display-large-screen"
            onClick={props.onCancel}
            icon={<DeleteOutlined />}
            danger
          >
            {t('basic.cancel')}
          </Button>
        </Col>
      </Row>
      <Form.Item
        label="Názov"
        name="title"
        rules={[
          {
            required: true,
            message: t('activities.subjectEnterName')
          }
        ]}
      >
        <Input size="large" />
      </Form.Item>
      <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label={t('activities.subjectField')}
                name="program"
                rules={[
                  {
                    required: false,
                    //message: this.props.t('activities.')
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            
            <Col span={8}>
              <Form.Item
                label={t('activities.subjectFieldCode')}
                name="code"
                rules={[
                  {
                    required: false,
                    //message: this.props.t('activities.')
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={t('activities.subjectYear')}
                name="year"
                rules={[
                  {
                    required: false,
                    //message: this.props.t('activities.')
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
      <Row>
        <Col xs={24}>
          <Form.Item name="public" valuePropName="checked">
            <Checkbox>
              {t('activities.subjectDisplayToOthers')}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Form.Item label="Obsah">
            <CKEditor
              editor={Editor}
              onInit={(editor: ICKEditor) => setActivityEditor(editor)}
              config={{
                toolbar: {
                  language: i18n.language,
                  items: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'link',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'outdent',
                    'indent',
                    '|',
                    'blockQuote',
                    'insertTable',
                    'mediaEmbed',
                    '|',
                    'math',
                    '|',
                    'undo',
                    'redo'
                  ]
                },
                mediaEmbed: {
                  previewsInData: true
                }
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default ActivityForm
