import { withRouter, RouteComponentProps } from 'react-router-dom'
import React from 'react'

/* Css */
import './SectionFaq.scss'

/* i18n */
import { WithTranslation, withTranslation } from 'react-i18next'
import MainCard from '../MainCard/MainCard'
import { Collapse } from 'antd'

interface IProps extends RouteComponentProps, WithTranslation {
}

interface IState {
  selectedLanguage: string;
}

class SectionFaq extends React.Component<IProps, IState> {

  render() {
    const { t } = this.props

    type FaqItem = { title: string, description: string }
    const faqItems = t('titlePage.faqSection.items', { returnObjects: true }) as FaqItem[]
    console.log(faqItems)
    return (
      <MainCard
        title={t('titlePage.faqSection.title')}
      >
        <Collapse
          defaultActiveKey={[0]}
          className="eceh-faq__container"
          ghost
          accordion
        >
          {faqItems.map(({ title, description }, index) =>
            <Collapse.Panel header={title} key={index} className="eceh-faq__collapse-item">
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </Collapse.Panel>
          )}
        </Collapse>
      </MainCard>
    )
  }
}

export default withTranslation()(withRouter(SectionFaq))