import React from 'react'

import {
  ProfileOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuOutlined,
  PlusOutlined,
  RollbackOutlined,
  UnderlineOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SettingOutlined,
  UpOutlined
} from '@ant-design/icons'

import { Menu, Layout, Button, Avatar, Modal, Row, Dropdown, Typography } from 'antd'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'

//common
import LogRegModal from '../LoginAndRegistrationModal/LoginAndRegistrationModal'

//images
import LogoWhite from '../../../assets/images/logo_white_new.png'
import LogoNorwayGrantsWhite from '../../../assets/images/Norway_grants_White.png'
import LogoSlovakCoatOfArms from '../../../assets/images/Slovak_erb.png'

//css
import './HeaderAndMenu.css'

//other
import api from '../../../utils/ApiAxios'
import { AuthContext } from '../../../context/Auth'
import notification from '../../../utils/notificationService'
import { ReactNode } from 'react'
import { initialAppState } from '../../../utils/generalHelpers'
import { withTranslation, WithTranslation } from 'react-i18next'

import FlagEng from '../../../assets/images/flag_eng.png'
import FlagSvk from '../../../assets/images/flag_svk.png'

const { SubMenu } = Menu
const { Header, Sider, Content, Footer } = Layout
const { confirm } = Modal
const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae']

interface MatchParams {
  activityId?: string
  unitId?: string
}

interface IProps extends RouteComponentProps<MatchParams>, WithTranslation {
  grantMode?: boolean
  menuHide?: boolean
  isMeasurable?: boolean
  welcomePage?: boolean
  isTest?: boolean
  taskWork?: boolean
  content:
    | ReactNode
    | ((
    menuState: MenuState,
    props: IProps,
    ...menuStateHandlers: (() => any)[]
  ) => ReactNode)
}

export interface MenuState {
  grantMode: boolean
  menuHide: boolean
  collapsedWidth: number
  currentSelectedItems: string
  showHelps: boolean
  testEnded: boolean
}

class HeaderAndMenu extends React.Component<IProps, MenuState, WithTranslation> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      grantMode: props.grantMode ? props.grantMode : false,
      menuHide: props.menuHide ? props.menuHide : false,
      collapsedWidth: props.menuHide ? 0 : 80,
      currentSelectedItems: '',
      showHelps: false,
      testEnded: false
    }
    // binding functions to handle test, exam, it is used in reactrouter navigation
    this.handleExitTestResults = this.handleExitTestResults.bind(this)
    this.handleExitTestFromLastQuestion = this.handleExitTestFromLastQuestion.bind(
      this
    )
    this.handleErrorExitTest = this.handleErrorExitTest.bind(this)
  }

  static contextType = AuthContext

  // function used on logout button click
  handleLogOut = () => {
    localStorage.appState = JSON.stringify(initialAppState)
    api.global.removeHeader('token')
    this.context.reloadAuthStateFromLocalStorageAndSetApiAuthorizationToken()
    notification.success(this.props.t('menu.logoutSuccessful'))

    //this.props.history.push('/')
  }

  // helps in test handling
  handleHelpSwitch = (checked: boolean) => {
    this.setState({
      showHelps: checked
    })
  }

  // handle exit test from button in menu
  handleExitTest = () => {
    confirm({
      title: this.props.t('menu.exitTest'),
      okText: this.props.t('basic.yes'),
      cancelText: this.props.t('basic.no'),
      onOk: () => {
        this.setState({
          testEnded: true
        })
      }
    })
  }

  handleExitTasksWork = () => {
    confirm({
      title: this.props.t('menu.exitTask'),
      okText: this.props.t('basic.yes'),
      cancelText: this.props.t('basic.no'),
      onOk: () => {
        this.setState({
          testEnded: true
        })
      }
    })
  }

  handleErrorExitTest = () => {
    this.setState({
      ...this.state,
      testEnded: false
    })
  }

  // handle measurable test from lasat question, differrent handling
  handleExitTestFromLastQuestion = () => {
    this.setState({
      testEnded: true
    })
  }

  // handle exit page with test results from menu
  handleExitTestResults = () => {
    this.setState({ testEnded: false })
    const { activityId, unitId } = this.props.match.params
    if (activityId && unitId) {
      this.props.history.push(`/activity/detail/${activityId}`)
    } else {
      this.props.history.push('/')
    }
  }

  // handle menu open state
  handleOnCollapse = () => {
    this.setState({
      // menuClosedState: !this.state.menuClosedState
    })
  }

  renderSiderMenuContentForTests(): ReactNode {
    if (this.state.testEnded) {
      return (
        <Menu theme="dark" mode="inline">
          <Menu.ItemGroup title={this.props.t('menu.testOptions')}>
            <Menu.Item key="exittestresult">
              <Link to="#" onClick={this.handleExitTestResults}>
                <RollbackOutlined style={{ color: '#1890ff' }} />
                <span>{this.props.t('menu.testClose')}</span>
              </Link>
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.Divider />
        </Menu>
      )
    } else {
      return (
        <Menu theme="dark" mode="inline">
          {!this.props.isMeasurable && (
            <Menu.ItemGroup title={this.props.t('menu.testOptions')}>
              <Menu.Item key="exittest">
                <Link to="#" onClick={this.handleExitTest}>
                  <LogoutOutlined style={{ color: 'red' }} />
                  <span>{this.props.t('menu.testStop')}</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
          )}
          {this.props.isMeasurable && (
            <Menu.ItemGroup title={this.props.t('menu.testOptions')}>
              <Menu.Item key="submittest">
                <span onClick={this.handleExitTest}>
                  <LogoutOutlined style={{ color: 'red' }} />
                  <span>{this.props.t('menu.testSubmit')}</span>
                </span>
              </Menu.Item>
            </Menu.ItemGroup>
          )}
          <Menu.Divider />
        </Menu>
      )
    }
  }

  renderSiderMenuContentForTasks(): ReactNode {
    if (this.state.testEnded) {
      return (
        <Menu theme="dark" mode="inline">
          <Menu.ItemGroup title={this.props.t('menu.testOptions')}>
            <Menu.Item key="exittestresult">
              <Link to="#" onClick={this.handleExitTestResults}>
                <RollbackOutlined style={{ color: '#1890ff' }} />
                <span>{this.props.t('menu.quit')}</span>
              </Link>
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.Divider />
        </Menu>
      )
    } else {
      return (
        <Menu theme="dark" mode="inline">
          <Menu.Item key="exittest">
            <Link to="#" onClick={this.handleExitTasksWork}>
              <LogoutOutlined style={{ color: 'red' }} />
              <span>{this.props.t('menu.quitTask')}</span>
            </Link>
          </Menu.Item>
        </Menu>
      )
    }
  }

  /* Main navigation */
  renderSiderMenuContent(currentPage: string, handleMenuClick: any): ReactNode {
    return (
      <Menu
        className="navigation-menu"
        onClick={handleMenuClick}
        selectedKeys={[this.context.currentPage]}
        theme="dark"
        mode="inline"
      >
        {/* Home */}
        <Menu.Item key="/">
          <Link to="/">
            <HomeOutlined />
            <span>{this.props.t('menu.home')}</span>
          </Link>
        </Menu.Item>

        {/* Tasks */}
        <Menu.ItemGroup key="events" title={this.props.t('menu.tasks')}>
          <Menu.Item key="/events">
            <Link to="/events">
              <UnderlineOutlined />
              <span>{this.props.t('menu.listOfTasks')}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/event/new'">
            <Link to="/event/new">
              <PlusOutlined />
              <span>{this.props.t('menu.addTask')}</span>
            </Link>
          </Menu.Item>
        </Menu.ItemGroup>

        {/* Activities */}
        <Menu.ItemGroup key="activities" title={this.props.t('menu.subjects')}>
          <Menu.Item key="/activities">
            <Link to="/activities">
              <ProfileOutlined />
              <span>{this.props.t('menu.listOfSubjects')}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/activity/new">
            <Link to="/activity/new">
              <PlusOutlined />
              <span>{this.props.t('menu.createSubject')}</span>
            </Link>
          </Menu.Item>
        </Menu.ItemGroup>

        {/* Invite */}
        <Menu.ItemGroup key="invite" title={this.props.t('menu.addUser')}>
          <Menu.Item key="/invite">
            <Link to="/invite">
              <PlusOutlined/>
              <span>{this.props.t('menu.addUser')}</span>
            </Link>
          </Menu.Item>
        </Menu.ItemGroup>

      </Menu>
    )
  }

  renderUserMenuInHeader(): ReactNode {
    const mobileMenuTrigger =
      this.state.collapsedWidth === 0 && !this.state.menuHide ? (
        <SubMenu
          className="float-right"
          key="menuTrigger"
          style={{ color: 'white' }}
          title={
            this.context.menuClosedState ? (
              <MenuUnfoldOutlined style={{ marginRight: '0' }} onClick={this.context.toggleMenu} />
            ) : (
              <MenuFoldOutlined style={{ marginRight: '0' }} onClick={this.context.toggleMenu} />
            )
          }
        />
      ) : undefined
    const userMenu = this.context.isLoggedIn ? (
      <SubMenu
        className="float-right"
        key="subMenu"
        disabled={this.props.isMeasurable}
        title={
          this.state.collapsedWidth === 0 ? (
            <Avatar style={{
              backgroundColor: ColorList[Math.floor((this.context.user.name.charCodeAt(0) % 100) / 100 * ColorList.length)],
              verticalAlign: 'middle'
            }} size="small">
              {this.context.user.name.substring(0, 1)}
            </Avatar>
          ) : (
            <>
              <span style={{ marginRight: '10px' }}>{this.context.user.name}</span> <Avatar style={{
              backgroundColor: ColorList[Math.floor((this.context.user.name.charCodeAt(0) % 100) / 100 * ColorList.length)],
              verticalAlign: 'middle'
            }} size="small">
              {this.context.user.name.substring(0, 1)}
            </Avatar>
            </>
          )
        }
      >
        <Menu.Item key="profile">
          <Button type="link" href="/profile">
            <SettingOutlined />
            {this.props.t('menu.accountSettings')}
          </Button>
        </Menu.Item>
        <Menu.Item key="logout">
          <Button type="link" onClick={this.handleLogOut} danger>
            <LogoutOutlined />
            {this.props.t('menu.logOut')}
          </Button>
        </Menu.Item>
      </SubMenu>
    ) : (
      <SubMenu
        className="float-right"
        key="subMenu"
        title={
          <span>
            <MenuOutlined />
          </span>
        }
      >
        <Menu.Item key="registration">
          <LogRegModal registration />
        </Menu.Item>
        <Menu.Item key="login">
          <LogRegModal registration={false} />
        </Menu.Item>
      </SubMenu>
    )

    return (
      <Menu
        theme="dark"
        mode="horizontal"
        selectable={false}
        style={{ lineHeight: '64px' }}
        subMenuCloseDelay={0.5}
      >
        {mobileMenuTrigger !== undefined && mobileMenuTrigger}
        {userMenu}
      </Menu>
    )
  }

  render() {
    const { isTest, taskWork, content } = this.props
    // componentConentent is used to show content from react router component
    let ComponentContent
    if (content) {
      if (isTest || taskWork) {
        ComponentContent = (content as (
          menuState: MenuState,
          props: IProps,
          ...menuStateHandlers: (() => any)[]
        ) => ReactNode)(
          this.state,
          this.props,
          this.handleExitTestResults,
          this.handleExitTestFromLastQuestion,
          this.handleErrorExitTest
        )
      } else {
        ComponentContent = content
      }
    } else {
      ComponentContent = <span>No content</span>
    }

    let logo
    if (true) {
      logo = (
        <Row style={{ alignItems: 'center', height: '100%' }}>
          <img src={LogoWhite} alt="Logo" style={{ maxHeight: '45px' }} />
          <span style={{
            fontFamily: 'Bungee',
            color: 'rgb(245,246,250, 1)',
            fontSize: '250%',
            lineHeight: '45px',
            marginLeft: '10px'
          }}>ECEH
                </span>
        </Row>)
    } else {
      logo = (
        <Link to="/" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <img src={LogoWhite} alt="Logo" style={{ maxHeight: '45px' }} />
          <span style={{
            fontFamily: 'Bungee',
            color: 'rgb(245,246,250, 1)',
            fontSize: '250%',
            lineHeight: '45px',
            marginLeft: '10px'
          }}>ECEH</span>
        </Link>
      )
    }

    const norwayGrantsLogo = (
      <img src={LogoNorwayGrantsWhite} alt="Norweign Grant Logo" style={{ maxHeight: '45px' }} />
    )

    const slovakCoatOfArmsLogo = (
      <img src={LogoSlovakCoatOfArms} alt="Slovak Coat of arms" style={{ maxHeight: '35px' }} />
    )

    const hideMenu = this.props.menuHide === true
    const grantMode = this.props.grantMode === true

    const languages = (
      <Menu>
        <Menu.Item>
          <Row onClick={() => this.props.i18n.changeLanguage('sk')}>
            <img src={String(FlagSvk)} alt={'Svk Flag'} className={'languageIcon'}></img>
            <span>Slovensky</span>
          </Row>

        </Menu.Item>
        <Menu.Item>
          <Row onClick={() => this.props.i18n.changeLanguage('en')}>
            <img src={String(FlagEng)} alt={'Eng Flag'} className={'languageIcon'}></img>
            <span>English</span>
          </Row>
        </Menu.Item>
      </Menu>
    )

    return (
      <Layout className="layout">
        {grantMode && (
          <Header
            className="header"
            style={{
              zIndex: 1,
              position: 'fixed',
              width: '100vw',
              height: '64px',
              padding: 15,
              paddingTop: 0,
              paddingBottom: 0
            }}
          >
            <div className={'container'}>
              <div className="norwayGrantLogo" style={{ float: 'left', height: '100%' }}>
                {norwayGrantsLogo}
              </div>

              <div className="slovakCoatOfArmsLogo" style={{ float: 'right', height: '100%' }}>
                {slovakCoatOfArmsLogo}
              </div>
            </div>
          </Header>
        )}
        {!grantMode && (
          <Header
            className="header"
            style={{
              zIndex: 1,
              position: 'fixed',
              width: '100vw',
              height: '64px',
              padding: 15,
              paddingTop: 0,
              paddingBottom: 0
            }}
          >
            <div className="logo" style={{ float: 'left', height: '100%' }}>
              {logo}
            </div>
            {this.renderUserMenuInHeader()}
          </Header>
        )}
        <Layout>
          {!hideMenu && (
            <Sider
              style={{
                height: '100vh',
                position: 'fixed',
                top: '64px'
              }}
              collapsible={!this.props.isTest}
              collapsed={this.context.menuClosedState}
              onCollapse={this.context.toggleMenu}
              breakpoint="sm"
              collapsedWidth={this.state.collapsedWidth}
              onBreakpoint={broken => {
                this.setState({ collapsedWidth: broken ? 0 : 80 })
              }}
              trigger={null}
            >
              {!this.props.isTest &&
                !this.props.taskWork &&
                this.renderSiderMenuContent(
                  this.context.currentPage,
                  this.context.handleMenuClick
                )}
              {this.props.isTest &&
                !this.props.taskWork &&
                this.renderSiderMenuContentForTests()}
              {this.props.taskWork &&
                !this.props.isTest &&
                this.renderSiderMenuContentForTasks()}
              <div className={'changeLanguageWrapper'}>
                <Dropdown overlay={languages} placement={'topLeft'}>
                  <Typography.Text className={'languageText'}><img src={this.props.t('menu.flagPath')} alt=""
                                                                   className={'languageIcon'} />
                    <span style={{ marginRight: '10px' }}>{this.props.t('menu.changeLanguage')}</span>
                    <UpOutlined />
                  </Typography.Text>
                </Dropdown>
              </div>
            </Sider>
          )}
          <Content
            style={{
              marginLeft: this.context.menuClosedState || hideMenu ? 0 : 200
            }}
            id="content"
          >
            {ComponentContent}
          </Content>
        </Layout>
        <Footer
          style={{ marginLeft: this.context.menuClosedState || hideMenu ? 0 : 200 }}
          className={`footer space-between ${this.props.welcomePage ? 'welcome-footer' : ''}`}
        >
          <Link target="_blank" to="/privatepolicy">
            {this.props.t('menu.privacyPolicy')}
          </Link>
          ECEH © {new Date().getFullYear()}
        </Footer>
      </Layout>
    )
  }
}


export default withTranslation()(withRouter(HeaderAndMenu))