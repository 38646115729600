import React, { useState, FC } from 'react'

import {
  AreaChartOutlined,
  DeleteOutlined,
  EditOutlined,
  FormOutlined,
  MoreOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  DesktopOutlined,
  MenuOutlined
} from '@ant-design/icons'

import {
  Collapse,
  List,
  Button,
  Dropdown,
  Menu,
  Typography,
  Switch,
  Tooltip
} from 'antd'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import './Unit.css'
import api from '../../../../utils/ApiAxios'
import notification from '../../../../utils/notificationService'
import Exam from '../../../../models/exam'
import notificationService from '../../../../utils/notificationService'
import Unit from '../../../../models/unit'
import { ReactNode } from 'react'
import { MenuInfo } from 'rc-menu/lib/interface'

//translation
import { useTranslation } from 'react-i18next'

const { Panel } = Collapse
const { Title } = Typography

// component
interface ActivationToggleProps {
  test: Exam
}

const ActivationToggle: FC<ActivationToggleProps> = ({
                                                       test
                                                     }: ActivationToggleProps) => {
  const { t } = useTranslation()
  const [active, setActive] = useState(test.active)
  const toggleTestActivation = () => {
    if (test.id) {
      api.exams
        .updateExam(test.id, { ...test, active: !active })
        .then(() => {
          setActive(!active)
        })
        .catch(error =>
          notification.error(
            t('activities.examActivationError'),
            error
          )
        )
    } else {
      notificationService.error(
        t('activities.examActivationError'),
        '[ERROR] TOGGLE TEST ACTIVATION - TestId is missing',
        true
      )
    }
  }
  return (
    <Switch
      className="active-switch"
      unCheckedChildren={t('activities.notActive')}
      checkedChildren={t('activities.active')}
      checked={active}
      onChange={() => toggleTestActivation()}
    />
  )
}

interface UnitProps extends RouteComponentProps {
  userID: number
  activityId: number
  unit: Unit
  tasksUnit: Unit
  questionsUnit: Unit
  index: number //position in list in activity
  owner: boolean
  progress: any
  subscriber?: boolean
  total_time:number
  handleEventAssign: (menuInfo: MenuInfo, unitIndex: number) => Promise<void>
  handleUnitDelete: (menuInfo: MenuInfo, unitIndex: number) => void
  handleUnitEdit: (menuInfo: MenuInfo, unitIndex: number) => void
  addTest: (unitId?: number) => void
  onCollapseChange: () => void
}

const UnitComponent: FC<UnitProps> = ({
                                        activityId,
                                        unit,
                                        tasksUnit,
                                        questionsUnit,
                                        index,
                                        owner,
                                        progress,
                                        subscriber,
                                        total_time,
                                        handleEventAssign,
                                        handleUnitDelete,
                                        handleUnitEdit,
                                        addTest,
                                        onCollapseChange
                                      }: UnitProps) => {
  const { t } = useTranslation()
  const showResultToStudents = true

  function UnitFooter(buletproofSwitch: boolean): ReactNode {
    if (
      buletproofSwitch &&
      questionsUnit.events &&
      questionsUnit.events.length > 0
    ) {
      return (
        <Button type="primary" style={{ marginTop: 15 }}>
          <Link to={`/${activityId}/${questionsUnit.id}/test`}>
            {t('activities.startInfoTest')}
          </Link>
        </Button>
      )
    } else if (
      !buletproofSwitch &&
      tasksUnit.events &&
      tasksUnit.events.length > 0
    ) {
      return (
        <Button type="primary" style={{ marginTop: 15 }}>
          <Link to={`/${activityId}/${tasksUnit.id}/tasks`}>
            {t('activities.startTaskWork')}
          </Link>
        </Button>
      )
    } else {
      return <></>
    }
  }

  function editUnitOfActivity(index: number): ReactNode {
    if (owner) {
      return (
        <React.Fragment>
          <div className="mobileHidden">
            <Menu mode="horizontal" style={{ backgroundColor: 'inherit' }}>
              <Menu.Item key="1">
                <Tooltip title={t('activities.teacherPanel')}>
                  <Link to={`/${activityId}/${questionsUnit.id}/panel`}>
                    <DesktopOutlined />
                  </Link>
                </Tooltip>
              </Menu.Item>

              <Menu.Item
                key="2"
                onClick={menuInfo => [handleEventAssign(menuInfo, index)]}
              >
                <Tooltip title={t('activities.tasksAddDelete')}>
                  <FormOutlined />
                </Tooltip>
              </Menu.Item>

              <Menu.Item
                key="3"
                onClick={menuInfo => handleUnitEdit(menuInfo, index)}
              >
                <Tooltip title={t('activities.lessonEditInfo')}>
                  <EditOutlined />
                </Tooltip>
              </Menu.Item>

              <Menu.Item
                key="4"
                onClick={menuInfo => handleUnitDelete(menuInfo, index)}
              >
                <Tooltip title={t('activities.lessonDelete')}>
                  <DeleteOutlined />
                </Tooltip>
              </Menu.Item>

              <Menu.Item
                key="5"
                onClick={() => {
                  addTest(unit.id)
                }}
              >
                <Tooltip title={t('activities.testAdd')}>
                  <PlusOutlined />
                </Tooltip>
              </Menu.Item>
            </Menu>
          </div>
          <div className="mobileVisible">
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    <Link to={`/${activityId}/${questionsUnit.id}/panel`}>
                      <DesktopOutlined /> {t('activities.teacherPanel')}
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    onClick={menuInfo => [handleEventAssign(menuInfo, index)]}
                  >
                    <FormOutlined />
                    {t('activities.tasksAddDelete')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={menuInfo => handleUnitEdit(menuInfo, index)}
                  >
                    <EditOutlined />
                    {t('activities.lessonEditInfo')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={menuInfo => handleUnitDelete(menuInfo, index)}
                  >
                    <DeleteOutlined />
                    {t('activities.lessonDelete')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      addTest(unit.id)
                    }}
                  >
                    <PlusOutlined />
                    {t('activities.testAdd')}
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                type="link"
                onClick={event => {
                  event.stopPropagation()
                }}
              >
                <MenuOutlined />
              </Button>
            </Dropdown>
          </div>
        </React.Fragment>
      )
    }
  }

  function ownerTestMenu(test: Exam): ReactNode {
    if (owner) {
      return (
        <>
          <ActivationToggle test={test} />
          <Dropdown
            overlay={
              <Menu>
                {/* show test results for unit owner */}
                <Menu.Item>
                  <Link
                    to={{
                      pathname: `/exam/result/${activityId}/${test.id}`,
                      state: { test }
                    }}
                  >
                    <AreaChartOutlined />
                    <span>{t('activities.showOverallResults')}</span>
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to={`/${activityId}/${unit.id}/exam/${test.id}`}>
                    <PlayCircleOutlined />
                    <span>{t('activities.testStart')}</span>
                  </Link>
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              type="link"
              onClick={event => {
                event.stopPropagation()
              }}
            >
              <MoreOutlined />
            </Button>
          </Dropdown>
        </>
      )
    } else {
      return <></>
    }
  }

  function formatTime(time: number) {
    if (time < 60) {
      return time.toString() + 's'
    } else if (time < 3600) {
      return Math.floor(time / 60).toString() + 'm ' + (time % 60).toString() + 's'
    }
    return Math.floor(time / 3600).toString() + 'h ' + Math.floor((time % 3600) / 60).toString() + 'm ' + (time % 60).toString() + 's'
  }

  function UnitTestsList(): ReactNode {
    let test_counter = 1
    if (!unit.tests || unit.tests.length === 0) return <></>
    let noDataText = t('activities.testNotExist')
    let testData: Exam[]
    if (owner) {
      testData = unit.tests
    } else if (subscriber) {
      testData = unit.tests.filter(test => test.active)
    } else {
      noDataText = t('activities.testShowInfo')
      testData = []
    }
    return (
      <List
        header={<Title level={4}>{t('activities.tests')}</Title>}
        dataSource={testData}
        locale={{
          emptyText: noDataText
        }}
        renderItem={test => {
          const { answers } = test
          return (
            <List.Item extra={ownerTestMenu(test)}>
              <span>{(test_counter++) + '. ' + test.name}</span>
              {!owner && (
                <div className="exam-button-right">
                  {answers &&
                    answers.length > 0 && ( //show test results for students
                      <Link
                        to={{
                          pathname: `/exam/student/result/${test.id}`,
                          state: { test }
                        }}
                      >
                        <Button type="link" disabled={!showResultToStudents}>
                          {t('activities.showOverallResults')}
                        </Button>
                      </Link>
                    )}
                  {answers && answers.length === 0 && test.active && (
                    <Link to={`/${activityId}/${unit.id}/exam/${test.id}`}>
                      <Button type="link">{t('activities.testStart')}</Button>
                    </Link>
                  )}
                </div>
              )}
            </List.Item>
          )
        }}
      />
    )
  }

  let ulohy_counter = 1
  let otazky_counter = 1
  return (
    <Collapse className="unit-colapse" onChange={onCollapseChange}>
      <Panel
        key={unit.title}
        header={unit.fe_counter + '. ' + unit.title + ' <' + formatTime(total_time) + '>'}
        extra={editUnitOfActivity(index)}
      >
        {/* <div>
          <Title level={3}>Popis</Title>
          {unit.description}
        </div> */}

        {tasksUnit?.events?.length ? (
          <>
            <List
              header={
                <div>
                  <Title level={4}>{t('activities.tasks')}</Title>
                </div>
              }
              loading={tasksUnit.events === null}
              dataSource={tasksUnit.events ? tasksUnit.events : []}
              renderItem={task => (
                <List.Item
                  className={
                    progress.data.includes(task.id)
                      ? 'eventItemDone'
                      : 'eventItem'
                  }
                >
                  <Link to={`/event/detail/${task.id}`}>
                    <span>{(ulohy_counter++) + '. ' + task.header + ' <' + formatTime(task.time_to_handle) + '>'}</span>
                  </Link>
                </List.Item>
              )}
            />
            {UnitFooter(false)}
          </>
        ) : (
          <div />
        )}
        {questionsUnit?.events?.length ? (
          <>
            <List
              header={
                <div>
                  <Title level={4}>{t('activities.questions')}</Title>
                </div>
              }
              loading={questionsUnit.events === null}
              dataSource={questionsUnit.events ? questionsUnit.events : []}
              renderItem={question => (
                <List.Item
                  className={
                    progress.data.includes(question.id)
                      ? 'eventItemDone'
                      : 'eventItem'
                  }
                >
                  <Link to={`/event/detail/${question.id}`}>
                    <span>{(otazky_counter++) + '. ' + question.header + ' <' + formatTime(question.time_to_handle) + '>'}</span>
                  </Link>
                </List.Item>
              )}
            />
            {UnitFooter(true)}
          </>
        ) : (
          <div />
        )}
        {UnitTestsList()}
      </Panel>
    </Collapse>
  )
}

export default withRouter(UnitComponent)
