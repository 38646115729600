import React from 'react'
import {
  CheckCircleOutlined,
  LeftCircleOutlined,
  LogoutOutlined,
  RightCircleOutlined,
  SmileTwoTone
} from '@ant-design/icons'
import { Button, Card, Row, Col, Typography, Result, Collapse, List, Tag } from 'antd'

import api from '../../../utils/ApiAxios'
import notification from '../../../utils/notificationService'
import './TasksContainer.css'
import Loading from '../../common/Loading/Loading'
import { MenuState } from '../../common/HeaderAndMenu/HeaderAndMenu'
import { RouteComponentProps } from 'react-router'
import { Event, Unit } from '../../../models'
//@ts-ignore
import { InlineMath, BlockMath } from 'react-katex'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'

const { Title } = Typography
const { Panel } = Collapse
const ButtonGroup = Button.Group

interface IProps extends RouteComponentProps<RouteParams>, WithTranslation {
  menuState: MenuState;
  handleExitTestFromLastQuestion: () => void;
  handleExitTestResults: () => void;
}

interface RouteParams {
  activityId: string;
  unitId: string;
}

interface IState {
  testEnded: boolean;
  events: Event[];
  index: number; // index on which event i am
  isLoading: boolean;
  unit: Unit | null;
  activityId: number;
  progress: number[];
  activeKey: string;
}

class TestContainer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      events: [],
      unit: null,
      // index_order: [],
      index: 0,
      activityId: 0,
      isLoading: false,
      testEnded: this.props.menuState.testEnded,
      progress: [],
      activeKey: ''
    }
  }

  componentWillUnmount() {
    if (!this.state.testEnded) {
      localStorage.setItem('tasksWorkState', JSON.stringify(this.state))
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.menuState.testEnded !== prevProps.menuState.testEnded) {
      if (this.props.menuState.testEnded) {
        this.endTest()
      }
    }
  }

  componentDidMount() {
    const stringifiedLastTestState = localStorage.getItem('tasksWorkState')
    if (stringifiedLastTestState !== null) {
      const lastTestState: IState = JSON.parse(stringifiedLastTestState)
      this.setState(lastTestState)
      localStorage.removeItem('tasksWorkState')
    }

    if (!this.state.isLoading) {
      const unitId = parseInt(this.props.match.params.unitId)
      const activityId = parseInt(this.props.match.params.activityId)
      Promise.all([
        api.units.getUnit(unitId),
        api.units.getUnitEvents(unitId),
        api.activities.getUserProgress(activityId, unitId)
      ])
        .then(async ([unit, events, progress]) => {
          let eventsData: Event[] = []
          const eventsDatatmp: Event[] = events.data
          const data: number[] = progress.data
          eventsDatatmp.forEach((element) => {
            if (element.event_type_id === 7) {
              eventsData.push(element)
            }
          })
          const eventsHelps = await Promise.all(
            eventsData.map((event) => api.events.getEventHelp(event.id))
          )
          eventsData = eventsData.map((event, index) => ({
            ...event,
            help: eventsHelps[index].data
          }))
          this.setState({
            activityId: activityId,
            unit: unit.data,
            events: eventsData,
            isLoading: true,
            progress: data
          })
        })
        .catch((error) =>
          notification.error(
            this.props.t('tasks.pageError'),
            error
          )
        )
    }
  }

  handleTaskDone = (eventId: number) => {
    if (this.state.progress.includes(eventId)) {
      notification.success(this.props.t('tasks.taskDoneAlready'))
    } else {
      const progress = this.state.progress
      progress.push(eventId)
      this.setState({
        progress: progress
      })
      api.activities
        .assignUserProgress(this.state.activityId, this.state.unit?.id, progress)
        .then(() => {
          notification.success(this.props.t('tasks.taskIsDone'))
        })
        .catch((error) => {
          notification.error(this.props.t('tasks.generalError'), error)
        })
    }
  }

  handleNextTask = () => {
    this.setState({
      index: this.state.index + 1,
      activeKey: ''
    })
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  handlePreviousTask = () => {
    this.setState({
      index: this.state.index - 1,
      activeKey: ''
    })
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  handleCollapse = () => {
    const tmp = this.state.activeKey

    if (tmp) {
      this.setState({
        activeKey: ''
      })
    } else {
      this.setState({
        activeKey: 'task-hints'
      })
    }
  }

  renderTextWithMath(message: string) {

    message = message.replaceAll('<p>', '').replaceAll('</p>', '<br>')

    let indexInline, indexBlock
    let iflag = false

    const elements = []

    while(true) {
      indexInline = message.search('<script type="math/tex">')
      indexBlock = message.search('<script type="math/tex; mode=display">')

      if (indexInline !== -1 && indexBlock !== -1) {
        iflag = (indexInline < indexBlock)
      }
      else if (indexInline !== -1) {
        iflag = true
      }
      else if (indexBlock !== -1) {
        iflag = false
      }
      else {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message}} />)
        break
      }

      const endIndex = message.search('</script>')

      // eslint-disable-next-line
      if(iflag == true) {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message.substring(0, indexInline)}} />)
        elements.push(<InlineMath>{message.substring(indexInline+24, endIndex)}</InlineMath>)
      }
      else {
        elements.push(<span dangerouslySetInnerHTML={{ __html: message.substring(0, indexBlock)}} />)
        elements.push(<BlockMath>{message.substring(indexBlock+38, endIndex)}</BlockMath>)
      }
      message = message.substring(endIndex+9)
    }

    return elements
  }

  renderQuestion() {
    const { events, index } = this.state
    const event = events[index]
    const { header, message } = event

    let nextTaskButton
    let previousTaskButton
    let doneTag

    if (index - 1 < 0) {
      previousTaskButton = (
        <Button style={{ margin: 3 }}>
          {this.props.t('tasks.prevTask')}
          <LeftCircleOutlined />
        </Button>
      )
    } else {
      previousTaskButton = (
        <Button style={{ margin: 3 }} onClick={this.handlePreviousTask}>
          {this.props.t('tasks.prevTask')}
          <LeftCircleOutlined />
        </Button>
      )
    }

    if (index + 1 === events.length) {
      nextTaskButton = (
        <Button style={{ margin: 3 }} onClick={this.props.handleExitTestFromLastQuestion}>
          {this.props.t('tasks.quit')}
          <LogoutOutlined />
        </Button>
      )
    } else {
      nextTaskButton = (
        <Button style={{ margin: 3 }} onClick={this.handleNextTask}>
          <RightCircleOutlined />
          {this.props.t('tasks.nextTask')}
        </Button>
      )
    }

    const doneButton = (
      <Button type="primary" style={{ margin: 3 }} onClick={() => this.handleTaskDone(event.id!)}>
        <CheckCircleOutlined />
        {this.props.t('tasks.done')}
      </Button>
    )

    const taskBox = (
      <Row justify="end">
        <Col>
          <ButtonGroup style={{ margin: 5 }}>
            {previousTaskButton}
            {doneButton}
            {nextTaskButton}
          </ButtonGroup>
        </Col>
      </Row>
    )

    const help = (
      <Collapse
        className="border-bottom-collapse-header"
        ghost
        onChange={this.handleCollapse}
        activeKey={this.state.activeKey}
        style={{ margin: 5 }}
      >
        <Panel header={<Title level={4}>{this.props.t('tasks.hints')}</Title>} key="task-hints">
          <List
            dataSource={event.help}
            renderItem={(item) => (
              <List.Item>{this.renderTextWithMath(item.url)}</List.Item>
            )}
          />
        </Panel>
      </Collapse>
    )

    if (this.state.progress.includes(event.id!)) {
      doneTag = (
        <Tag color={'green'} style={{ marginLeft: 10 }}>
          <CheckCircleOutlined style={{ marginRight: 5 }} />
          {this.props.t('tasks.taskDone')}
        </Tag>
      )
    } else {
      doneTag = <div />
    }

    return (
      <>
        <Card
          className="rounded-top"
          bodyStyle={{ padding: 10, paddingLeft: 24 }}
          style={{ marginBottom: 5 }}
        >
          <Title level={3}>
            {header}
            {doneTag}
          </Title>
          <div className="left-space">
            {this.renderTextWithMath(message)}
          </div>
          {taskBox}
        </Card>
        {help}
      </>
    )
  }

  endTest = () => {
    localStorage.removeItem('tasksWorkState')
    this.setState({
      testEnded: true
    })
  }

  handleResultEnd = () => {
    this.props.handleExitTestResults()
  }

  renderResults() {
    localStorage.removeItem('tasksWorkState')
    return (
      <Card
        className="rounded"
        bodyStyle={{ padding: 10, paddingLeft: 24 }}
        style={{ marginBottom: 5 }}
      >
        <Result
          icon={<SmileTwoTone />}
          title={this.props.t('tasks.congrat')}
          extra={
            <div style={{ textAlign: 'center' }}>
              <Button onClick={this.handleResultEnd} type="primary">
              {this.props.t('tasks.quit')}
              </Button>
            </div>
          }
        />
      </Card>
    )
  }

  render() {
    const { isLoading, testEnded, unit } = this.state

    if (!isLoading) {
      return <Loading />
    }
    return (
      <div className="tasks-container">
        <Card
          className="rounded"
          style={{ margin: 0, marginBottom: 10 }}
          bodyStyle={{ padding: 10, paddingLeft: 24 }}
        >
          <Title level={3} style={{ marginBottom: 0 }}>
            {this.props.t('tasks.taskElaboration')}
          </Title>
          <p className="left-space">{unit ? unit.title : this.props.t('tasks.taskTitleError')}</p>
        </Card>
        {isLoading && testEnded ? this.renderResults() : this.renderQuestion()}
      </div>
    )
  }
}

export default withTranslation()(TestContainer)
