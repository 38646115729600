import React, { Component, ChangeEvent, ReactText } from 'react'
import { DeleteTwoTone } from '@ant-design/icons'
import {
  List, Table, Typography, Button, Input, // DatePicker,
  Tooltip
} from 'antd'
import './TestCreation.css'
import api from '../../../utils/ApiAxios'
import notification from '../../../utils/notificationService'
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom'
import { Event, Unit } from '../../../models'
import moment from 'moment'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'

interface IProps extends RouteComponentProps<{}, {}, { activity_id: number; unit_id: number; }>, WithTranslation {

}

interface IState {
  loading: boolean;
  testSubmitted: boolean;
  testName: string;
  nameValidationError: boolean;
  dateValidationError: boolean;
  units: Unit[];
  selectedEvents: number[]; //ids of selected events in current unit
  testEvents: Event[];
  selectedUnit: number; // id of unit to which i am adding test
  selectedActivity: number; // id of activity to which i am adding test
  unitSelected?: number; //id of unit from which i am picking events right now
  events: Event[]; // events of unit with id unitSelected
}

class TestCreation extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props)
    this.state = {
      selectedEvents: [],
      testEvents: [],
      units: [],
      loading: true,
      testName: '',
      testSubmitted: false,
      selectedActivity: props.location.state.activity_id,
      selectedUnit: props.location.state.unit_id,
      nameValidationError: false,
      dateValidationError: false,
      events: []
    }
  }

  updateTestName = (e: ChangeEvent<HTMLInputElement>) => {
    const testNameRegex = /^.{1,100}$/
    this.setState({
      testName: e.target.value,
      nameValidationError: !e.target.value.match(testNameRegex)
    })
  };

  // updateTestTime = (value, dateString: string) => {
  //   const testTimeValue = value && value._d
  //   let currentTime = new Date()
  //   currentTime.setSeconds(currentTime.getSeconds() - 10)
  //   this.setState({
  //     testTime: dateString,
  //     dateValidationError: testTimeValue < currentTime,
  //   })
  // }

  redirectToActivities = () => {
    if (this.state.testSubmitted) {
      return <Redirect
        to={this.props.location.state.activity_id ? `/activity/detail/${this.props.location.state.activity_id}` : '/activities'} />
    }
    return null
  };

  selectEvents = (selectedEvents: ReactText[]) => {
    this.setState({ selectedEvents: selectedEvents.map(id => parseInt(id as string)) })
  };

  /**
   * add events to selected.
   * @param {Array} events - events selected by checkbox in UI.
   */
  addToSelected = (events: Event[]) => {
    // ids of event already in the test
    const testEventsKeys = this.state.testEvents ? this.state.testEvents.map(({ id }) => id) : []
    // deduplicated events selected for test
    const selectedTestEvents = events
      ? events.reduce((tempArray: Event[], event: Event) => {
        if (
          event.id !== undefined &&
          this.state.selectedEvents.includes(event.id) &&
          !testEventsKeys.includes(event.id)
        ) {
          tempArray.push(event)
        }
        return tempArray
      }, [])
      : []

    this.setState({
      testEvents: [...this.state.testEvents, ...selectedTestEvents]
    })
  };

  removeFromSelected = (id: number) => {
    const testEvents = this.state.testEvents
    testEvents.splice(
      testEvents.findIndex((v) => v.id === id), 1
    )
    this.setState({
      testEvents
    })
  };

  submitTest() {
    const payload = {
      name: this.state.testName,
      unit_id: this.state.selectedUnit,
      activity_id: this.props.location.state.activity_id,
      // startDate: this.state.testTime,
      startDate: '2020-05-23 01:22:55',
      events: this.state.testEvents,
      active: false
    }
    if (!this.state.dateValidationError && !this.state.nameValidationError) {
      api.exams.createExam(payload)
        .then(() => {
          this.setState({
            selectedEvents: [],
            testSubmitted: true
          })
          notification.success(this.props.t('test.testCreateSuccess'))
        })
        .catch((error) => notification.error(this.props.t('test.testCreateUnsuccess'), error.name))
    } else {
      notification.error(this.props.t('test.testPickDateTitle'))
    }
  }

  updateEvents(unit: Unit) {
    const unitSelected = unit.id
    if (this.state.unitSelected === unitSelected) {
      return
    }
    api.units.getUnitEvents(unitSelected)
      .then((response) => {
        this.setState({
          unitSelected,
          events: response.data
        })
      })
      .catch((error) => notification.error(this.props.t('test.pageError'), error.name))
  }

  componentDidMount() {
    api.activities
      .getActivityUnits(this.state.selectedActivity)
      .then(response => {
        const selectedUnitObject = response.data.find(
          unit => unit.id === this.state.selectedUnit
        )
        const units = selectedUnitObject
          ? response.data.reduce((tempArray: Unit[], unit: Unit) => {
            if (
              unit.id &&
              unit.pivot &&
              selectedUnitObject.pivot &&
              unit.pivot.unit_order_number <=
              selectedUnitObject.pivot.unit_order_number
            ) {
              tempArray.push(unit)
            }
            return tempArray
          }, [])
          : response.data
        this.setState({
          units,
          loading: false
        })
      })
      .catch(error => notification.error(this.props.t('test.pageError'), error.name))
  }

  render() {
    const { selectedEvents } = this.state
    const eventRowSelection = {
      selectedEvents,
      onChange: this.selectEvents
    }

    const unitEventsColumns = [
      <Table.Column key="header" title={this.props.t('test.title')} dataIndex="header" />,
      <Table.Column key="created_at" title={this.props.t('test.created')} dataIndex="created_at" />,
      <Table.Column key="time_to_handle" title={this.props.t('test.recommendedTime')} dataIndex="time_to_handle" />
    ]

    const testEventsColumns = [
      ...unitEventsColumns,
      <Table.Column
        key="actions"
        title={this.props.t('test.actions')}
        dataIndex="id"
        render={id => {
          return (
            <div>
              <Tooltip title={this.props.t('test.deleteTasks')}>
                <Button onClick={() => this.removeFromSelected(id)}>
                  <DeleteTwoTone twoToneColor="#ff0000" />
                </Button>
              </Tooltip>
            </div>
          )
        }}
      />
    ]

    return (
      <main>
        {!this.state.loading &&
        <div
          className="test-creation-container"
        >
          <div
            className="unit-list"
          >
            <h2>
              {this.props.t('test.listOfLessons')}
            </h2>
            <List
              className="unit-list-items"
              bordered
              dataSource={this.state.units}
              renderItem={(unit) => (
                <button
                  className="unit"
                  onClick={() => this.updateEvents(unit)}
                >
                  <List.Item>
                    <Typography.Text mark /> {unit.title}
                  </List.Item>
                </button>
              )}
            />
          </div>
          <div
            className="event-list"
          >
            <h2>
            {this.props.t('test.listOfTasks')}
            </h2>
            <div>
              <Table
                rowKey={(record) => record.id ? record.id.toString() : 'undef'}
                rowSelection={eventRowSelection}
                dataSource={this.state.events.map((e: any) => {
                  return {
                    ...e,
                    created_at: moment(e.created_at).format('DD/MM/YYYY')
                  }
                })}
                pagination={false} scroll={{ y: 280 }}
                size="small"
              >
                {unitEventsColumns}
              </Table>
            </div>
            <Button
              onClick={() => this.addToSelected(this.state.events)}
              className="add-event"
            >
              {this.props.t('test.addLesson')}
            </Button>
          </div>
        </div>
        }
        {!!this.state.testEvents.length &&
        <div>
          <div
            className="test-event-list"
          >
            <h2>
              {this.props.t('test.listOftasksForTest')}
            </h2>
            <div>
              <Table
                rowKey={({id}) => id ? String(id) : 'undefined'}
                dataSource={this.state.testEvents.map((e: any) => {
                  return {
                    ...e,
                    created_at: moment(e.created_at).format('DD/MM/YYYY')
                  }
                })}
                pagination={false} scroll={{ y: 300 }}
                size="small"
              >{testEventsColumns}</Table>
            </div>
          </div>
          <div
            className="test-meta-info-container"
          >
            {this.redirectToActivities()}
            <h2>
              {this.props.t('test.createTest')}
            </h2>
            <div
              className="test-meta-info"
            >
              <Input
                onChange={(e) => this.updateTestName(e)}
                className="test-name"
                placeholder="Názov testu"
              />
            </div>
            <br />
            <Button
              onClick={() => this.submitTest()}
              className="create-test"
            >
              {this.props.t('test.createTest')}
            </Button>
          </div>
        </div>
        }
      </main>
    )
  }
}

export default withTranslation()(withRouter(TestCreation))
