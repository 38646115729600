import { withRouter, RouteComponentProps } from 'react-router-dom'
import React, { ReactNode, Component } from 'react'

/* Css */
import './Layout.scss'

/* i18n */
import { WithTranslation, withTranslation } from 'react-i18next'


interface IProps extends RouteComponentProps, WithTranslation {
  children: ReactNode
}

interface IState {
  selectedLanguage: string;
}

class Layout extends Component<IProps, IState> {
  render() {
    const { children } = this.props
    return (
      <div className="eceh-layout">
        <div className="eceh-layout__inner">
          {children}
        </div>
      </div>
    )
  }
}

export default withTranslation()(withRouter(Layout))