import React from 'react'
import {withRouter, RouteComponentProps, Link, Redirect} from 'react-router-dom'
import Panel from './Panel/Panel'
import {Breadcrumb, Button, Col, Row, Tooltip} from 'antd'
import {
  HomeOutlined,
  ArrowLeftOutlined,
  ReloadOutlined
} from '@ant-design/icons'
import notification from '../../../utils/notificationService'
import api from '../../../utils/ApiAxios'
import {Activity, Unit} from '../../../models'
import Loading from '../../common/Loading/Loading'
import './TeacherPanel.css'
import {Student} from './../../../models'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'

let timer: NodeJS.Timeout

interface IProps extends RouteComponentProps<RouteParams>, WithTranslation {
  
}

interface IState {
  activity: Activity | null
  unit: Unit | undefined
  loading: boolean
  students: Student[]
}

interface RouteParams {
  activityId: string
  unitId: string
}


  class TeacherPanel extends React.Component<IProps, IState> {
    constructor(props: IProps) {
      super(props)
      this.getStudents = this.getStudents.bind(this)
      this.state = {
        loading: true,
        activity: null,
        unit: undefined,
        students: []
      }
    }

    getStudents() {
      api.units
        .unitSummary(
          parseInt(this.props.match.params.activityId, 10),
          parseInt(this.props.match.params.unitId, 10)
        )
        .then(summary => {
          const students: Student[] = summary.data
          this.setState({
            students: students
          })
        })
        .catch(error => {
          notification.error('Vo vyučovacej hodine sa nenachádzajú žiadni študenti', error)
        })
    }

    componentDidMount() {
      const activityId = parseInt(this.props.match.params.activityId)
      this.fetchActivity(activityId)
      this.getStudents()
      timer = setInterval(this.getStudents, 20000)
    }

    componentWillUnmount() {
      clearTimeout(timer)
    }

    fetchActivity = (id: number) => {
      this.setState({loading: true})
      api.activities
        .getActivity(id)
        .then(response => {
          this.setState({
            activity: response.data.activity
          })
          api.activities
            .getActivityUnits(id)
            .then(response => {
              const units: Unit[] = response.data
              this.setState({
                unit: units.find(
                  unit => unit.id === parseInt(this.props.match.params.unitId)
                ),
                loading: false
              })
            })
            .catch(error => {
              this.setState({loading: false})
              notification.error(
                'Chyba inicializácie dát aktivity alebo jednotky, skontrolujete či zadaná aktivita alebo jednotka existuje',
                error
              )
            })
        })
        .catch(error => {
          this.setState({loading: false})
          notification.error(
            'Chyba inicializácie dát aktivity alebo jednotky, skontrolujete či zadaná aktivita alebo jednotka existuje',
            error
          )
        })
    }

    activityNotInitializedMessage() {
      notification.error(
        'Došlo ku chybe pri načítavaní stránky, prosím znovu načítajte stránku'
      )
    }

    renderBreadcrumb() {
      const {match} = this.props
      const {activity} = this.state
      if (activity && match.params.activityId) {
        return (
          <React.Fragment>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={`/activity/detail/${match.params.activityId}`}>
                  <ArrowLeftOutlined />
                </Link>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Link to="/">
                  <HomeOutlined />
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/activities">Predmety</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/activity/detail/${match.params.activityId}`}>
                  {activity.title}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Učiteľský panel</Breadcrumb.Item>
            </Breadcrumb>
          </React.Fragment>
        )
      } else {
        this.activityNotInitializedMessage()
        return <Redirect to="/404" />
      }
    }

    renderUnitTitle() {
      if (this.state.unit) {
        return (
          <React.Fragment>
            <Row>
              <Col xs={20}>
                <h2>{this.state.unit.title}</h2>
              </Col>
              <Col xs={4} className="button-container-col">
                <Tooltip key={'Obnoviť'} title={'Obnoviť'}>
                  <Button
                    shape="circle"
                    icon={<ReloadOutlined style={{paddingTop: 5}} />}
                    type="default"
                    onClick={this.getStudents}
                  />
                </Tooltip>
              </Col>
            </Row>
          </React.Fragment>
        )
      } else {
        this.activityNotInitializedMessage()
        return <Redirect to="/404" />
      }
    }

    render() {
      return (
        <React.Fragment>
          {this.state.loading ? (
            <Loading />
          ) : (
            <div className="style">
              {this.renderBreadcrumb()}
              {this.renderUnitTitle()}
              <Panel students={this.state.students} />
            </div>
          )}
        </React.Fragment>
      )
    }
  }

  export default withTranslation()(withRouter(TeacherPanel))
