import React from 'react'
import { Button, Table, Col, Row } from 'antd'

import { withRouter, RouteComponentProps } from 'react-router'
import api from '../../../utils/ApiAxios'
import notification from '../../../utils/notificationService'
import './ExamResults.css'
import { AuthContext } from '../../../context/Auth'
import { Event } from '../../../models'
import Loading from '../../common/Loading/Loading'

//translation
import { WithTranslation, withTranslation } from 'react-i18next'

interface IProps extends RouteComponentProps<RouteParams>, WithTranslation {

}

interface RouteParams {
  examId: string;
}

interface IState {
  events: Event[];
  loading: boolean;
  testName: string;
  testDate: string;
  results: {
    id: number;
    order: number;
    title: string;
    points: number;
  }[];
  totalObtainedPoints: number;
  maxPoints: number;

}

class ExamResultsStudent extends React.Component<IProps, IState> {
  static contextType = AuthContext;

  constructor(props: IProps) {
    super(props)
    this.state = {
      events: [],
      loading: true,
      testName: '',
      testDate: '',
      results: [],
      totalObtainedPoints: 0,
      maxPoints: 0
    }
  }

  componentDidMount() {
    const { examId } = this.props.match.params
    const studentId = this.context.user.id
    let totalObtainedPoints = 0
    Promise.all([
      api.exams.getExam(parseInt(examId)),
      api.exams.getUserAnswers(parseInt(examId), studentId)
    ])
      .then(response => {
        const test = response[0].data
        const answers = response[1].data
        const results = answers.reduce(
          (tempResults: any[], currentAnswer: any, index: number) => {
            const event = test.events.find(
              event => event.id === currentAnswer.event_id
            )
            if (event) {
              totalObtainedPoints += parseFloat(currentAnswer.obtained_points)
              tempResults.push({
                id: currentAnswer.event_id,
                order: index + 1,
                title: event.header,
                points: parseFloat(currentAnswer.obtained_points)
              })
            }
            return tempResults
          },
          []
        )
        this.setState({
          totalObtainedPoints,
          maxPoints: answers.length,
          results: results,
          testName: test.name,
          testDate: test.startDate,
          loading: false
        })
      })
      .catch(error => notification.error(this.props.t('exams.pageError'), error))
  }

  renderResultsTable(points: number) {
    return (
      <p className={points > 0 ? 'result_green' : 'result_red'}>{points}/1{this.props.t('exams.shortPoint')}</p>
    )
  }

  renderResult(totalObtainedPoints: number, maxPoints: number) {
    const percentage = (totalObtainedPoints * 100) / maxPoints
    const color =
      percentage >= 75
        ? 'result_green'
        : percentage >= 40
          ? 'result_orange'
          : 'result_red'
    return (
      <Row>
        <Col>{this.props.t('exams.resultPercentage')}</Col>
        <Col className={color}>: {percentage.toFixed(2)}%</Col>
      </Row>
    )
  }

  render() {
    const { totalObtainedPoints, maxPoints } = this.state
    return (
      <main>
        {this.state.loading ? (
          <Loading />
        ) : (
          <div className="style">
            <h2>{this.props.t('exams.examResult')}</h2>
            <Row justify="space-between">
              <Col>
                <p>{this.props.t('exams.examTitle')}</p>
                <b>
                  <p
                    style={{ marginLeft: '10px' }}
                    dangerouslySetInnerHTML={{ __html: this.state.testName }}
                  />
                </b>
              </Col>
              <Col>
                <p>{this.props.t('exams.date')}</p>
                <p
                  style={{ margin: 0 }}
                  dangerouslySetInnerHTML={{ __html: this.state.testDate }}
                />
              </Col>
            </Row>
            <br />
            {this.renderResult(totalObtainedPoints, maxPoints)}
            <p>
              {this.props.t('exams.totalCorrectAnswers')}{' '}{totalObtainedPoints}{' '}{this.props.t('exams.of')}{' '}{maxPoints}
            </p>
            <p>
            {this.props.t('exams.totalPointsEarned')}{' '}{totalObtainedPoints}{this.props.t('exams.shortPoint')}{' '}{this.props.t('exams.of')}{' '}
              {maxPoints}{this.props.t('exams.shortPoint')}
            </p>
            <br />
            <div>
              <p>
                <b>{this.props.t('exams.resultOfQuestions')}</b>
              </p>
              <br />
              <Table
                rowKey={record => record.id}
                dataSource={this.state.results}
                scroll={{ y: 240 }}
                pagination={false}
              >
                <Table.Column title={this.props.t('exams.numberOfQuestion')} dataIndex="order" />
                <Table.Column title={this.props.t('exams.titleOfQuestion')} dataIndex="title" />
                <Table.Column
                  title={this.props.t('exams.successOfQuestion')}
                  dataIndex="points"
                  render={(points: number) => {
                    return this.renderResultsTable(points)
                  }}
                />
              </Table>
            </div>
            <br />
            <Button
              type="primary"
              onClick={() => {
                this.props.history.goBack()
              }}
            >
              {this.props.t('exams.back')}
            </Button>
          </div>
        )}
      </main>
    )
  }
}

export default withTranslation()(withRouter(ExamResultsStudent))
