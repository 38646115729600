import Event from '../models/event'

export function formToEventTransform(values: any, editor: any, user_id?: number, id?: number): Event {
  return {
    ...(id !== undefined) && {id},
    message: editor !== null ? editor.getData() : '',
    header: values.name ? values.name : '',
    time_to_explain: values.timeToExplain ? values.timeToExplain : 0,
    //ktory kokot nazval jednu premennu time_to_handle a druhu timeToFinish???
    time_to_handle: values.timeToFinish ? values.timeToFinish : 0,
    event_type_id: values.type ? values.type : 1,
    category_ids: values.category_ids ? values.category_ids : [],
    ...(user_id) && {id_users: user_id}
  }
}
